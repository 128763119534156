import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import DataTable from "react-data-table-component";
import { FiEye } from "react-icons/fi";
import { setActiveDashboard } from "../../redux/slices/user.slice";
import MapMarker from "./MapMarker";
import { DashboardCount } from "./DashboardCount";
import { endpoint } from "../../api";
import { userRole } from "../../constant";
import { AlarmTimeFilter } from "./AlarmTimeFilter";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const MainDashboard = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.userDetails);
  const [mapCenter, setMapCenter] = useState([3.0534, 101.6571]);
  const [dashboardCounter, setDashboardCounter] = useState({});
  const [alarmCounter, setAlarmCounter] = useState({});
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [siteLocationsWithSensor, setSiteLocationsWithSensor] = useState([]);
  const [siteLocations, setSiteLocations] = useState([]);
  const [isCounterLoading, setIsCounterLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [markerLoading, setMarkerLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [markerData, setMarkerData] = useState({
    totalDevices: 0,
    activeSensors: 0,
    inactiveSensors: 0,
  });

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setSiteLocations(data);
        let firstData = data?.[0];
        if (firstData) {
          setMapCenter([firstData?.latitude, firstData?.longitude]);
        }
        setSiteLocationsWithSensor([]);
      }
    } catch (error) {}
  };

  const getSiteLocationsWithSensor = async (search) => {
    try {
      // if (search) {
      setIsLoading(true);
      const response = await axios.get(
        `/site-location/sensorDetail?page=${page}&limit=${limit}&search=${search}`,
        {
          withCredentials: true,
        }
      );
      if (response?.data?.results) {
        setSiteLocationsWithSensor(response.data.results);
        if (response.data.results?.length) {
          let firstData = response.data.results[0];
          if (firstData) {
            setMapCenter([firstData?.latitude, firstData?.longitude]);
          }
        }
        setTotal(response.data.total);
      }
      // }
      setIsLoading(false);
    } catch (error) {}
  };
  const getSensorDetails = async (id) => {
    try {
      setMarkerLoading(true);
      const response = await axios.get(`/device/getSensorDetails/${id}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        setMarkerData(response.data);
      }
      setMarkerLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkerClick = (id) => {
    getSensorDetails(id);
  };
  const searchHandler = (e) => {
    e.preventDefault();
    setPage(1);
    if (search) {
      setLocations("");
      getSiteLocationsWithSensor(search);
    } else if (locations) {
      setSearch("");
      getSiteLocationsWithSensor(locations);
    } else {
      getSiteLocations();
    }
  };

  const columns = [
    {
      name: "No.",
      selector: (row, ind) => ind + 1,
      width: "60px",
    },
    {
      name: "Location",
      cell: (row) => <p className="m-0">{row?.name || "--"}</p>,
    },
    {
      name: "Total Sensor",
      cell: (row) => <p className="m-0">{row?.totalDevices || 0}</p>,
      center: true,
    },
    {
      name: "Sensor Online",
      cell: (row) => <p className="m-0">{row?.activeSensors || 0}</p>,
      center: true,
    },
    {
      name: "Sensors Offline",
      cell: (row) => <p className="m-0">{row?.inactiveSensors || 0}</p>,
      center: true,
    },
    {
      name: "Action",
      width: "220px",
      center: true,
      cell: (row) => (
        <div>
          <Button
            variant="success"
            disabled={!row?.dashboard}
            onClick={() => dispatch(setActiveDashboard(row?.dashboard))}
          >
            <FiEye />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (search) {
      setLocations("");
      getSiteLocationsWithSensor(search);
    } else if (locations) {
      setSearch("");
      getSiteLocationsWithSensor(locations);
    } else {
      setSiteLocationsWithSensor([]);
    }
  }, [limit, page]);

  const getDashboardCounter = async () => {
    setIsCounterLoading(true);
    try {
      if (userDetails?.role === "superAdmin") {
        const response = await axios.get(endpoint.dashboard_count, {
          withCredentials: true,
        });
        if (response) {
          setDashboardCounter(response.data.result);
        }
      }
    } catch (error) {}
    setIsCounterLoading(false);
  };
  const getAlarmCounter = async (filters = {}) => {
    try {
      if (Object.keys(filters).length === 0) {
        filters.startDate = dayjs().subtract(7, "day").startOf("day").toDate();
        filters.endDate = dayjs().subtract(1, "day")?.endOf("day").toDate();
      }
      setStartDate(filters?.startDate);
      setEndDate(filters?.endDate);
      if (filters?.minuteAgo) {
        setStartDate(dayjs().subtract(filters?.minuteAgo, filters?.xAxis));
        setEndDate(dayjs());
      }
      const response = await axios.get(endpoint.dashboard_alarm_count, {
        withCredentials: true,
        params: {
          ...filters,
        },
      });
      if (response) {
        setAlarmCounter(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Dashboard";
    getSiteLocations();
    getAlarmCounter();
  }, []);

  useEffect(() => {
    getDashboardCounter();
  }, [userDetails]);

  return (
    <div className="az-content-body">
      <Spin spinning={isCounterLoading}>
        <div className="container mb-4 gy-3" style={{ maxWidth: "100%" }}>
          {mainCounters.map(({ entryName, url, roles, ...rest }, idx) => {
            return (
              roles.includes(userDetails?.role) && (
                <div
                  key={entryName}
                  className="col"
                  style={{
                    paddingLeft: idx === 0 ? "0" : "15px",
                    paddingRight: idx === 4 ? "0" : "15px",
                  }}
                >
                  <Link to={url}>
                    <DashboardCount
                      {...rest}
                      count={dashboardCounter?.[entryName]}
                    />
                  </Link>
                </div>
              )
            );
          })}
        </div>

        {(userDetails.role === userRole.superAdmin ||
          userDetails.role === userRole.admin) && (
          <Row className="mb-4 gy-3">
            <Col md={12} className="d-flex justify-content-between">
              <h5 style={{ marginBottom: "-8px" }}>Alarm Summary</h5>
              <AlarmTimeFilter getAlarmCounter={getAlarmCounter} />
            </Col>
            {alarmSummary.map(({ entryName, url, ...rest }) => {
              let params = url;
              if (startDate) {
                params = `${url}&startDate=${dayjs(startDate).format(
                  "YYYY/MM/DD : HH:mm"
                )}&endDate=${dayjs(endDate).format("YYYY/MM/DD : HH:mm")}`;
              }
              return (
                <Col xs={4} md={3} key={entryName}>
                  <Link to={params}>
                    <DashboardCount
                      {...rest}
                      count={alarmCounter?.[entryName]}
                      bg={
                        (entryName === "totalCriticalState" &&
                          alarmCounter?.[entryName]) > 0
                          ? "#f30a0a"
                          : "#E1E1E1"
                      }
                      color={
                        !(
                          entryName === "totalCriticalState" &&
                          alarmCounter?.[entryName]
                        ) > 0
                          ? "#000"
                          : "#fff"
                      }
                    />
                  </Link>
                </Col>
              );
            })}
          </Row>
        )}
      </Spin>
      <h5 className="pb-2">Advance Search Filters</h5>
      <Form onSubmit={searchHandler}>
        <div className="row mb-3">
          <div className="col-md-4">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className="col-md-4">
            <select
              className="form-select"
              value={locations}
              onChange={(e) => setLocations(e.target.value)}
            >
              <option value="">Select Substations</option>
              {siteLocations?.length > 0 &&
                siteLocations.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-md-2">
            <Button type="submit" variant="success">
              Search
            </Button>
          </div>
        </div>
      </Form>
      <div className="row row-sm mg-b-15 mg-sm-b-20">
        <div className="col-lg-12 py-5">
          <MapContainer center={mapCenter} zoom={6} scrollWheelZoom={false}>
            <ChangeView center={mapCenter} zoom={6} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {siteLocationsWithSensor?.length > 0
              ? siteLocationsWithSensor?.map((site) => (
                  <MapMarker
                    key={site._id}
                    site={site}
                    markerLoading={markerLoading}
                    handleMarkerClick={handleMarkerClick}
                    markerData={markerData}
                  />
                ))
              : siteLocations?.map((site) => (
                  <MapMarker
                    key={site._id}
                    site={site}
                    markerLoading={markerLoading}
                    handleMarkerClick={handleMarkerClick}
                    markerData={markerData}
                  />
                ))}
          </MapContainer>
        </div>
      </div>

      {siteLocationsWithSensor?.length > 0 && (
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          <div className="col-lg-12">
            <div className="card card-dashboard-six">
              <div className="card-header">
                <div>
                  <label className="az-content-label">SEARCH RESULTS</label>
                </div>
              </div>
              <Spin spinning={isLoading}>
                <DataTable
                  columns={columns}
                  data={siteLocationsWithSensor}
                  pagination
                  paginationServer
                  noDataComponent={isLoading ? "Loading" : "No record found."}
                  className="mt-3"
                  paginationTotalRows={total}
                  paginationPerPage={limit}
                  onChangePage={(data) => {
                    setPage(data);
                  }}
                  onChangeRowsPerPage={(data) => {
                    setLimit(data);
                    setPage(1);
                  }}
                  paginationRowsPerPageOptions={[10, 20, 50]}
                />
              </Spin>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainDashboard;

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

let mainCounters = [
  {
    title: "Substations",
    bg: "#2E5A88",
    entryName: "locationCount",
    roles: ["user", "superAdmin", "admin"],
    url: "/substation",
  },
  {
    title: "All Admins",
    bg: "#FF7F27",
    entryName: "adminCount",
    roles: ["superAdmin"],
    url: "/users?role=admin",
  },
  {
    title: "All Users",
    bg: "#cb9418",
    entryName: "userCount",
    roles: ["superAdmin", "admin"],
    url: "/users?role=user",
  },

  {
    title: "Sensors",
    bg: "#780078c7",
    entryName: "deviceCount",
    roles: ["user", "superAdmin", "admin"],
    url: "/sensor",
  },
  {
    title: "Offline Sensor",
    bg: "#f30a0a",
    entryName: "inactiveSensors",
    roles: ["user", "superAdmin", "admin"],
    url: "/sensor?status=inactive",
  },
];

const alarmSummary = [
  {
    title: "Total Triggered Alarms",
    entryName: "totalAlram",
    url: "/alarm-history?alarm-status=normal",
  },
  {
    title: "Active Alarms",
    entryName: "totalCriticalState",
    url: "/alarm-history?alarm-status=critcal",
  },
  {
    title: "Total Alarms Acknowledged",
    entryName: "totalAcknoledged",
    url: "/alarm-history?alarm-status=ackow",
  },
  {
    title: "Total Alarms Returned to Normal",
    entryName: "totalNormal",
    url: "/alarm-history?alarm-status=normal",
  },
];
