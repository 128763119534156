import { TimePicker, Tag, Spin } from "antd";
import axios from "axios";
import moment from "moment/moment";
import React, { memo } from "react";
import { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { endpoint } from "../../api";
import MultipleLocation from "./MultipleLocation";
import { BiPlus } from "react-icons/bi";
import ReactQuill from "react-quill";
import appConfig from "../../appConfig";

const AddItemModal = memo(
  ({ show, setShow, allData, setAllData, editData, mode, id }) => {
    const initialState = {
      locations: [],
      component: "",
      chartTitle: "",
      operator: "",
      xAxis: "",
      titleFontSize: 14,
      valueFontSize: 14,
      colorSchema: "default",
    };

    const initLocation = [
      {
        location: "",
        device: "",
        yAxis: "",
      },
    ];

    const [deviceList, setDeviceList] = useState([]);
    const [siteLocations, setSiteLocations] = useState([]);
    const [itemData, setItemData] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [multipleLocation, setMultipleLocation] = useState(initLocation);

    const {
      component,
      chartTitle,
      operator,
      xAxis,
      colorSchema,
      min,
      max,
      unit,
      description,
      capacity,
      baseLine,
      titleFontSize,
      valueFontSize,
    } = itemData;

    const handleClose = () => {
      setShow(false);
      setItemData(initialState);
      setMultipleLocation(initLocation);
    };

    const handleChange = (e) => {
      if (e.target.name === "component") {
        setMultipleLocation(initLocation);
        return setItemData({
          ...initialState,
          _id: itemData._id,
          chartTitle: itemData.chartTitle,
          [e.target.name]: e.target.value,
        });
      }
      setItemData({
        ...itemData,
        [e.target.name]: e.target.value,
      });
    };
    const handleDescriptionChange = (value) => {
      setItemData({
        ...itemData,
        description: value,
      });
    };

    // ***** api ******* //
    // get device
    const getDevice = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(endpoint.get_devices, {
          withCredentials: true,
        });
        if (response) {
          setDeviceList(response.data?.results);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    // get site locations
    const getSiteLocations = async (userDetails) => {
      try {
        const response = await axios.get(`/site-location`, {
          withCredentials: true,
        });
        if (response) {
          setSiteLocations(
            response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          );
        }
      } catch (error) {}
    };

    useEffect(() => {
      if (!isLoading) {
        getDevice();
        getSiteLocations();
      }
    }, []);

    useEffect(() => {
      if (mode === "edit" && id && deviceList.length > 0) {
        const editData = allData.find((data) => data._id === id);
        setItemData(editData);
      }
    }, [show, isLoading]);

    const handleSubmit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const data = { ...itemData, locations: multipleLocation };
      data._id = new Date().getTime();

      setAllData([...allData, data]);
      setShow(false);
      setItemData(initialState);
      setMultipleLocation(initLocation);
    };

    // have to change according new data
    const handleEdit = (e) => {
      e.preventDefault();
      e.stopPropagation();
      itemData.locations = multipleLocation;
      const updated = allData.map((d) => {
        if (d._id === itemData._id) {
          d = itemData;
        }
        return d;
      });
      setAllData(updated);
      setShow(false);
    };

    useEffect(() => {
      if (mode === "edit" && itemData.locations.length > 0) {
        setMultipleLocation(itemData.locations);
      }
    }, [itemData.locations]);

    const componentPreview = {
      bar: "/img/chart/bar.png",
      line: "/img/chart/line.png",
      pie: "/img/chart/pie.png",
      counter: "/img/chart/counter.png",
      splitCounter: "/img/chart/split-counter.png",
      textBox: "/img/chart/text-box.png",
      dial: "/img/chart/dial.png",
      tank: "/img/chart/tank.png",
    };

    return (
      <div>
        <Modal className="mt-5 mt-md-0" show={show} onHide={handleClose}>
          <Modal.Header closeButton />
          <Spin spinning={isLoading}>
            <Modal.Body>
              <Form onSubmit={mode === "edit" ? handleEdit : handleSubmit}>
                {/* title */}
                <Form.Group className="mb-2">
                  <Form.Label className="fw-bold">Title</Form.Label>
                  <Form.Control
                    required
                    className="text-secondary"
                    type="text"
                    name="chartTitle"
                    value={chartTitle}
                    onChange={handleChange}
                    placeholder="Enter Tile Name"
                  />
                </Form.Group>

                {/* components */}
                <Form.Group className="mb-2">
                  <Form.Label className="fw-bold">Component</Form.Label>
                  <Form.Select
                    required
                    className="text-secondary"
                    name="component"
                    value={component}
                    onChange={handleChange}
                  >
                    <option value="">Select Component</option>
                    <option value="bar">Bar Graph</option>
                    <option value="line">Line Graph</option>
                    <option value="pie">Pie Chart</option>
                    <option value="tank">3D Fuel Tank Image</option>
                    <option value="counter">Counter</option>
                    <option value="splitCounter">Split Counter</option>
                    <option value="textBox">Text Box</option>
                    <option value="dial">Dial</option>
                  </Form.Select>
                </Form.Group>

                {component !== "" ? (
                  <div className="mb-2 ">
                    <div className="mb-2 text-capitalize fw-bold form-label">
                      preview
                    </div>
                    <img
                      src={appConfig.baseRoute + componentPreview[component]}
                      alt="img"
                      className="img-fluid"
                      style={{
                        maxWidth: "60%",
                        display: "block",
                        margin: "auto",
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <>
                  {component === "counter" ||
                  component === "dial" ||
                  component === "splitCounter" ||
                  component === "textBox" ||
                  component === "tank" ? (
                    ""
                  ) : (
                    <hr />
                  )}
                  {component !== "textBox" &&
                    multipleLocation.map((location, ind) => {
                      const props = {
                        ind,
                        location,
                        component,
                        siteLocations,
                        multipleLocation,
                        setMultipleLocation,
                      };
                      return (
                        <React.Fragment key={ind}>
                          <MultipleLocation {...props} />
                          {component === "counter" ||
                          component === "dial" ||
                          component === "splitCounter" ? (
                            ""
                          ) : (
                            <hr />
                          )}
                        </React.Fragment>
                      );
                    })}

                  {component === "counter" ||
                  component === "dial" ||
                  component === "splitCounter" ||
                  component === "textBox" ||
                  component === "tank" ? (
                    ""
                  ) : (
                    <Button
                      className="mb-2 py-2 rounded-circle"
                      onClick={() =>
                        setMultipleLocation([
                          ...multipleLocation,
                          ...initLocation,
                        ])
                      }
                    >
                      <BiPlus />
                    </Button>
                  )}
                  {component === "textBox" && (
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-bold">Description</Form.Label>
                      <ReactQuill
                        theme="snow"
                        id="description1"
                        name="description"
                        value={description}
                        onChange={handleDescriptionChange}
                      />
                    </Form.Group>
                  )}
                  {component !== "textBox" && (
                    <Form.Group className="mb-2">
                      <Form.Label className="fw-bold">
                        Select Operator
                      </Form.Label>
                      <Form.Select
                        // required
                        className="text-secondary"
                        name="operator"
                        value={operator}
                        onChange={handleChange}
                      >
                        <option value="">Real Time</option>
                        <option value="max">Maximum</option>
                        <option value="min">Minimum</option>
                        <option value="avg">Average</option>
                        <option value="sum">Sum</option>
                      </Form.Select>
                    </Form.Group>
                  )}
                </>

                <Row className="mb-2 gy-3">
                  {component === "dial" && (
                    <>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label className="fw-bold">Min</Form.Label>
                          <Form.Control
                            required
                            className="text-secondary"
                            type="text"
                            name="min"
                            value={min}
                            onChange={handleChange}
                            placeholder="Enter Min Value"
                          />
                        </Form.Group>
                      </Col>

                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label className="fw-bold">Max</Form.Label>
                          <Form.Control
                            required
                            className="text-secondary"
                            type="text"
                            name="max"
                            value={max}
                            onChange={handleChange}
                            placeholder="Enter Max Value"
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}

                  {component === "tank" && (
                    <>
                      <Form.Group>
                        <Form.Label className="fw-bold">Capacity</Form.Label>
                        <Form.Control
                          required
                          className="text-secondary"
                          type="number"
                          name="capacity"
                          value={capacity}
                          onChange={handleChange}
                          placeholder="Enter Tank Capacity (L)"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="fw-bold">Base Line</Form.Label>
                        <Form.Control
                          required
                          className="text-secondary"
                          type="number"
                          name="baseLine"
                          value={baseLine}
                          onChange={handleChange}
                          placeholder="Enter Minimum Base Line"
                        />
                      </Form.Group>
                    </>
                  )}

                  {(component === "dial" ||
                    component === "counter" ||
                    component === "splitCounter" ||
                    component === "tank") && (
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label className="fw-bold">Unit</Form.Label>
                        <Form.Control
                          required
                          className="text-secondary"
                          type="text"
                          name="unit"
                          value={unit}
                          onChange={handleChange}
                          placeholder="Enter Unit Value"
                        />
                      </Form.Group>
                    </Col>
                  )}

                  {(component === "counter" ||
                    component === "splitCounter") && (
                    <Row className="mt-2">
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label className="fw-bold">
                            Title Font Size (pt)
                          </Form.Label>
                          <Form.Control
                            required
                            className="text-secondary"
                            type="number"
                            name="titleFontSize"
                            value={titleFontSize}
                            onChange={handleChange}
                            placeholder="Enter Font Size (pt)"
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group>
                          <Form.Label className="fw-bold">
                            Value Font Size (pt)
                          </Form.Label>
                          <Form.Control
                            required
                            className="text-secondary"
                            type="number"
                            name="valueFontSize"
                            value={valueFontSize}
                            onChange={handleChange}
                            placeholder="Enter Font Size (pt)"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  {/* Time */}
                  {component === "dial" ||
                  component === "counter" ||
                  component === "splitCounter" ||
                  component === "textBox" ||
                  component === "tank" ? (
                    ""
                  ) : (
                    <>
                      <Col xs={12}>
                        <Form.Group className="mb-2">
                          <Form.Label className="fw-bold">
                            Select Time Period
                          </Form.Label>
                          <Form.Select
                            required
                            className="text-secondary"
                            value={xAxis}
                            name="xAxis"
                            onChange={handleChange}
                          >
                            <option value="">Select Period</option>
                            <option value="day">Daily</option>
                            <option value="week">Weekly</option>
                            <option value="month">Monthly</option>
                            <option value="year">Yearly</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                  {(component === "line" ||
                    component === "bar" ||
                    component === "pie") && (
                    <Col xs={12}>
                      <Form.Group className="mb-2">
                        <Form.Label className="fw-bold">
                          Select Colour Schema
                        </Form.Label>
                        <Form.Select
                          required
                          className="text-secondary"
                          value={colorSchema}
                          name="colorSchema"
                          onChange={handleChange}
                        >
                          <option value="">Select Colour Schema</option>
                          {colorSchemaList?.map(({ label, value }) => (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}
                </Row>

                <div className=" mt-4">
                  <Button type="submit" className="px-4 " variant="success">
                    {mode === "edit" ? "Save" : "Create"}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Spin>
        </Modal>
      </div>
    );
  }
);

export default AddItemModal;

const colorSchemaList = [
  { label: "Default", value: "category10" },
  { label: "Type 1", value: "nivo" },
  { label: "Type 2", value: "accent" },
  { label: "Type 3", value: "dark2" },
  { label: "Type 4", value: "paired" },
  { label: "Type 5", value: "pastel1" },
  { label: "Type 6", value: "pastel2" },
  { label: "Type 7", value: "set1" },
  { label: "Type 8", value: "set2" },
  { label: "Type 9", value: "set3" },
];
