import React, { useState, useEffect } from "react";
import { FiMaximize } from "react-icons/fi";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  setIsLogged,
  setUserDetails,
  setActiveDashboard,
} from "../../redux/slices/user.slice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import { api, endpoint } from "../../api";
import { getFormatedUserType } from "../../utils/userTypeFomater";

const Header = ({ toggleShow, handle }) => {
  const { userDetails, activeDashboard } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [dashboardList, setDashboardList] = useState([]);

  const logOut = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`/user/logout`, {
            withCredentials: true,
          })
          .then((res) => {
            dispatch(setActiveDashboard(""));
            dispatch(setIsLogged(false));
            dispatch(setUserDetails({}));
            window.localStorage.clear();
            navigate("/");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const getSingleDashboard = async () => {
    try {
      if (activeDashboard && activeDashboard !== "panelOverview") {
        await axios.get(endpoint.dashboard + "/" + activeDashboard, {
          withCredentials: true,
        });
      }
    } catch (error) {
      dispatch(setActiveDashboard(""));
    }
  };

  const getDashboard = async () => {
    try {
      const res = await api.get(endpoint.dashboard_list, {
        params: {
          limit: 1000,
          page: 1,
        },
      });
      let data = res.results?.length ? res.results : [];
      setDashboardList(data);
      if (!data.length) {
        dispatch(setActiveDashboard(""));
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDashboard();
    getSingleDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname === "/"]);

  const changeDashboardHandler = (e) => {
    dispatch(setActiveDashboard(e.target.value));
  };

  return (
    <div className="az-header">
      <div className="container-fluid">
        <div className="az-header-left d-block d-lg-none  me-4">
          <button
            id="azSidebarToggle"
            className="bg-transparent border-0 az-header-menu-icon"
            onClick={() => toggleShow()}
          >
            <span></span>
          </button>
        </div>
        <div className="">
          {location.pathname === "/" && (
            <div className="me-4" style={{ maxWidth: "180px" }}>
              <select
                className="form-select"
                onChange={changeDashboardHandler}
                value={activeDashboard}
              >
                <option value="">Main Dashboard</option>
                <option value="substationOverview">Substation Overview</option>
                <option value="panelOverview">Panel Overview</option>
                {dashboardList?.length &&
                  dashboardList.map((dashboard) => (
                    <option key={dashboard?._id} value={dashboard?._id}>
                      {dashboard?.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>

        <div className="ms-auto az-header-right">
          {/* <div
            className=" position-relative fs-4 me-3"
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            <Link to="/alarm-history" className="text-warning">
              <IoMdNotifications />
            </Link>
            <span
              style={{ height: "20px", width: "20px", top: "10px" }}
              className={`position-absolute start-100 translate-middle border border-light rounded-circle bg-danger d-flex align-items-center justify-content-center ${
                totalNotification > 0 ? "" : "d-none"
              } `}
            >
              <span className="text-white" style={{ fontSize: "12px" }}>
                {totalNotification > 9 ? "9+" : totalNotification}
              </span>
            </span>
          </div> */}

          <div>
            <FiMaximize
              className="tx-16"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handle.enter();
                console.log("click");
              }}
            />
          </div>
          <DropdownButton
            key={"down"}
            id={`dropdown-button-drop-down`}
            className="d-flex"
            drop={"down"}
            variant="none"
            title={
              <div className="az-img-user online">
                <img src={userDetails?.avatar} alt="user" />
              </div>
            }
          >
            <div className="az-header-profile">
              <div className="az-img-user">
                <img src={userDetails?.avatar} alt="" />
              </div>
              <h6>{userDetails?.name}</h6>
              <span className="text-capitalize">
                {getFormatedUserType(userDetails?.role)}
                {/* {userDetails?.role === "superAdmin"
                  ? "Super Admin"
                  : userDetails?.role} */}
              </span>
            </div>
            <div className="nav">
              <Dropdown.Item eventKey="1">
                <Link to="/settings" className="dropdown-item">
                  Settings
                </Link>
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <Link to="/profile" className="dropdown-item">
                  Profile
                </Link>
              </Dropdown.Item>
              <Dropdown.Item eventKey="1">
                <button
                  className="dropdown-item border-0 bg-transparent"
                  onClick={() => logOut()}
                >
                  Logout
                </button>
              </Dropdown.Item>
            </div>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
