import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import ElectricityTariffTable from './ElectricityTariffTable';
import { api, endpoint } from "../../api";
import { CreateTariffCategory } from '../../components/Settings/CreateTariffCategory';
import { userRole } from "../../constant";

export default function ElectricityTariff() {
    const userDetails = useSelector((state) => state.user.userDetails);

    const [isLoading, setIsLoading] = useState(false);
    const [eTariff, setETariff] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);


    const getElectricityTariff = async () => {
        setIsLoading(true);
        try {
            let { results, count } = await api.get(endpoint.get_electricity_tariff, {
                params: { limit, page, sortBy: "createdAt", orderBy: "DESC" },
            });

            results = results.map((item, idx) => {
                item.sl = (page - 1) * limit + idx + 1;
                return item;
            });
            setETariff(results);
            setTotal(count || 0);
        } catch (error) { }
        setIsLoading(false);
    };
    useEffect(() => {
        getElectricityTariff()
    }, [limit, page]);


    return (
        <div className="card p-3 m-3">
            {/* <h3>Electricity Tariff</h3> */}
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <label className="az-content-label">Settings</label>
                    <span className="d-block py-2">Manage Electricity Tariff</span>
                </div>
                {/* <div className="col-md-4 d-flex justify-content-end p-0">
                    <Link className="btn btn-az-primary btn-md" to="/substation/create">Add Site Location</Link>
                </div> */}
            </div>
            <hr className="mt-0" />
            <div className="row mt-4">
                {userDetails.role === userRole.superAdmin ? (
                    <CreateTariffCategory getElectricityTariffList={getElectricityTariff} />
                ) : (
                    ""
                )}
                <div className={`col-md-${userDetails.role === userRole.superAdmin ? "8" : "12"}`}>
                    <ElectricityTariffTable
                        listLoading={isLoading}
                        data={eTariff}
                        getElectricityTariff={getElectricityTariff}
                        limit={limit}
                        total={total}
                        setPage={setPage}
                        setLimit={setLimit}
                    />
                </div>
            </div>
        </div>
    );
};