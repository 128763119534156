import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import TypesTable from "./TypesTable";
import { api } from "../../api";
import { notification } from "antd";

export const LineupTypes = () => {
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [lineupTypes, setLineupTypes] = useState([]);
  const [lineupTypeData, setLineupTypeData] = useState({
    name: "",
    code: "",
  });
  const { name, code } = lineupTypeData;
  const onInputChange = (e) => {
    setLineupTypeData({ ...lineupTypeData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`/lineup-type`, lineupTypeData, {
        withCredentials: true,
      });
      if (response) {
        setLineupTypeData({ name: "", code: "" });
        getLineupTypes();
        setSuccessMessage("Switchgear Type Created Successfully");
        setTimeout(() => {
          setSuccessMessage();
        }, 2000);
      }
    } catch (error) {
      notification.warning({
        placement: "bottomLeft",
        message: error?.response?.data?.msg || "Something went wrong.",
      });
    }
    setIsLoading(false);
  };
  const getLineupTypes = async () => {
    const response = await api.get(`/lineup-type`, { withCredentials: true });
    if (response) {
      setLineupTypes(response);
    }
  };
  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Manage Switchgear Types";
    getLineupTypes();
  }, []);
  return (
    <div className="card p-3 m-3">
      <label className="az-content-label mt-1">Manage Sensors</label>
      <div className="row gx-5">
        <div className="col-md-6">
          <span className="d-block py-2">Add Switchgear Type</span>
          <hr className="mt-0" />
          <div className="d-flex justify-content-center">
            {isLoading && <Spinner animation="border" variant="dark" />}
          </div>
          {SuccessMessage && (
            <div className="alert alert-success" role="alert">
              {SuccessMessage}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Switchgear Type Value
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={onInputChange}
                className="form-control"
                id="name"
                placeholder="Enter A Lineup Type Value"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="form-label">
                Code
              </label>
              <input
                type="text"
                name="code"
                value={code}
                onChange={onInputChange}
                className="form-control"
                id="code"
                placeholder="Enter code"
                required
              />
            </div>
            <div className="float-end">
              <button type="submit" className="btn btn-success me-2">
                Create Switchgear Type
              </button>
              <Link to="/sensor" className="btn btn-secondary">
                Cancel
              </Link>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <TypesTable
            data={lineupTypes}
            getTypesList={getLineupTypes}
            title="Switchgear"
            apiEndPoint="lineup-type"
          />
        </div>
      </div>
    </div>
  );
};
