import axios from "axios";
import { Button, Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiTrash, FiEye, FiEdit } from "react-icons/fi";
import { MdOutlineDevicesOther } from "react-icons/md";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import appConfig from "../../appConfig";
import { DataTable } from "../../components";

const AlarmSummary = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchByLocation, setSearchByLocation] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const navigate = useNavigate();

  console.log(searchByLocation);
  const getNotifications = async () => {
    setIsLoading(true);
    try {
      let { results, count } = await api.get(endpoint.notification, {
        params: {
          limit,
          page,
          sortBy: "date",
          orderBy: "DESC",
          searchText: searchTerm,
          site: selectedSite,
        },
      });

      setNotifications(results);
      setTotal(count || 0);
    } catch (error) {}
    setIsLoading(false);
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSiteLocation();
  }, []);

  useEffect(() => {
    document.title = `${appConfig.name} - System Alarm Summary`;
    getNotifications();
  }, [page, limit, searchTerm, selectedSite]);

  const handleSearch = (event) => {
    navigateWithQuryParams({ s: event.target.value });
  };
  const handleLocation = (e) => {
    setSearchByLocation(e.target.value);
  };

  const columns = [
    {
      title: "No.",
      render: (_, __, index) => <div>{(page - 1) * limit + index + 1}</div>,
      width: "60px",
      align: "center",
    },
    {
      title: "Alarm Name",
      render: (row) => <div>{row?.name}</div>,
    },
    {
      title: "Sensor Name",
      render: (row) => row?.device?.name,
    },
    {
      title: "Substation Name",
      render: (row) => <>{row?.site?.name}</>,
    },
    {
      title: "Date Created",
      render: (row) => moment(row?.createdAt).format("DD/MM/YYYY"),
    },
    {
      title: "Action",
      render: (row) => (
        <div>
          <Link
            to={`/alarm-view/` + row?._id}
            title="View Alarm"
            className="btn btn-success me-1"
          >
            <FiEye />
          </Link>
          <Link to={`/edit-alarm/` + row?._id} className="btn btn-info me-1">
            <FiEdit title="Edit" />
          </Link>
          <Link
            to={`/sensor/details/` + row?.device?._id}
            title="View Device"
            className="btn btn-warning me-1"
          >
            <MdOutlineDevicesOther />
          </Link>
          <button
            className="btn btn-danger"
            onClick={() => deleteAlarm(row?._id)}
          >
            <FiTrash title="Delete" />
          </button>
        </div>
      ),
      width: "220px",
      align:"center"
    },
  ];

  const deleteAlarm = async (alarmId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Alarm?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_API_URL}/notification/` + alarmId, {
            withCredentials: true,
          })
          .then((res) => {
            getNotifications();
            Swal.fire({
              title: "Done!",
              text: "Alarm Deleted Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const navigateWithQuryParams = (payload) => {
    let queryParams = {};
    searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    if (Object.keys(payload).includes("s")) {
      queryParams.page = 1;
    }
    queryParams = new URLSearchParams({ ...queryParams, ...payload });
    navigate({ search: queryParams.toString() });
  };

  useEffect(() => {
    setPage(Number(searchParams.get("page")) || 1);
    setLimit(Number(searchParams.get("limit")) || 10);
    setSearchTerm(searchParams.get("s") || "");
    setSelectedSite(searchParams.get("substation") || "");
  }, [searchParams]);

  return (
    <div className="settings">
      <div className="card p-3 m-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">MANAGE NOTIFICATION</label>
            <span className="d-block py-2">System Alarm Summary</span>
          </div>
          <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/create-alarm">
              Create New Alarm
            </Link>
          </div>
        </div>
        <hr className="mt-0" />
        <div className="d-flex my-3">
          <div className="col-md-4">
            <select className="form-select" onChange={handleLocation}>
              <option label="All Substations"></option>
              {siteLocation &&
                siteLocation.length > 0 &&
                siteLocation.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-2">
            <Button
              onClick={() => {
                navigateWithQuryParams({ substation: searchByLocation });
              }}
              variant="success"
            >
              Search
            </Button>
          </div>
          <div className="col-md-4"></div>

          <div className="col-md-2">
            <Form className="d-flex">
              <Form.Control
                type="search"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
            </Form>
          </div>
        </div>
        {/* <h3>System Alarm Summary</h3> */}
        <Spin spinning={isLoading}>
          <DataTable
            columns={columns}
            dataSource={notifications}
            pagination={{
              defaultPageSize: limit,
              current: page,
              total,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total}`,
              pageSizeOptions: [10, 20, 50],
              responsive: true,
            }}
            onChange={(pagination) =>
              navigateWithQuryParams({
                page: pagination.current,
                limit: pagination.pageSize,
              })
            }
          />
        </Spin>
      </div>
    </div>
  );
};

export default AlarmSummary;
