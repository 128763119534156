import { Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { AiOutlineEye } from "react-icons/ai";
import { BiEdit, BiUserX } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { api, endpoint } from "../../api";
import { userRole } from "../../constant";
// import DashboardLayoutHome from "./DashboardLayoutHome";
import moment from "moment";
import { useSelector } from "react-redux";
import { FiUserCheck } from "react-icons/fi";
import { getFormatedUserType } from "../../utils/userTypeFomater";

const AssignDashboard = () => {
  const { userDetails } = useSelector((state) => state.user);

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [role, setRole] = useState("");
  const [dashboardList, setDashboardList] = useState([]);
  // const [limit, setLimit] = useState(10);
  // const [page, setPage] = useState(1);
  // const [total, setTotal] = useState(0);
  // const [data, setData] = useState([]);
  // const [assign, setAssign] = useState([]);

  const [assignList, setAssignList] = useState([]);
  const [assignListTotal, setAssignListTotal] = useState(0);
  const [assignLimit, setAssignLimit] = useState(10);
  const [assignPage, setAssignPage] = useState(1);

  const [sites, setSites] = useState([]);
  const [formData, setFormData] = useState({
    siteLocation: "",
    siteUser: "",
    dashboardId: "",
    dashboardType: "",
  });

  // get location
  const getLocation = async () => {
    const response = await axios.get(`/site-location`, {
      withCredentials: true,
    });
    setLocations(response?.data);
  };

  // get site user
  const getUsers = async () => {
    setIsLoading(true);
    try {
      if (role && formData.siteLocation) {
        const { results } = await api.get(endpoint.all_users, {
          params: {
            sortBy: "createdAt",
            orderBy: "DESC",
            role,
            site: formData.siteLocation,
            limit: 100000,
            page: 1
          },
        });
        setUsers(results);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  // get dashboard list
  const getDashboardList = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await api.get(endpoint.get_dashboard_list, {
        params: {
          // deviceId: device,
          limit: 1000,
          page: 1,
        },
      });
      setDashboardList(response?.data || []);
      setIsLoading(false);
    }
  };

  // const getAssign = async () => {
  //   try {
  //     if (!isLoading && formData.dashboardId) {
  //       setIsLoading(true)
  //       // const response = await axios.post(endpoint.get_assign, { dashboard: formData.dashboardId }, { withCredentials: true });
  //       const { results, count } = await api.get(endpoint.get_assign, {
  //         params: {
  //           limit,
  //           page,
  //           dashboardType: formData.dashboardType,
  //           sortBy: 'createdAt',
  //           orderBy: 'DESC',
  //         },
  //       });
  //       setAssign(results)
  //       setTotal(count)
  //       setIsLoading(false)
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "Opps",
  //       text: error.message,
  //       icon: "danger",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Confirm",
  //     })
  //   }
  // }

  // getAllAssignList
  const getAllAssignList = async () => {
    try {
      if (!isLoading) {
        setIsLoading(true);
        const { results, count } = await api.get(endpoint.get_assign, {
          params: {
            limit: assignLimit,
            page: assignPage,
            dashboardType: formData.dashboardType,
            sortBy: "createdAt",
            orderBy: "DESC",
          },
        });
        setAssignList(results);
        setAssignListTotal(count);
        setIsLoading(false);
      }
    } catch (error) {
      Swal.fire({
        title: "Opps",
        text: error.message,
        icon: "danger",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      });
    }
  };

  useEffect(() => {
    getLocation();
    getDashboardList();
  }, []);

  useEffect(() => {
    getAllAssignList();
  }, [assignLimit, assignPage]);

  // useEffect(() => {
  //   getAssign()
  // }, [formData.dashboardId]);

  useEffect(() => {
    getUsers();
  }, [role, formData.siteLocation]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRemoveAssign = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await axios.delete(`/dashboard/assign/${id}`, {
          withCredentials: true,
        });
        // getAssign()
        getAllAssignList();
        Swal.fire({
          title: "Deleted!",
          text: "User Unassigned to Dashboard Successfully.",
          icon: "success",
          timer: 1500,
        });
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      site: formData.siteLocation,
      user: formData.siteUser,
      dashboard: formData.dashboardId,
      dashboardType: Number(formData.dashboardType),
    };

    try {
      const res = await axios.post(endpoint.assign_dashboard, body, {
        withCredentials: true,
      });
      console.log({ res });
      if (res.status === 202) {
        Swal.fire({
          title: "",
          text: "User assigned successfully.",
          icon: "success",
          timer: 1500,
        });
      }
    } catch (error) {
      if (error?.response) {
        Swal.fire({
          title: "Opps",
          text: "User already assigned to the dashboard.",
          icon: "danger",
          timer: 1500,
        });
      }
    }
    // getAssign()
    getAllAssignList();
  };

  const makeDefaultHandler = async (userId, dashboardId, isActive) => {
    Swal.fire({
      title: "Are you sure?",
      text: !isActive ? "This will remove this dashboard as default dashboard." :
        "Make this dashboard as default dashboard.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: "#198754"
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const body = { userId, dashboardId, isActive };
          const { success, message } = await api.put(endpoint.active_dashboard, body);
          console.log({ success });
          if (success) {
            // getAssign()
            getAllAssignList();
            Swal.fire({
              title: "Success!",
              text: "Default dashboard set successfully.",
              icon: "success",
              timer: 1500,
            });
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "User has already default dashboard.",
              icon: "error",
              timer: 1500,
            });
          }
        }
      })
      .catch(() =>
        Swal.fire({
          title: "",
          text: "User has already default dashboard.",
          icon: "error",
          timer: 1500,
        })
      );
  };

  const columnsList = [
    {
      name: "No.",
      selector: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: "Assign Dashboard",
      cell: (row) => row.dashboard?.name,
    },
    {
      name: "User Name",
      cell: (row) => row.user?.name,
    },
    {
      name: "Email",
      cell: (row) => row.user?.email,
      grow: 2,
    },
    {
      name: "User Type",
      cell: (row) => getFormatedUserType(row.user?.role),
      center: true
    },
    // {
    //   name: "Site",
    //   cell: (row) => row.site?.name,
    // },
    {
      name: "Date Assign",
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      center: true,
    },
    // {
    //   name: "Status",
    //   cell: (row) => Boolean(row.isActive) ? (
    //     <div className="badge text-bg-success"> Active </div>
    //   ) : (
    //     <div className="badge text-bg-danger"> Deactivated </div>
    //   ),
    //   center: true,
    // },
    {
      name: "Action",
      width: "220px",
      center: true,
      cell: (row) => (
        <div>
          <Link to={`/users/details/${row.user?._id}`}>
            <Button className="me-2 btn-info">
              <AiOutlineEye className="text-white" />
            </Button>
          </Link>
          {/* EditDashboard */}
          {row?.isActive ? (
            <Button
              title="Default Selected"
              className="me-2"
              onClick={() => makeDefaultHandler(row?.user?._id, row?.dashboard?._id, !row?.isActive)}
            >
              <FiUserCheck />
            </Button>
          ) : (
            <Button
              variant="warning"
              title="Make Default"
              className="me-2"
              onClick={() => makeDefaultHandler(row?.user?._id, row?.dashboard?._id, !row?.isActive)}
            >
              <BiUserX className="text-white" />
            </Button>
          )}
          <Button className="me-2 btn-danger" onClick={() => handleRemoveAssign(row._id)}>
            <MdDeleteOutline className="text-white" />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="px-3 mt-3">
      <div className="border bg-white p-3">
        {/* <h5 className="fw-bold">Assign Dashboard</h5> */}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">Manage Dashboard</label>
            <span className="d-block py-2">Assign Dashboard</span>
          </div>
          {/* <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/newDashboard">Create Dashboard</Link>
          </div> */}
        </div>
        <hr className="mt-0 mb-5" />
        <h6 className="fw-bold mt-4">Assign Existing System User Accounts</h6>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>Substation</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  onChange={handleChange}
                  value={formData.siteLocation}
                  name="siteLocation"
                >
                  <option value="">Select Substation</option>
                  {locations &&
                    locations.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>User Role</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  onChange={(e) => setRole(e.target.value)}
                  value={role}
                  name="userType"
                >
                  <option value="" disabled>
                    Select role
                  </option>
                  {Object.keys(userRole).map((role) =>
                    role === userRole.superAdmin ? (
                      ""
                    ) : (
                      <option key={role} value={role}>
                        {getFormatedUserType(role)}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>Site User</Form.Label>
                <Form.Select
                  required
                  className="text-secondary"
                  onChange={handleChange}
                  value={formData.siteUser}
                  name="siteUser"
                >
                  <option value="">Select user</option>
                  {users.length > 0 &&
                    users.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group className="my-2">
                <Form.Label>Dashboard</Form.Label>
                <Form.Select
                  required
                  value={formData.dashboardType ? `${formData.dashboardType}:${formData.dashboardId}` : ""}
                  className="text-secondary"
                  onChange={(e) => {
                    const [dashboardType, _id] = e.target.value.split(":");
                    setFormData({
                      ...formData,
                      dashboardType: dashboardType,
                      dashboardId: _id,
                    });
                  }}
                  name="dashboardId"
                >
                  <option value="">Select dashboard</option>
                  {dashboardList &&
                    dashboardList?.map((item, index) => (
                      <option key={index} value={`${item.dashboardType}:${item._id}`}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={8} className="d-flex justify-content-end">
              <div className="marginTop">
                <Button type="submit" className="px-4 " variant="success">
                  Assign
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <hr />
        <div className="mt-4">
          {/* <h5 className="text-center"> Assign Dashboard </h5>
          <Spin spinning={isLoading}>
            <DataTable
              columns={columns}
              data={assign}
              pagination
              paginationServer
              // progressPending={isLoading}
              // progressComponent={<Spin />}
              noDataComponent={isLoading ? "Loading" : "No record found."}
              className="mt-3"
              paginationTotalRows={total}
              paginationPerPage={limit}
              onChangePage={(data) => {
                setPage(data);
              }}
              onChangeRowsPerPage={(data) => {
                setLimit(data);
                setPage(1);
              }}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </Spin> */}

          {userDetails.role === userRole.superAdmin && (
            <div>
              <h6 className="fw-bold"> Assign List </h6>
              <Spin spinning={isLoading}>
                <DataTable
                  columns={columnsList}
                  data={assignList}
                  pagination
                  paginationServer
                  noDataComponent={isLoading ? "Loading" : "No record found."}
                  className="mt-3"
                  paginationTotalRows={assignListTotal}
                  paginationPerPage={assignLimit}
                  onChangePage={(data) => {
                    setAssignPage(data);
                  }}
                  onChangeRowsPerPage={(data) => {
                    setAssignLimit(data);
                    setAssignPage(1);
                  }}
                  paginationRowsPerPageOptions={[10, 20, 50]}
                />
              </Spin>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignDashboard;
