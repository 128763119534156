import { userRole } from "../constant";

export const getFormatedUserType = (text) => {
  switch (text) {
    case userRole.admin:
      return "Admin";
    case userRole.installer:
      return "Installer";
    case userRole.user:
      return "Site User";
    case userRole.public:
      return "Public User";
    default:
      return "Super Admin";
  }
};

