import axios from 'axios';

const baseUrl = '/device';

export const uploadBulkDeviceData = (payload) => {
  return axios.post(`${baseUrl}/csv-upload`, payload);
};

export const getCSVUploadStatus = (payload) => {
  return axios.get(`${baseUrl}/csv-upload-status`, payload);
};

export const updateCSVUploadStatus = (payload) => {
  return axios.put(`${baseUrl}/csv-upload-status`, payload);
};
