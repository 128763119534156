import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { Select, Spin } from "antd";
import { DataTable } from "../../components";
import moment from "moment";
import { PanelTimeFilter } from "./PanelTimeFilter";
import { PanelImageView } from "./PanelImageView";

const PanelOverview = () => {
  const [siteLocations, setSiteLocations] = useState([]);
  const [alarmHistory, setAlarmHistory] = useState([]);
  const [lineup, setLineup] = useState([]);
  const [panels, setPanels] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLineup, setSelectedLineup] = useState("");
  const [selectedPanel, setSelectedPanel] = useState("");
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isBlinking, setBlinking] = useState(true);
  const [isFilterItemsLoading, setFilterItemLoading] = useState(false);
  const [filters, setFilters] = useState({
    site: "",
    lineupType: "",
    panelType: "",
  });

  const getFilterOptionsBySite = async (site, firstLoad) => {
    setFilterItemLoading(true);
    try {
      setSelectedLineup("");
      setSelectedPanel("");

      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site,
          },
        }
      );
      setLineup(response?.data?.lineupType || []);
      setPanels(response?.data?.panelType || []);
      if (firstLoad) {
        let firstLineup = response?.data?.lineupType?.[0]?._id || "";
        setSelectedLineup(firstLineup);
        getFilterOptionsByLineup(firstLineup, firstLoad, site);
      }
    } catch (error) {
      setLineup([]);
      setPanels([]);
      console.log(error);
    }
    setFilterItemLoading(false);
  };
  const getFilterOptionsByLineup = async (lineupType, firstLoad, site) => {
    setFilterItemLoading(true);
    try {
      setSelectedPanel("");
      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site: site || selectedLocation,
            lineupType,
          },
        }
      );
      setPanels(response?.data?.panelType || []);
      if (firstLoad) {
        let firstPanelType = response?.data?.panelType?.[0]?._id || "";
        setSelectedPanel(firstPanelType);
        setFilters({
          site: site,
          lineupType,
          panelType: firstPanelType,
        });
      }
    } catch (error) {
      setPanels([]);
    }
    setFilterItemLoading(false);
  };
  const searchHandler = async (e) => {
    e.preventDefault();
    try {
      setLimit(10);
      setPage(1);
      getAlarmHisotry();
      setFilters({
        site: selectedLocation,
        lineupType: selectedLineup,
        panelType: selectedPanel,
      });
    } catch (error) {}
  };

  const getAlarmHisotry = async () => {
    if (!filters.site || !filters?.lineupType || !filters?.panelType) return;
    try {
      const response = await axios.get(`/notification/history`, {
        withCredentials: true,
        params: {
          site: filters.site,
          lineupType: filters.lineupType,
          panelType: filters.panelType,
          limit,
          page,
        },
      });
      const { results, count } = response.data;
      setAlarmHistory(results);
      setTotal(count || 0);
    } catch (error) {}
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setSiteLocations(data);
        let firstSite = data?.[0]?._id;
        if (firstSite) {
          getFilterOptionsBySite(firstSite, true);
          setSelectedLocation(firstSite);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
      getAlarmHisotry();
      const intervalId = setInterval(() => {
      getAlarmHisotry();
    }, 600000);
    return () => clearInterval(intervalId);
  }, [limit, page, filters]);

  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Dashboard";
    getSiteLocations();
    const intervalId = setInterval(() => {
      setBlinking((prevBlinking) => !prevBlinking);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="az-content-body">
      <div className="card p-3">
        <Form onSubmit={searchHandler}>
          <div className="row mb-3 gy-3">
            <div className="col-md-3">
              <Select
                className="w-100"
                size="large"
                value={selectedLocation || null}
                placeholder="Select Substations"
                // mode="multiple"
                showArrow
                showSearch={false}
                onChange={(value) => {
                  setSelectedLocation(value);
                  getFilterOptionsBySite(value);
                }}
              >
                {siteLocations?.length > 0 &&
                  siteLocations.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-3">
              <Select
                loading={isFilterItemsLoading}
                className="w-100"
                value={selectedLineup || null}
                placeholder="Select Switchgear"
                onChange={(value) => {
                  setSelectedLineup(value);
                  getFilterOptionsByLineup(value);
                }}
                size="large"
                // mode="multiple"
                showArrow
                showSearch={false}
              >
                {lineup?.length > 0 &&
                  lineup.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-3">
              <Select
                loading={isFilterItemsLoading}
                className="w-100"
                value={selectedPanel || null}
                onChange={(e) => setSelectedPanel(e)}
                placeholder="Select Panel Type"
                size="large"
                // mode="multiple"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                {panels?.length > 0 &&
                  panels.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-3">
              <Button type="submit" variant="success">
                Search
              </Button>
            </div>
          </div>
        </Form>
        <hr className="mt-0" />
        <div className="row my-3">
          <div className="col-md-12">
            <div className="mb-3">
              <label style={{ fontWeight: "500" }}>Panel Overview</label>
            </div>
          </div>
          <PanelImageView {...filters} />
          <div className="mb-3">
            <label style={{ fontWeight: "500" }}>
              Historical Alarm Summary
            </label>
          </div>

          <Spin spinning={false}>
            <DataTable
              columns={getColumn(isBlinking)}
              dataSource={alarmHistory}
              pagination={{
                pageSize: limit,
                current: page,
                total,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[1]}-${range[1]} of ${total}`,
                pageSizeOptions: [10, 20, 50],
                responsive: true,
              }}
              onChange={(pagination) => {
                setLimit(pagination.pageSize);
                setPage(pagination.current);
              }}
            />
          </Spin>
          <PanelTimeFilter {...filters} />
        </div>
      </div>
    </div>
  );
};

export default PanelOverview;

const getColumn = (isBlinking) => [
  {
    title: "Trigger Time & Date",
    render: (_, row) => moment(row.createdAt).format("DD/MM/YY | HH:MM:ss"),
  },
  {
    title: "Lineup",
    align: "center",
    render: (_, row) => row?.notification?.lineupType?.name,
  },
  {
    title: "Panel No",
    align: "center",
    render: (_, row) => row?.notification?.panelType?.name,
  },
  {
    title: "Compartment",
    align: "center",
    render: (_, row) => row?.notification?.compartmentType?.name,
  },
  {
    title: "Parameter",
    align: "center",
    render: (_, row) => row?.notification?.parameter?.name,
  },
  {
    title: "Sensor Name",
    align: "center",
    render: (_, row) => row?.notification?.device?.name,
  },
  {
    title: "Measurement",
    align: "center",
    render: (_, record) => (
      <BlinkingTableCell
        value={record.value}
        status={record.alarmStatus}
        isBlinking={isBlinking}
      />
    ),
  },
  {
    title: "Alarm Name",
    align: "center",
    render: (_, row) => row?.notification?.name,
  },
];

const BlinkingTableCell = ({ value, status, isBlinking }) => {
  const backgroundStyle = {
    backgroundColor: isBlinking ? getBlinkingBG(status) : "transparent",
    fontWeight: "bold",
  };

  return <div style={backgroundStyle}>{value}</div>;
};

export const getBlinkingBG = (status) => {
  let color = "transparent";

  if (status === "critical") color = "red";
  else if (status === "caution") color = "#DEC20B";
  else if (status === "high") color = "orange";
  // console.log({ value, status, isBlinking, color });
  return color;
};
