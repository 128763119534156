import axios from "axios";
import React, { useEffect, useState } from "react";
import ReportTable from "../../components/Reports/ReportTable";
import Select from "react-select";
import { useMemo } from "react";
import { api, endpoint } from "../../api";
import { Link } from "react-router-dom";
import { exportCSV } from "../../utils/exportCsv";
import NivoLine from "../../components/Charts/NivoLine";
import { DatePicker, Select as AntdSelect } from "antd";
import moment from "moment";
import SplineChart from "../../components/Charts/SplineChart";

const AnalysisReporting = () => {
  const [isFilterItemsLoading, setFilterItemLoading] = useState(false);
  const [panelList, setPanelList] = useState([]);
  const [lineupList, setLineuplist] = useState([]);
  const [compartmentList, setCompartmentList] = useState([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isLoadingGraph, setIsLoadingGraph] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [reportTypeGraph, setReportTypeGraph] = useState(true);
  const [devices, setDevices] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceTableData, setDeviceTableData] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState();
  const [sites, setSites] = useState([]);
  const [graphFrom, setGraphFrom] = useState();
  const [graphTo, setGraphTo] = useState();
  const [state, setState] = useState({
    interval: "",
    device: "",
    from: "",
    to: "",
    operator: "",
    parameters: [],
    lineupType: "",
    panelType: "",
    compartmentType: "",
    site: "",
  });
  console.log(state);
  const {
    interval,
    device,
    from,
    to,
    parameters,
    lineupType,
    panelType,
    compartmentType,
    site,
  } = state;

  const options = useMemo(() => {
    if (deviceParameters) {
      return deviceParameters.map((item) => {
        return { value: item._id, label: item.name };
      });
    } else {
      return [];
    }
  }, [deviceParameters]);

  const getFilterOptionsBySite = async (site) => {
    setFilterItemLoading(true);
    try {
      // selected items
      setState({
        ...state,
        site,
        lineupType: "",
        panelType: "",
        compartmentType: "",
        device: "",
        parameters: [],
      });
      // list
      setDeviceParameters([]);
      setDevices([]);

      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site,
          },
        }
      );
      setLineuplist(response?.data?.lineupType || []);
      setPanelList(response?.data?.panelType || []);
      setCompartmentList(response?.data?.compartmentType || []);
      // setSensorTypes(response?.data?.deviceType || []);
    } catch (error) {
      setLineuplist([]);
      setPanelList([]);
      setCompartmentList([]);
      // setSensorTypes([]);
      console.log(error);
    }
    setFilterItemLoading(false);
  };

  const getFilterOptionsByLineup = async (lineupType) => {
    setFilterItemLoading(true);
    setState({
      ...state,
      lineupType,
      panelType: "",
      compartmentType: "",
      device: "",
      parameters: [],
    });
    setDevices([]);
    setDeviceParameters([]);
    try {
      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site: state?.site,
            lineupType,
          },
        }
      );

      setPanelList(response?.data?.panelType || []);
      setCompartmentList(response?.data?.compartmentType || []);
    } catch (error) {
      setPanelList([]);
      setCompartmentList([]);
    }
    setFilterItemLoading(false);
  };

  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleParameterChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const getDeviceParameters = async (deviceId) => {
    try {
      setDeviceParameters([]);
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}
  };

  useEffect(() => {
    if (device) {
      setState({ ...state, parameters: [] });

      getDeviceParameters(device);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);

  const getDevices = async () => {
    try {
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site, panelType, lineupType, compartmentType },
      });
      if (response) {
        setDevices(response.data?.results);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (site && panelType && lineupType && compartmentType) {
      getDevices();
    }
  }, [site, panelType, lineupType, compartmentType]);

  const getSiteLocationList = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      let data = response.data;
      setSites(data);
      getFilterOptionsBySite(data?.[0]?._id, true);
    } catch (error) {}
  };

  /**
   * @param { String } type
   * @type graph | table
   */
  const getReport = async (type) => {
    if (type === "graph") {
      setIsLoadingGraph(true);
      setGraphFrom(from);
      setGraphTo(to);
    } else {
      setIsLoadingTable(true);
    }
    const body = { ...state, interval: state.interval || "1" };
    if (state.parameters?.length)
      body.parameters = state.parameters.map((p) => p.label);
    try {
      const response = await api.post(endpoint.parameter_comparison, body, {
        params: { limit, page, type },
      });
      // console.log({ response });
      if (type === "graph") {
        // const data = response.map((d) => ({ id: d._id, data: d.data }));
        setDeviceData(response);
      } else {
        setDeviceTableData(response?.results);
        setTotal(response?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
    if (type === "graph") {
      setIsLoadingGraph(false);
    } else {
      setIsLoadingTable(false);
    }
  };

  const handleDownloadReport = () => {
    const device = devices.find((d) => d._id === state.device);
    exportCSV({
      data: deviceTableData.map(({ _id, date, ...e }) => {
        return {
          Date: date,
          Device: device.apiKey,
          ...e,
        };
      }),
      filename: `report-parameter-comparison-${device.apiKey}`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    getReport("graph");
    getReport("table");
  };

  useEffect(() => {
    getSiteLocationList();

    document.title = `TNB Switchgear 2.0 - Parameter Comparison`;
  }, []);

  return (
    <div className="analysis">
      <div className="card p-3 m-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">
              Manage Analysis and Reporting
            </label>
            <span className="d-block py-2">Sensor Parameter Comparison</span>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            {deviceTableData.length > 0 && (
              <button
                className="btn btn-danger me-2"
                onClick={handleDownloadReport}
              >
                Download Report
              </button>
            )}
            <Link to="/" className="btn btn-secondary">
              Back
            </Link>
          </div>
        </div>
        <hr className="mt-0" />

        <div className="row my-3">
          <div className="col-md-12">
            <button
              className={`btn me-2 ${
                reportTypeGraph ? " btn-info" : "btn-success"
              }`}
              onClick={() => setReportTypeGraph(true)}
            >
              Graph
            </button>
            <button
              className={`btn ${
                !reportTypeGraph ? " btn-info" : "btn-success"
              }`}
              onClick={() => setReportTypeGraph(false)}
            >
              Table
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row ">
            <div className="col-md-3 mt-2">
              <select
                className="form-select"
                name="interval"
                defaultValue={interval}
                onChange={onInputChange}
              >
                <option value="">Default</option>
                <option value="30">30 Min</option>
                <option value="60">1 Hour</option>
                <option value="1440">24 Hours</option>
              </select>
            </div>
            <div className="col-md-3 mt-2">
              <select
                className="form-select"
                name="site"
                onChange={(e) => {
                  getFilterOptionsBySite(e.target.value);
                }}
                defaultValue={""}
              >
                <option disabled label="Select Substation" value={""}></option>
                {sites?.length &&
                  sites.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3 mt-2">
              <select
                name="lineupType"
                id="lineupType"
                value={lineupType}
                className="form-select"
                onChange={(e) => {
                  getFilterOptionsByLineup(e.target.value);
                }}
              >
                <option>Select Switchgear</option>
                {lineupList?.length &&
                  lineupList.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-3 mt-2">
              <AntdSelect
                className="w-100"
                value={panelType || null}
                onChange={(value) => {
                  setState({
                    ...state,
                    panelType: value,
                    compartmentType: "",
                    device: "",
                    parameters: [],
                  });
                  setDevices([]);
                  setDeviceParameters([]);
                }}
                placeholder="Select Panel Type"
                size="large"
                showArrow
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                {panelList?.length > 0 &&
                  panelList.map((item) => (
                    <AntdSelect.Option key={item._id}>
                      {item.name}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
            </div>
            <div className="col-md-3 mt-2">
              <AntdSelect
                className="w-100"
                value={compartmentType || null}
                onChange={(value) => {
                  setState({
                    ...state,
                    compartmentType: value,
                    device: "",
                    parameters: [],
                  });
                  setDevices([]);
                  setDeviceParameters([]);
                }}
                placeholder="Select Compartment Type"
                size="large"
                showArrow
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                {compartmentList?.length > 0 &&
                  compartmentList.map((item) => (
                    <AntdSelect.Option key={item._id}>
                      {item.name}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
            </div>

            <div className="col-md-3 mt-2">
              <AntdSelect
                className="w-100"
                value={state?.device || null}
                onChange={(device) => setState({ ...state, device })}
                placeholder="Select Sensor"
                size="large"
                showArrow
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                {devices?.length > 0 &&
                  devices.map((item) => (
                    <AntdSelect.Option key={item._id}>
                      {item.name}
                    </AntdSelect.Option>
                  ))}
              </AntdSelect>
              {/* <select
                className="form-select"
                name="device"
                value={state.device}
                onChange={onInputChange}
              >
                <option>Select Sensor</option>
                {devices &&
                  devices.length > 0 &&
                  devices.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select> */}
            </div>
            <div className="col-md-3 mt-2">
              <DatePicker
                className="form-control"
                placeholder="Select Start Date"
                size="large"
                disabledDate={(current) => current > moment()}
                onChange={(e, date) => {
                  setState({ ...state, from: date });
                }}
              />
            </div>
            <div className="col-md-3 mt-2">
              <DatePicker
                className="form-control"
                placeholder="Select End Date"
                size="large"
                onChange={(e, date) => {
                  setState({ ...state, to: date });
                }}
                disabledDate={(current) => current > moment()}
              />
            </div>
            <div className="col-md-3 mt-2">
              <select
                className="form-select"
                name="operator"
                value={state.operator}
                onChange={onInputChange}
              >
                <option>Select Operator</option>
                <option value="max">Maximum</option>
                <option value="min">Minimum</option>
                <option value="avg">Average</option>
                <option value="sum">Sum</option>
                <option value="real">Real Time</option>
              </select>
            </div>
            <div className="col-md-3 mt-2">
              <Select
                suffixIcon={
                  <img
                    src="/img/arrow.svg"
                    style={{ width: "12px", marginRight: "2px" }}
                  />
                }
                isMulti
                value={parameters}
                onChange={(value) => handleParameterChange("parameters", value)}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable={true}
                placeholder={`Select Sensor Parameter`}
              />
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button className="btn btn-success" onClick={handleSubmit}>
                Generate
              </button>
            </div>
          </div>
        </form>

        <div className="row">
          <div className="col-md-12">
            <div className="mt-3 h-100">
              {reportTypeGraph ? (
                <div style={{ height: "400px" }}>
                  {/* <NivoLine
                    title="Parameter Comparison"
                    data={deviceData}
                    from={graphFrom}
                    to={graphTo}
                    isLoading={isLoadingGraph}
                    xAxis="day"
                  /> */}
                  <SplineChart data={deviceData} />
                </div>
              ) : (
                <ReportTable
                  title="Parameter Comparison"
                  data={deviceTableData}
                  parameters={parameters}
                  total={total}
                  limit={limit}
                  page={page}
                  setPage={setPage}
                  setLimit={setLimit}
                  isLoading={isLoadingTable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisReporting;
