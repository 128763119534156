import axios from "axios";
import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiEye, FiEdit, FiTrash } from "react-icons/fi";
import { AiOutlineFundView } from "react-icons/ai";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { api, endpoint } from "../../api";
import { Spin, Tag } from "antd";
import { Button, Form } from "react-bootstrap";
import { DataTable } from "../../components";

const DeviceList = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [limit, setLimit] = useState(Number(searchParams.get("limit")) || 10);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [searchTerm, setSearchTerm] = useState(searchParams.get("s") || "");
  const [searchByLocation, setSearchByLocation] = useState(
    searchParams.get("substation") || ""
  );
  const [searchByStatus, setSearchByStatus] = useState(
    searchParams.get("status") || ""
  );
  const [searchByPanelType, setSearchByPanelType] = useState(
    searchParams.get("panel") || ""
  );

  const [panelList, setPanelList] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [siteLocation, setSiteLocation] = useState([]);
  const [query, setQeury] = useState({});
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.user?.userDetails);

  useEffect(() => {
    setSearchTerm(searchParams.get("s") || "");
    setPage(Number(searchParams.get("page")) || 1);
    setLimit(Number(searchParams.get("limit")) || 10);
  }, [searchParams]);

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  const getPanel = async () => {
    try {
      const response = await axios.get(`/panel-type`, {
        withCredentials: true,
      });
      if (response) {
        setPanelList(response.data);
      }
    } catch (error) {}
  };

  const getDevices = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(endpoint.get_devices, {
        params: {
          limit,
          page,
          sortBy: "createdAt",
          orderBy: "DESC",
          searchText: searchTerm,
          // site: searchByLocation,
          // status: searchByStatus,
          ...query,
        },
      });
      setDevices(response?.results);
      setTotal(response?.count);
    } catch (error) {}
    setIsLoading(false);
  };

  const columns = [
    {
      title: "No.",
      render: (_, __, index) => <div>{(page - 1) * limit + index + 1}</div>,
      width: "60px",
      align: "center",
    },
    {
      title: "Sensor Name",
      render: (row) => row?.name,
    },
    {
      title: "Switchgear",
      render: (row) => row?.lineupType?.name,
    },
    {
      title: "Panel",
      render: (row) => row?.panelType?.name,
    },
    {
      title: "Compartment",
      render: (row) => row?.compartmentType?.name,
    },
    {
      title: "Substation",
      render: (_, row) => <div className="text-wrap">{row?.site?.name}</div>,
    },
    {
      title: "Status",
      render: (_, row) => (
        <div className="text-wrap">
          {row?.status === "active" ? (
            <Tag color="green">Online</Tag>
          ) : (
            <Tag color="magenta">Offline</Tag>
          )}
        </div>
      ),
    },
    {
      title: "Date Installed",
      render: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      align: "center",
    },
    {
      title: "Action",
      width: "220px",
      align: "center",
      render: (_, row) => (
        <div>
          {(() => {
            switch (userDetails.role) {
              case "superAdmin":
                return (
                  <div className="actions">
                    <Link
                      to={`/sensor/details/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/sensor/edit/` + row._id}
                      className="btn btn-info me-1"
                    >
                      <FiEdit />
                    </Link>
                    <Link
                      to={`/sensor/data/` + row._id}
                      className="btn btn-warning me-1"
                    >
                      <AiOutlineFundView />
                    </Link>
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteDevice(row._id)}
                    >
                      <FiTrash />
                    </button>
                  </div>
                );
              case "installer":
                return (
                  <div className="actions">
                    <Link
                      to={`/sensor/details/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/sensor/edit/` + row._id}
                      className="btn btn-info me-1"
                    >
                      <FiEdit />
                    </Link>
                    <Link
                      to={`/sensor/data/` + row._id}
                      className="btn btn-warning me-1"
                    >
                      <AiOutlineFundView />
                    </Link>
                  </div>
                );
              case "admin":
                return (
                  <div className="actions">
                    <Link
                      to={`/sensor/details/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/sensor/edit/` + row._id}
                      className="btn btn-info me-1"
                    >
                      <FiEdit />
                    </Link>
                    <Link
                      to={`/sensor/data/` + row._id}
                      className="btn btn-warning me-1"
                    >
                      <AiOutlineFundView />
                    </Link>
                  </div>
                );
              case "user":
                return (
                  <div className="actions">
                    <Link
                      to={`/sensor/details/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/sensor/data/` + row._id}
                      className="btn btn-warning me-1"
                    >
                      <AiOutlineFundView />
                    </Link>
                  </div>
                );
              case "public":
                return (
                  <div className="actions">
                    <Link
                      to={`/sensor/details/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye />
                    </Link>
                    <Link
                      to={`/sensor/data/` + row._id}
                      className="btn btn-warning me-1"
                    >
                      <AiOutlineFundView />
                    </Link>
                  </div>
                );
              default:
                return;
            }
          })()}
        </div>
      ),
    },
  ];
  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - All Sensors";
    getDevices();
    // eslint-disable-next-line
  }, [limit, page, searchTerm, query]);

  useEffect(() => {
    getPanel();
    getSiteLocation();
    const panel = searchParams.get("panel") || "";
    const status = searchParams.get("status") || "";
    const substation = searchParams.get("substation") || "";

    setSearchByPanelType(panel);
    setSearchByStatus(status);
    setSearchByLocation(substation);
    setQeury({ panel, status, site: substation });
  }, []);

  const deleteDevice = async (deviceId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this device?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/device/` + deviceId, {
            withCredentials: true,
          })
          .then((res) => {
            getDevices();
            Swal.fire({
              title: "Done!",
              text: "Device Deleted Successfully",
              icon: "info",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const navigateWithQuryParams = (payload) => {
    let queryParams = {};
    searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });
    queryParams = new URLSearchParams({ ...queryParams, ...payload });
    navigate({ search: queryParams.toString() });
  };

  const handleSearch = () => {
    const obj = {};
    obj.substation = searchByLocation;
    obj.panel = searchByPanelType;
    obj.status = searchByStatus;
    navigateWithQuryParams(obj);
    obj.site = searchByLocation;
    setQeury(obj);
  };

  return (
    <div className="card p-3 m-3">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <label className="az-content-label">Manage Sensors</label>
          <span className="d-block py-2">All Sensors</span>
        </div>
        <div className="col-md-4 d-flex justify-content-end p-0 gap-2">
          <Link className="btn btn-danger btn-md" to="/device-csv-upload">
            Bulk CSV Upload
          </Link>
          <Link className="btn btn-az-primary btn-md" to="/add-sensor">
            Add New Sensor
          </Link>
        </div>
      </div>

      <hr className="mt-0" />
      <div className="row my-3">
        <div className="col-md-2">
          <select
            className="form-select"
            value={searchByLocation || ""}
            onChange={(e) => setSearchByLocation(e.target.value)}
          >
            <option value="">All Substations</option>
            {siteLocation &&
              siteLocation.length > 0 &&
              siteLocation.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-2">
          <select
            value={searchByPanelType || ""}
            onChange={(e) => setSearchByPanelType(e.target.value)}
            className="form-select"
          >
            <option value="">All Panels</option>
            {panelList?.length &&
              panelList.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-2">
          <select
            className="form-select"
            value={searchByStatus || ""}
            onChange={(e) => setSearchByStatus(e.target.value)}
          >
            <option value="">All Status</option>
            <option value="active">Online</option>
            <option value="inactive">Offline</option>
          </select>
        </div>
        <div className="col-md-2">
          <Button onClick={handleSearch} variant="success">
            Search
          </Button>
        </div>
        <div className="col-md-2"></div>

        <div className="col-md-2">
          <Form className="d-flex">
            <Form.Control
              type="search"
              value={searchTerm}
              onChange={(e) => navigateWithQuryParams({ s: e.target.value })}
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
          </Form>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <DataTable
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={devices}
          pagination={{
            pageSize: limit,
            current: page,
            total,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSizeOptions: [10, 20, 50],
            responsive: true,
          }}
          onChange={(pagination) =>
            navigateWithQuryParams({
              page: pagination.current,
              limit: pagination.pageSize,
            })
          }
        />
      </Spin>
    </div>
  );
};

export default DeviceList;
