import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { PanelTimeFilter } from "./PanelTimeFilter";
import { Select } from "antd";
import { TriggeredAlarm } from "./TriggeredAlarm";
import { DeviceDataByPanel } from "./DeviceDataByPanel";
import _ from "lodash";

const SubstationOverview = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isFilterItemsLoading, setFilterItemLoading] = useState(false);
  const [siteLocations, setSiteLocations] = useState([]);
  const [lineup, setLineup] = useState([]);
  const [panels, setPanels] = useState([]);
  const [compartments, setCompartments] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [sensorTypes, setSensorTypes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLineup, setSelectedLineup] = useState("");
  const [selectedPanel, setSelectedPanel] = useState([]);
  const [selectedCompartment, setSelectedCompartment] = useState([]);
  const [selectedSensorType, setSelectedSensorType] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState([]);
  // set on search
  const [searchedDeviceParameter, setSearchedDeviceParameter] = useState([]);
  const [searchedLineup, setSearchedLineup] = useState("");
  const [searchedSite, setSearchedSite] = useState("");

  const searchHandler = async (e) => {
    e.preventDefault();
    try {
      if (
        selectedLocation &&
        selectedLineup &&
        selectedPanel?.length &&
        selectedCompartment?.length &&
        selectedSensorType?.length &&
        selectedParameter?.length
      ) {
        setSearchedDeviceParameter(selectedParameter);
      }
      if (selectedLineup) {
        setSearchedLineup(selectedLineup);
      }
      if (selectedLocation) {
        setSearchedSite(selectedLocation);
      }
    } catch (error) {}
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setSiteLocations(data);
        getFilterOptionsBySite(data?.[0]?._id, true);
      }
    } catch (error) {}
  };

  const getFilterOptionsBySite = async (site, firstLoad) => {
    setFilterItemLoading(true);
    try {
      // selected items
      setSelectedLineup("");
      setSelectedPanel([]);
      setSelectedCompartment([]);
      setSelectedSensorType([]);
      setSelectedParameter([]);
      // list
      setParameters([]);

      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site,
          },
        }
      );
      setLineup(response?.data?.lineupType || []);
      setPanels(response?.data?.panelType || []);
      setCompartments(response?.data?.compartmentType || []);
      setSensorTypes(response?.data?.deviceType || []);
      if (firstLoad) {
        getFilterOptionsByLineup(
          response?.data?.lineupType?.[0]?._id,
          firstLoad,
          site
        );
      }
    } catch (error) {
      setLineup([]);
      setPanels([]);
      setCompartments([]);
      setSensorTypes([]);
      console.log(error);
    }
    setFilterItemLoading(false);
  };
  const getFilterOptionsByLineup = async (lineupType, firstLoad, site) => {
    setFilterItemLoading(true);
    try {
      // selected items
      setSelectedPanel([]);
      setSelectedCompartment([]);
      setSelectedSensorType([]);
      setSelectedParameter([]);
      // list
      setParameters([]);

      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site: site || selectedLocation,
            lineupType,
          },
        }
      );
      setCompartments(response?.data?.compartmentType || []);
      setPanels(response?.data?.panelType || []);
      setSensorTypes(response?.data?.deviceType || []);
      if (firstLoad) {
        setSearchedSite(site);
        setSearchedLineup(lineupType);
        // selected
        setSelectedLocation(site);
        setSelectedLineup(lineupType);
        setSelectedPanel(response?.data?.panelType?.[0]?._id || "");
        setSelectedCompartment(response?.data?.compartmentType?.[0]?._id || "");
        setSelectedSensorType(response?.data?.deviceType?.[0]?._id || "");
      } else {
        setIsFirstLoading(false);
      }
    } catch (error) {
      setPanels([]);
      setCompartments([]);
      setSensorTypes([]);
      console.log(error);
    }
    setFilterItemLoading(false);
  };

  const getDeviceParameters = async () => {
    try {
      const response = await axios.get(`/device-parameter`, {
        withCredentials: true,
        params: {
          deviceType: JSON.stringify(selectedSensorType),
          site: JSON.stringify(selectedLocation),
          panel: JSON.stringify(selectedPanel),
          lineup: JSON.stringify([selectedLineup]),
          compartment: JSON.stringify(selectedCompartment),
        },
      });
      if (response) {
        let data = response.data?.results;
        setParameters(data);
        if (isFirstLoading) {
          setSearchedDeviceParameter([JSON.stringify(data?.[0])]);
        }
      }
    } catch (error) {}
  };

  const handlePanelType = (e) => {
    setSelectedParameter([]);
    console.log(e);
    if (e.includes("left")) {
      setSelectedPanel(
        panels
          .filter((item) => item.locationType === "left")
          .map(({ _id }) => _id)
      );
    } else if (e.includes("right")) {
      setSelectedPanel(
        panels
          .filter((item) => item.locationType === "right")
          .map(({ _id }) => _id)
      );
    } else if (e.includes("all")) {
      if (
        _.isEqual(
          panels.map(({ _id }) => _id),
          selectedPanel
        )
      ) {
        setSelectedPanel([]);
      } else {
        setSelectedPanel(panels.map(({ _id }) => _id));
      }
    } else {
      setSelectedPanel(e);
    }
  };

  useEffect(() => {
    if (
      selectedLocation &&
      selectedPanel?.length &&
      selectedCompartment?.length &&
      selectedLineup &&
      selectedSensorType?.length
    ) {
      getDeviceParameters();
    } else {
      setParameters([]);
    }
  }, [
    selectedLocation,
    selectedPanel,
    selectedCompartment,
    selectedLineup,
    selectedSensorType,
    isFirstLoading,
  ]);

  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Dashboard";
    getSiteLocations();
  }, []);

  return (
    <div className="az-content-body substation-overview-wrapper">
      <div className="card p-3">
        <Form onSubmit={searchHandler}>
          <div className="row mb-3 gy-3">
            <div className="col-md-3">
              <Select
                className="w-100"
                size="large"
                value={selectedLocation || null}
                placeholder="Select Substations"
                showArrow
                showSearch={false}
                onChange={(value) => {
                  setSelectedLocation(value);
                  getFilterOptionsBySite(value);
                }}
              >
                {siteLocations?.length > 0 &&
                  siteLocations.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-3">
              <Select
                loading={isFilterItemsLoading}
                className="w-100"
                value={selectedLineup || null}
                placeholder="Select Switchgear Type"
                onChange={(value) => {
                  getFilterOptionsByLineup(value);
                  setSelectedLineup(value);
                }}
                size="large"
                // mode="multiple"
                showArrow
                showSearch={false}
              >
                {lineup?.length > 0 &&
                  lineup.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-3">
              <Select
                loading={isFilterItemsLoading}
                className="w-100"
                value={selectedPanel}
                onChange={handlePanelType}
                placeholder="Select Panel Type"
                size="large"
                mode="multiple"
                showArrow
                maxTagCount={2}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="left">Left</Select.Option>
                <Select.Option value="right">Right</Select.Option>
                {panels?.length > 0 &&
                  panels.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>

            <div className="col-md-3">
              <Select
                loading={isFilterItemsLoading}
                className="w-100"
                value={selectedCompartment}
                showArrow
                mode="multiple"
                size="large"
                onChange={(e) => {
                  setSelectedParameter([]);
                  if (e.includes("all")) {
                    if (
                      _.isEqual(
                        compartments.map(({ _id }) => _id),
                        selectedCompartment
                      )
                    ) {
                      setSelectedCompartment([]);
                    } else {
                      setSelectedCompartment(
                        compartments.map(({ _id }) => _id)
                      );
                    }
                  } else {
                    setSelectedCompartment(e);
                  }
                }}
                placeholder="Select Compartment Type"
                maxTagCount={2}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                <Select.Option value="all">All</Select.Option>
                {compartments?.length > 0 &&
                  compartments.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-3">
              <Select
                loading={isFilterItemsLoading}
                className="w-100"
                value={selectedSensorType}
                onChange={(e) => {
                  setSelectedParameter([]);
                  if (e.includes("all")) {
                    if (
                      _.isEqual(
                        sensorTypes.map(({ _id }) => _id),
                        selectedSensorType
                      )
                    ) {
                      setSelectedSensorType([]);
                    } else {
                      setSelectedSensorType(sensorTypes.map(({ _id }) => _id));
                    }
                  } else {
                    setSelectedSensorType(e);
                  }
                }}
                showArrow
                mode="multiple"
                size="large"
                placeholder="Select Sensor Type"
                maxTagCount={2}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                <Select.Option value="all">All</Select.Option>
                {sensorTypes?.length > 0 &&
                  sensorTypes.map((item) => (
                    <Select.Option key={item._id}>{item.name}</Select.Option>
                  ))}
              </Select>
            </div>
            <div className="col-md-3">
              <Select
                loading={isFilterItemsLoading}
                className="w-100"
                value={selectedParameter}
                onChange={(value) => {
                  if (value.includes("all")) {
                    if (
                      _.isEqual(
                        parameters.map((item) => JSON.stringify(item)),
                        selectedParameter
                      )
                    ) {
                      setSelectedParameter([]);
                    } else {
                      setSelectedParameter(
                        parameters.map((item) => JSON.stringify(item))
                      );
                    }
                  } else {
                    setSelectedParameter(value);
                  }
                }}
                showArrow
                size="large"
                mode="multiple"
                placeholder="Select Parameter"
                maxTagCount={2}
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.children
                    ? option?.children.toLowerCase()
                    : ""
                  ).includes(input?.toLowerCase())
                }
              >
                <Select.Option value="all">All</Select.Option>
                {parameters?.length > 0 &&
                  parameters.map((item) => (
                    <Select.Option key={JSON.stringify(item)}>
                      {`${item?.device?.name} - ${item.name}`}
                    </Select.Option>
                  ))}
              </Select>
            </div>

            <div className="col-md-3">
              <Button type="submit" variant="success">
                Search
              </Button>
            </div>
          </div>
        </Form>
        <hr className="mt-0" />
        <div className="row my-3">
          <div className="col-md-12">
            <DeviceDataByPanel
              // searchedLineup={searchedLineup}
              lineup={lineup}
              site={selectedLocation}
            />
            {/* {searchedDeviceParameter?.length && ( */}
            <PanelTimeFilter
              substationOverview
              selectedParameterData={searchedDeviceParameter}
            />
            {/* )} */}
          </div>
          <TriggeredAlarm />
        </div>
      </div>
    </div>
  );
};

export default SubstationOverview;
