import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Select, Spin } from "antd";
import { validateFields } from "../../utils/validation";
import { endpoint } from "../../api";
import appConfig from "../../appConfig";

const formSchema = {
  name: {
    type: "string",
    required: true,
  },
  deviceType: {
    type: "object",
    required: true,
  },
  site: {
    type: "string",
    required: true,
  },
  lineupType: {
    type: "string",
    required: true,
  },
  panelType: {
    type: "string",
    required: true,
  },
  compartmentType: {
    type: "string",
    required: true,
  },
  phase: {
    type: "string",
    // required: true,
  },
  protocol: {
    type: "string",
    required: true,
  },
  serial: {
    type: "string",
    required: true,
  },
};

const DeviceForm = ({ mode, data }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [ErrorMessage, setErrorMessage] = useState();
  const [siteLocations, setSiteLocations] = useState([]);
  const [panel, setPanel] = useState([]);
  const [lineup, setLineup] = useState([]);
  const [compartment, setCompartment] = useState([]);
  const [deviceTypes, setDeviceTypes] = useState([]);
  const [error, setError] = useState({});
  const [state, setState] = useState({
    name: "",
    deviceType: "",
    site: "",
    serial: "",
    apiKey: "",
    lineupType: "",
    panelType: "",
    compartmentType: "",
    devices: [],
    subscriptionTopic: "",
    phase: "",
    protocol: "",
  });
  console.log(state);
  const {
    name,
    serial,
    apiKey,
    site,
    panelType,
    lineupType,
    compartmentType,
    subscriptionTopic,
    phase,
    protocol,
  } = state;

  const onInputChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const createDevice = async (values) => {
    setIsLoading(true);
    try {
      await axios.post(endpoint.get_devices, values, { withCredentials: true });
      setIsLoading(false);
      setSuccessMessage("New device created successfully");
      setTimeout(() => {
        setSuccessMessage("");
        navigate(-1);
      }, 2000);
    } catch (error) {
      if (error.response) {
        setErrorMessage(
          error.response.data?.message || "Something went wrong!"
        );
        setTimeout(() => {
          // setErrorMessage();
        }, 2000);
      }
    }
    setIsLoading(false);
  };

  const updateDevice = async (values) => {
    try {
      await axios.put(endpoint.get_devices + "/" + data?._id, values, {
        withCredentials: true,
      });
      setIsLoading(false);
      setSuccessMessage("Device updated successfully");
      setTimeout(() => {
        setSuccessMessage("");
        navigate(-1);
      }, 2000);
    } catch (error) {
      if (error.response) {
        setIsLoading(false);
        setErrorMessage(
          error.response.data?.message || "Something went wrong!"
        );
        setTimeout(() => {
          // setErrorMessage();
        }, 2000);
      }
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let { errors } = validateFields(state, formSchema);

    if (protocol === "mqtt" && !subscriptionTopic) {
      errors = { ...errors };
      errors.subscriptionTopic = "This is a mandatory field!";
      delete errors.apiKey;
    } else if (protocol === "api" && !apiKey) {
      errors = { ...errors };
      errors.apiKey = "This is a mandatory field!";
      delete errors.subscriptionTopic;
    }
    console.log(errors);
    if (errors) {
      return setError(errors);
    }
    setError({});
    console.log("no err");

    const body = {
      name,
      serial,
      apiKey,
      site,
      deviceType: state.deviceType?.value,
      panelType,
      lineupType,
      compartmentType,
      subscriptionTopic,
      phase,
      protocol,
    };

    Object.assign(body, {
      serial: state.serial,
      apiKey: state.apiKey,
    });

    if (mode === "create") {
      createDevice(body);
    } else if (mode === "edit") {
      updateDevice(body);
    }
  };

  //htmlFor site location dropdown
  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setSiteLocations(data);
      }
    } catch (error) {}
  };
  const getLineup = async () => {
    try {
      const response = await axios.get(`/lineup-type`, {
        withCredentials: true,
      });
      if (response) {
        setLineup(response.data);
      }
    } catch (error) {}
  };
  const getPanel = async () => {
    try {
      const response = await axios.get(`/panel-type`, {
        withCredentials: true,
      });
      if (response) {
        setPanel(response.data);
      }
    } catch (error) {}
  };
  const getCompartment = async () => {
    try {
      const response = await axios.get(`/compartment-type`, {
        withCredentials: true,
      });
      if (response) {
        setCompartment(response.data);
      }
    } catch (error) {}
  };

  const handleChangeDeviceType = (_value, option) => {
    setState({ ...state, deviceType: option });
  };

  useEffect(() => {
    getSiteLocations();
    getLineup();
    getCompartment();
    getPanel();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode !== "create") {
      setState({ ...state, site: siteLocations[0]?._id });
    }
    // eslint-disable-next-line
  }, [siteLocations]);

  //htmlFor device type dropdown
  const getDeviceTypes = async () => {
    const response = await axios.get(`/device-type`, { withCredentials: true });
    if (response) {
      setDeviceTypes(
        response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      );
    }
  };

  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Add New Sensor";
    getDeviceTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode === "edit" && data) {
      setState({
        name: data.name,
        deviceType: {
          label: data.deviceType?.name,
          value: data.deviceType?._id,
        },
        lineupType: data.lineupType?._id,
        panelType: data.panelType?._id,
        subscriptionTopic: data.subscriptionTopic,
        compartmentType: data.compartmentType?._id,
        site: data.site?._id,
        serial: data.serial,
        apiKey: data.apiKey,
        phase: data.phase,
        protocol: data.protocol,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, data]);

  return (
    <Spin spinning={isLoading}>
      <div className="card p-3 m-3">
        <div>
          <label className="az-content-label">Manage Sensors</label>
          <span className="d-block py-2" style={{ fontSize: "1rem" }}>
            {mode === "edit" ? "Update Sensor" : "Add New Sensor"}
          </span>
          {/* <span className="d-block py-2">
          </span> */}
        </div>
        <hr className="mt-0 mb-4" />

        {SuccessMessage && (
          <div className="alert alert-success" role="alert">
            {SuccessMessage}
          </div>
        )}
        {ErrorMessage && (
          <div className="alert alert-danger" role="alert">
            {ErrorMessage}
          </div>
        )}
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label htmlFor="name" className="form-label">
                Sensor Name
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={onInputChange}
                className="form-control"
                id="name"
                placeholder="Enter Sensor Name"
                // required
              />
              {error.name && <div className="text-danger">{error.name}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="serial" className="form-label">
                Serial No
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>
              <input
                type="text"
                name="serial"
                value={serial}
                onChange={onInputChange}
                className="form-control"
                id="serial"
                placeholder="Enter sensor serial"
                // required
              />
              {error.serial && (
                <div className="text-danger">{error.serial}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="deviceType" className="form-label">
                Sensor Type
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>

              <Select
                suffixIcon={
                  <img
                    src={`${appConfig.baseRoute}/img/arrow.svg`}
                    style={{ width: "12px", marginRight: "2px" }}
                  />
                }
                style={{ width: "100%", color: "red" }}
                size="large"
                id="admin"
                name="admin"
                value={state?.deviceType || null}
                onChange={handleChangeDeviceType}
                placeholder="Select Sensor Type"
                options={[
                  ...deviceTypes.map((item) => ({
                    label: item.name,
                    value: item._id,
                  })),
                ]}
                required
              />
              {error.deviceType && (
                <div className="text-danger">{error.deviceType}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="site" className="form-label">
                Switchgear Type
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>
              <select
                className="form-select"
                id="lineupType"
                name="lineupType"
                value={state.lineupType}
                onChange={onInputChange}
                aria-label="Select Switchgear"
                // required
              >
                <option value="" disabled selected>
                  Select Switchgear
                </option>
                {lineup?.length > 0 &&
                  lineup.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
              {error.lineupType && (
                <div className="text-danger">{error.lineupType}</div>
              )}
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="deviceType" className="form-label">
                Panel Type
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>

              <Select
                suffixIcon={
                  <img
                    src={`${appConfig.baseRoute}/img/arrow.svg`}
                    style={{ width: "12px", marginRight: "2px" }}
                  />
                }
                // mode="multiple"
                style={{ width: "100%" }}
                size="large"
                name="panelType"
                value={state.panelType || null}
                onChange={(value) => setState({ ...state, panelType: value })}
                placeholder="Enter Panel Types"
                options={[
                  ...panel.map((item) => ({
                    label: item.name,
                    value: item._id,
                  })),
                ]}
              />
              {error.panelType && (
                <div className="text-danger">{error.panelType}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="site" className="form-label">
                Compartment Type
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>
              <Select
                suffixIcon={
                  <img
                    src={`${appConfig.baseRoute}/img/arrow.svg`}
                    style={{ width: "12px", marginRight: "2px" }}
                  />
                }
                style={{ width: "100%" }}
                size="large"
                id="compartment"
                name="comaprtmentType"
                // mode="multiple"
                value={state.compartmentType || null}
                onChange={(value) =>
                  setState({ ...state, compartmentType: value })
                }
                placeholder="Enter Compartment Types"
                options={[
                  ...compartment.map((item) => ({
                    label: item.name,
                    value: item._id,
                  })),
                ]}
              />
              {error.compartmentType && (
                <div className="text-danger">{error.compartmentType}</div>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="deviceType" className="form-label">
                Phase
                {/* <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span> */}
              </label>

              <Select
                suffixIcon={
                  <img
                    src={`${appConfig.baseRoute}/img/arrow.svg`}
                    style={{ width: "12px", marginRight: "2px" }}
                  />
                }
                style={{ width: "100%" }}
                size="large"
                name="phase"
                value={state.phase || ""}
                onChange={(value) => setState({ ...state, phase: value })}
              >
                <option value="" disabled selected>
                  Select Type
                </option>
                <option value="red">Red</option>
                <option value="yellow">Yellow</option>
                <option value="blue">Blue</option>
              </Select>
              {error.phase && <div className="text-danger">{error.phase}</div>}
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="site" className="form-label">
                Assigned Substation
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>
              <select
                className="form-select"
                id="site"
                name="site"
                value={site}
                onChange={onInputChange}
                aria-label="Select a site location"
                // required
              >
                <option value="" disabled selected>
                  Select Substation
                </option>
                {siteLocations?.length > 0 &&
                  siteLocations.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
              {error.site && <div className="text-danger">{error.site}</div>}
            </div>

            <div className="col-md-6 mb-3">
              <label htmlFor="site" className="form-label">
                Identifier Protocol
                <span
                  className="text-danger"
                  style={{ fontFamily: "monospace", marginLeft: 3 }}
                >
                  *
                </span>
              </label>
              <select
                className="form-select"
                name="protocol"
                value={state.protocol || ""}
                onChange={onInputChange}
                // required
              >
                <option value="" disabled selected>
                  Select Type
                </option>
                <option value="api">API</option>
                <option value="mqtt">MQTT</option>
              </select>
              {error.protocol && (
                <div className="text-danger">{error.protocol}</div>
              )}
            </div>
            {state.protocol === "mqtt" ? (
              <div className="col-md-6 mb-3">
                <label htmlFor="apiKey" className="form-label">
                  Subscription Topic
                  <span
                    className="text-danger"
                    style={{ fontFamily: "monospace", marginLeft: 3 }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="subscriptionTopic"
                  value={subscriptionTopic}
                  onChange={onInputChange}
                  className="form-control"
                  id="subscriptionTopic"
                  placeholder="Enter Subscription Topic"
                  // required
                />
                {error.subscriptionTopic && (
                  <div className="text-danger">{error.subscriptionTopic}</div>
                )}
              </div>
            ) : state.protocol === "api" ? (
              <div className="col-md-6 mb-3">
                <label htmlFor="apiKey" className="form-label">
                  Sensor API Key
                  <span
                    className="text-danger"
                    style={{ fontFamily: "monospace", marginLeft: 3 }}
                  >
                    *
                  </span>
                </label>
                <input
                  type="text"
                  name="apiKey"
                  value={apiKey}
                  onChange={onInputChange}
                  className="form-control"
                  id="apiKey"
                  placeholder="Enter sensor API key"
                />
                {error.apiKey && (
                  <div className="text-danger">{error.apiKey}</div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="float-end">
            <button type="submit" className="btn btn-success me-2">
              {mode === "edit" ? "Update Sensor" : "Create Sensor"}
            </button>
            <button onClick={() => navigate(-1)} className="btn btn-secondary">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Spin>
  );
};

export default DeviceForm;
