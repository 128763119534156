import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import TypesTable from "./TypesTable";
import { api } from "../../api";
import { notification, Select } from "antd";
import appConfig from "../../appConfig";

export const PanelTypes = () => {
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [typeData, setTypeData] = useState({
    name: "",
    code: "",
    locationType: "",
  });
  const { name, code, locationType } = typeData;
  const onInputChange = (e) => {
    setTypeData({ ...typeData, [e.target.name]: e.target.value });
  };
  console.log(typeData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`/panel-type`, typeData, {
        withCredentials: true,
      });
      if (response) {
        setTypeData({ name: "" });
        getPanelTypes();
        setSuccessMessage("Panel Type Created Successfully");
        setTimeout(() => {
          setSuccessMessage();
        }, 2000);
      }
    } catch (error) {
      notification.warning({
        placement: "bottomLeft",
        message: error?.response?.data?.msg || "Something went wrong.",
      });
    }
    setIsLoading(false);
  };
  const getPanelTypes = async () => {
    const response = await api.get(`/panel-type`, { withCredentials: true });
    if (response) {
      setTypes(response);
    }
  };
  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Manage Panel Types";
    getPanelTypes();
  }, []);

  return (
    <div className="card p-3 m-3">
      <label className="az-content-label mt-1">Manage Sensors</label>
      <div className="row gx-5">
        <div className="col-md-6">
          <span className="d-block py-2">Add Panel Type</span>
          <hr className="mt-0" />
          <div className="d-flex justify-content-center">
            {isLoading && <Spinner animation="border" variant="dark" />}
          </div>
          {SuccessMessage && (
            <div className="alert alert-success" role="alert">
              {SuccessMessage}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Panel Type Name
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={onInputChange}
                className="form-control"
                id="name"
                placeholder="Enter A Panel Type Name"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="code" className="form-label">
                Code
              </label>
              <input
                type="text"
                name="code"
                value={code}
                onChange={onInputChange}
                className="form-control"
                id="code"
                placeholder="Enter code"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="locationType" className="form-label">
                Location Type
              </label>
              <Select
                suffixIcon={
                  <img
                    src={`${appConfig.baseRoute}/img/arrow.svg`}
                    style={{ width: "12px", marginRight: "2px" }}
                  />
                }
                style={{ width: "100%" }}
                size="large"
                name="locationType"
                value={locationType || ""}
                onChange={(value) =>
                  setTypeData({ ...typeData, locationType: value })
                }
              >
                <option value="" disabled selected>
                  Select Location Type
                </option>
                <option value="left">Left</option>
                <option value="right">Right</option>
              </Select>
            </div>
            <div className="float-end">
              <button type="submit" className="btn btn-success me-2">
                Create Panel Type
              </button>
              <Link to="/sensor" className="btn btn-secondary">
                Cancel
              </Link>
            </div>
          </form>
        </div>
        <div className="col-md-6">
          <TypesTable
            data={types}
            getTypesList={getPanelTypes}
            title="Panel"
            apiEndPoint="panel-type"
          />
        </div>
      </div>
    </div>
  );
};
