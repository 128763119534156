import React, { useEffect, useState } from "react";
import axios from "axios";
import { endpoint } from "../../api";
import DashboardLayoutHome from "./DashboardLayoutHome";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDashboard } from "../../redux/slices/user.slice";
import {
  removeCustomSelector,
  setCurrentDashboard,
} from "../../redux/slices/dashboard.slice";
import { CustomSelector } from "./CustomSelector";
import { TimePeriodFilter } from "./TimePeriodFilter";
import { BiX } from "react-icons/bi";

const DashboardOne = ({ id }) => {
  const dispatch = useDispatch();
  const {
    currentSiteSelectedTiles = [],
  } = useSelector((state) => state.dashboard);
  const [isLoading, setIsLoading] = useState(false);
  const [layouts, setLayouts] = useState([]);
  const [selectedTiles, setSelectedTiles] = useState([]);
  const dashboard = useSelector((state) => state.dashboard?.currentSite);

  useEffect(() => {
    document.title = "TNB Switchgear 2.0";
  }, []);

  const getDashboard = async () => {
    if (!isLoading) {
      try {
        setIsLoading(true);
        const response = await axios.get(endpoint.dashboard + "/" + id, {
          withCredentials: true,
        });
        if (response.status === 200) {
          dispatch(setCurrentDashboard(response.data.result));
          // setSelectedTiles(currentSiteSelectedTiles)
          setLayouts(response.data.result.layouts);
          // setSelectedTiles([]);
        } else {
          dispatch(setActiveDashboard(""));
        }
      } catch (error) {
        dispatch(setActiveDashboard(""));
      }
      setIsLoading(false);
    }
  };

  const handleSelect = (selectId) => {
    const currentIds = [...selectedTiles];
    const idx = currentIds.findIndex((id) => id === selectId);
    if (idx >= 0) {
      currentIds.splice(idx, 1);
      setSelectedTiles(currentIds);
    } else {
      setSelectedTiles([...currentIds, selectId]);
    }
  };

  const handleClearCustomSelector = () => {
    dispatch(removeCustomSelector(id));
    setSelectedTiles([]);
    getDashboard();
  };

  useEffect(() => {
    if (id) getDashboard();
  }, [id]);

  useEffect(() => {
    setSelectedTiles(currentSiteSelectedTiles);
  }, [currentSiteSelectedTiles]);

  return (
    <div>
      <div className="az-content-header d-block d-md-flex">
        <div className="d-flex">
          <CustomSelector
            selectedTiles={selectedTiles}
            getDashboard={getDashboard}
            handleSelect={handleSelect}
            setSelectedTiles={setSelectedTiles}
          />
          {currentSiteSelectedTiles.length > 0 && (
            <div
              className="ms-2"
              style={{
                background: "red",
                fontSize: "19px",
                color: "#fff",
                padding: "0px 5px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={handleClearCustomSelector}
            >
              <BiX style={{ marginBottom: "4px" }} />
            </div>
          )}
        </div>
        <div>
          <TimePeriodFilter />
        </div>
      </div>
      <div className="az-content-body">
        <div className="row row-sm mg-b-15 mg-sm-b-20">
          {dashboard?.items?.length > 0 ? (
            <DashboardLayoutHome
              allData={dashboard.items}
              layouts={layouts}
              selectedTiles={selectedTiles}
              handleSelect={handleSelect}
              mode={"view"}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          ) : dashboard.iframe ? (
            <iframe
              title="Dashboard"
              id="frame"
              scrolling="y"
              onLoad={(e, err) => {
                e.target.width = "100%";
                e.target.height = window.screen.height;
              }}
              frameBorder="0"
              src={dashboard.iframe}
              style={{ maxHeight: 800, width: "100%" }}
            >
              Iframe does not supported
            </iframe>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardOne;
