import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getFormatedUserType } from '../../utils/userTypeFomater';

export default function WelcomeSection() {
    const userDetails = useSelector(state => state.user?.userDetails);
    const [today, setDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <div className="az-content-header d-block d-md-flex">
            <div>
                <h2 className="az-content-title mg-b-5 mg-b-lg-8">Welcome {userDetails?.name || 'N/A'}!</h2>
            </div>

            <div className="az-dashboard-header-right">
                <div>
                    <label className="tx-13">{moment(today).format("hh:mm A")} | {moment(today).format("DD MMMM yyyy")}  | {getFormatedUserType(userDetails?.role)} Account</label>
                </div>
            </div>
        </div>
    )
}
