import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import EditDTModel from "../../components/Modals/EditDTModel";
import { FiTrash, FiEdit } from "react-icons/fi";

const DeviceTypeTable = ({ data, getDeviceTypes }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deviceTypeDataToEdit, setDeviceTypeDataToEdit] = useState();
  const columns = [
    {
      name: "No.",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
    },
    {
      name: "Name",
      // selector: (row) => row.name,
      selector: (row) => <p style={{ maxWidth: "10vw", marginBottom: "0px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >{row?.name}</p>,
    },
    {
      name: "Code",
      selector: (row) => <p style={{ maxWidth: "10vw", marginBottom: "0px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >{row?.code}</p>,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button className="btn btn-info me-1" onClick={() => editType(row)}>
            <FiEdit />
          </button>
          <button
            className="btn btn-danger"
            onClick={() => deleteType(row._id)}
          >
            <FiTrash />
          </button>
        </div>
      ),
      right: "yes",
    },
  ];
  const editType = (data) => {
    setDeviceTypeDataToEdit(data);
    setModalShow(true);
  };
  const deleteType = async (typeId) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(`/device-type/` + typeId, {
        withCredentials: true,
      });
      if (response) {
        setSuccessMessage("Device Type deleted successfully.");
        setErrorMessage("");
        getDeviceTypes();
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setSuccessMessage("");
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };
  return (
    <div>
      <span className="d-block py-2">Sensor Type</span>
      <hr className="mt-0" />
      <div className="d-flex justify-content-center">
        {isLoading && <Spinner animation="border" variant="dark" />}
      </div>
      {SuccessMessage && (
        <div className="alert alert-success" role="alert">
          {SuccessMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 50]}
      />
      <EditDTModel
        show={modalShow}
        onHide={() => setModalShow(false)}
        deviceTypeDataToEdit={deviceTypeDataToEdit}
        setModalShow={setModalShow}
        getDeviceTypes={getDeviceTypes}
      />
    </div>
  );
};

export default DeviceTypeTable;
