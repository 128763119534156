import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import { endpoint } from "../../api";
import AddItemModal from "../LiveDashboard/AddItemModal";
import DashboardLayout from "./DashboardLayout";
import ConfirmModal from "../../components/Shared/ConfirmModel";
import { IoIosCloseCircleOutline } from "react-icons/io";

const DashboardForm = ({ mode, data }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [allData, setAllData] = useState([]);
  const [layouts, setLayouts] = useState({});
  const [name, setName] = useState("");
  const [iframe, setIframe] = useState();
  const iframeText = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  useEffect(() => {
    if (mode === "edit") {
      let oldName = data?.name || "";
      if (searchParams.get("cp") === "true") {
        oldName += " (copy)";
      }
      setLayouts(data.layouts);
      setAllData(data.items || []);
      setName(oldName);
      setIframe(data.iframe);
    }
  }, [data, searchParams]);

  console.log(typeof searchParams.get("cp"), name);

  // create Dashboard
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("create");
    // return;
    const dashboardType = new Date().getTime();
    const body = { name, dashboardType };

    if (iframe) {
      body.iframe = iframe;
    } else {
      body.layouts = layouts;
      body.items = allData;
    }

    try {
      const { status } = await axios.post(endpoint.create_dashboard, body, {
        withCredentials: true,
      });
      if (status === 201) {
        Swal.fire(
          "",
          `Dashboard ${
            searchParams.get("cp") === "true" ? "Duplicated" : "Created"
          } Successfully!`,
          "success"
        );
        navigate("/dashboard-list");
      }
    } catch (error) {
      console.lot(error);
    }
  };

  // update dashboard
  const handleUpdate = async (e) => {
    e.preventDefault();
    console.log("updated");
    const body = { name };

    if (iframe) {
      body.iframe = iframe;
    } else {
      body.layouts = layouts;
      body.items = allData;
    }

    // return;
    const response = await axios.put(
      endpoint.update_dashboard + "/" + data._id,
      body,
      { withCredentials: true }
    );
    console.log(response.data);
    if (response.status === 204) {
      Swal.fire("", "Dashboard Updated Successfully!", "success");
      navigate("/dashboard-list");
    }
  };

  return (
    <div className="px-3 mt-3">
      <div className="border bg-white p-3">
        {/* <h5 className="fw-bold">Add New Dashboard</h5> */}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">Manage Dashboard</label>
            <span className="d-block py-2">Add New Dashboard</span>
          </div>
          {/* <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/substation/create">Add Site Location</Link>
          </div> */}
        </div>
        <hr className="mt-0" />
        <Form
          onSubmit={
            mode === "edit" && searchParams.get("cp") !== "true"
              ? handleUpdate
              : handleSubmit
          }
        >
          <Row>
            <Col md={6}>
              <Form.Group className="mt-3">
                <Form.Label>Dashboard Name</Form.Label>
                <Form.Control
                  required
                  // className="text-secondary"
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Enter Dashboard Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <div className="my-3 d-flex align-items-center gap-2">
                <button
                  onClick={handleShow}
                  className="btn btn-success "
                  disabled={iframe}
                >
                  Add Item
                </button>{" "}
                - or -{" "}
                <ConfirmModal
                  title="Iframe"
                  description={
                    <Form.Group className="mt-3 mb-3">
                      <Form.Control
                        required
                        className="text-secondary"
                        type="text"
                        name="name"
                        placeholder="Enter iframe link"
                        ref={iframeText}
                      />
                    </Form.Group>
                  }
                  onConfirm={() => {
                    if (iframeText.current?.value?.length > 0) {
                      setIframe(iframeText.current?.value);
                    }
                  }}
                >
                  <button
                    disabled={allData?.length > 0}
                    className="btn btn-success "
                  >
                    Add Iframe
                  </button>
                </ConfirmModal>
              </div>
              {iframe && (
                <div className="bg-light p-2 my-2 d-flex justify-content-between align-items-center">
                  {iframe}
                  <IoIosCloseCircleOutline
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIframe();
                      iframeText.current.value = "";
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
          {allData?.length ? (
            <div className="bg-layout">
              <DashboardLayout
                allData={allData}
                setAllData={setAllData}
                layouts={layouts}
                setLayouts={setLayouts}
                mode="create"
              />
            </div>
          ) : iframe ? (
            <iframe
              title="Dashboard"
              src={iframe}
              style={{ width: "100%", height: "60vh" }}
            >
              Iframe does not supported
            </iframe>
          ) : (
            ""
          )}

          <div className="d-flex justify-content-end mt-4">
            <Button
              disabled={allData?.length === 0 && !iframe}
              type="submit"
              className="me-2"
              variant="success"
            >
              {mode === "edit"
                ? searchParams.get("cp") === "true"
                  ? "Save"
                  : "Update Dashboard"
                : "Create Dashboard"}
            </Button>
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>

      {/* item added with modal */}
      <AddItemModal
        show={show}
        setShow={setShow}
        allData={allData}
        setAllData={setAllData}
        mode={"create"}
      />
    </div>
  );
};

export default DashboardForm;
