import axios from "axios";
import React, { useEffect, useState } from "react";
import NivoLine from "../../components/Charts/NivoLine";
import moment from "moment";
import SplineChartSubstation from "../../components/Charts/SplineChartSubstation";
import SplineChart from "../../components/Charts/SplineChart";

export const PanelOverViewGraph = ({
  title,
  sysParameter,
  minuteAgo,
  xAxis,
  startDate,
  endDate,
  selectedParameterData,
  substationOverview,
  panelType,
  lineupType,
  site,
}) => {
  const [deviceData, setDeviceData] = useState([]);

  const getGraphData = async () => {
    try {
      if (
        !((site && panelType && lineupType) || selectedParameterData?.length)
      ) {
        return;
      }
      let devices = [];
      if (selectedParameterData?.length) {
        selectedParameterData.forEach((element) => {
          const data = JSON.parse(element);
          devices.push({
            device: data?.device?._id,
            parameters: [data?.name],
          });
        });
      }

      const res = await axios.get("/dashboard/panel-overview-graph", {
        withCredentials: true,
        params: {
          sysParameter,
          startDate: startDate
            ? startDate.toDate()
            : moment().startOf("day").toDate(),
          endDate: endDate ? endDate.toDate() : moment().endOf("day").toDate(),
          minuteAgo,
          xAxis,
          devices: devices?.length ? JSON.stringify(devices) : null,
          panelType,
          lineupType,
          site,
        },
      });
      const data = res?.data?.results;
      // ?.map((d) => ({
      //   id: d.name,
      //   data: d.data,
      // }));
      setDeviceData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (substationOverview && !selectedParameterData?.length) {
      return;
    } else getGraphData();

    const intervalId = setInterval(() => {
      if (substationOverview && !selectedParameterData?.length) {
        return;
      } else getGraphData();
    }, 600000);
    return () => clearInterval(intervalId);
  }, [
    startDate,
    endDate,
    minuteAgo,
    xAxis,
    selectedParameterData,
    site,
    panelType,
    lineupType,
  ]);

  return (
    <div className="mb-5">
      <div className="mb-3">
        <label style={{ fontWeight: "500", marginBottom: "10px" }}>
          {title}
        </label>
        <div style={{ height: "450px" }}>
          {deviceData?.length > 0 && (
            <SplineChart data={deviceData} showAlarmedDatapoints={true} />
            // <NivoLine
            //   data={deviceData}
            //   // fromPanel={true}
            //   // from={graphFrom}
            //   // to={graphTo}
            //   // isLoading={isLoadingGraph}
            //   xAxis={xAxis}
            // />
          )}
        </div>
      </div>
    </div>
  );
};
