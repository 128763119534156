export const groupByLineup = (data = []) => {
  const groupedData = {};

  // Iterate through the original data array
  data.forEach((item) => {
    const lineupTypeId = item?.notification?.lineupType?.name;
    if (lineupTypeId) {
      if (!groupedData[lineupTypeId]) {
        groupedData[lineupTypeId] = [];
      }
      groupedData[lineupTypeId].push(item);
    }
  });

  // Now groupedData will contain the data grouped by lineupType _id
  return groupedData;
};

export const groupByPanel = (data = []) => {
  const groupedData = {};
  data.forEach((item) => {
    const panelType = item?.notification?.panelType?.name;
    if (panelType) {
      if (!groupedData[panelType]) {
        groupedData[panelType] = [];
      }
      groupedData[panelType].push(item);
    }
  });
  return groupedData;
};

export const groupByCompartment = (data) => {
  const groupedData = {};
  const panels = groupByPanel(data);
  data.forEach((item) => {
    const compartmentType = item?.notification?.compartmentType?.code;
    if (compartmentType) {
      if (!groupedData[compartmentType]) {
        groupedData[compartmentType] = [];
      }
      groupedData[compartmentType].push(item);
    }
  });
  const finalData = [];
  Object.keys(groupedData).forEach((com) => {
    const compart = {
      [com]: {},
    };
    Object.keys(panels).forEach((pn) => {
      compart[com] = {
        [pn]: 0,
      };
    });
    data.forEach((dt) => {
      const { notification } = dt;
      if (notification?.compartmentType?.code === com) {
        compart[com][notification?.panelType?.name] =
          compart[com][notification?.panelType?.name] + 1;
      }
    });
    finalData.push(compart);
  });
  return finalData;
};
