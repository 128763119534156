import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { FiTrash, FiEye, FiEdit, FiPaperclip } from "react-icons/fi";
import { Spin } from "antd";
import { DataTable } from "../../components";

const SiteLocationsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [limit, setLimit] = useState(Number(searchParams.get("limit")) || 10);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [total, setTotal] = useState(0);
  const [siteLocations, setSiteLocations] = useState([]);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getSiteLocations = async (userDetails) => {
    try {
      setIsLoading(true);
      let setData = (data) => {
        // data = [...data, ...data, ...data];
        setSiteLocations(
          data
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((item, idx) => {
              item.sl = idx + 1;
              return item;
            })
        );
        setTotal(data.length || 0);
      };
      if (userDetails.role === "superAdmin") {
        const response = await axios.get(`/site-location`, {
          withCredentials: true,
        });
        if (response) {
          setData(response.data || []);
        }
      }
      if (userDetails.role === "admin") {
        const response = await axios.get(
          `/site-location/admin-sites/` + userDetails._id,
          { withCredentials: true }
        );
        if (response) {
          setData(response.data || []);
        }
      }
      if (userDetails.role === "installer") {
        const response = await axios.get(
          `/site-location/installer-sites/` + userDetails._id,
          { withCredentials: true }
        );
        if (response) {
          setData(response.data || []);
        }
      }
    } catch (error) {}
    setIsLoading(false);
  };
  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - All Substations";
    getSiteLocations(userDetails);
  }, [userDetails]);

  useEffect(() => {
    let queryLimit = Number(searchParams.get("limit"));
    let queryPage = Number(searchParams.get("page"));
    setPage(queryPage || 1);
    setLimit(queryLimit || 10);
  }, [searchParams]);

  const columns = [
    {
      title: "No",
      dataIndex: "sl",
      width: "60px",
    },
    {
      title: "Name",
      render: (_, row) => row.name,
    },
    {
      title: "Building Name",
      render: (_, row) => row.buildingName,
    },
    {
      title: "Contact Person",
      render: (_, row) => row.contactPersonName,
    },
    {
      title: "Phone",
      render: (row) => <>+6{row.contactPersonPhone}</>,
      // width: "140px",
    },
    {
      title: "Date Created",
      render: (_, row) => moment(row.createdAt).format("DD/MM/YYYY"),
      align: "center",
    },
    {
      title: "Action",
      align: "center",
      render: (row) => (
        <div>
          {(() => {
            switch (userDetails.role) {
              case "superAdmin":
                return (
                  <div className="actions">
                    <Link
                      to={`/substation/details/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View" />
                    </Link>
                    <Link
                      to={`/substation/edit/` + row._id}
                      className="btn btn-info me-1"
                    >
                      <FiEdit title="Edit" />
                    </Link>
                    {/* <Link
                      to={`/substation/document/` + row._id}
                      className="btn btn-warning me-1"
                    >
                      <FiPaperclip title="Documents" />
                    </Link> */}
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteSiteLocation(row._id)}
                    >
                      <FiTrash title="Delete" />
                    </button>
                  </div>
                );
              case "installer":
                return (
                  <div className="actions">
                    <Link
                      to={`/site-location/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View" />
                    </Link>
                  </div>
                );
              case "admin":
                return (
                  <div className="actions">
                    <Link
                      to={`/substation/details/` + row._id}
                      className="btn btn-success me-1"
                    >
                      <FiEye title="View" />
                    </Link>
                    <Link
                      to={`/substation/edit/` + row._id}
                      className="btn btn-info me-1"
                    >
                      <FiEdit title="Edit" />
                    </Link>
                    {/* <Link
                      to={`/site-document/` + row._id}
                      className="btn btn-warning me-1"
                    >
                      <FiPaperclip title="Documents" />
                    </Link> */}
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteSiteLocation(row._id)}
                    >
                      <FiTrash title="Delete" />
                    </button>
                  </div>
                );
              case "user":
                return <div className="actions"></div>;
              case "public":
                return <div className="actions"></div>;
              default:
                return;
            }
          })()}
        </div>
      ),
      // grow: 2,
      // width: "240px",
      // center: "yes",
    },
  ];
  const deleteSiteLocation = async (siteLocationId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this site location?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/site-location/` + siteLocationId, {
            withCredentials: true,
          })
          .then((res) => {
            console.log({ res });
            getSiteLocations(userDetails);
            Swal.fire({
              title: "Done!",
              text: res?.data?.message || "Site location Successfully Deleted",
              icon: "success",
              timer: 2000,
              button: false,
            });
          })
          .catch((error) => {
            console.log({ error: error.response.data.message });
            Swal.fire({
              title: "",
              text: error?.response?.data?.message || "Error happened",
              icon: "error",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  return (
    <div className="card p-3 m-3">
      {/* <h3>All Substations</h3> */}
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <label className="az-content-label">MANAGE SUBSTATIONS</label>
          <span className="d-block py-2">All Substations</span>
        </div>
        <div className="col-md-4 d-flex justify-content-end p-0">
          <Link
            className="btn btn-az-primary btn-md"
            to="/substation/create"
          >
            Add New Substation
          </Link>
        </div>
      </div>
      <hr className="mt-0" />

      <Spin spinning={isLoading}>
        <DataTable
          columns={columns}
          dataSource={siteLocations}
          pagination={{
            defaultPageSize: limit,
            current: page,
            total,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSizeOptions: [10, 20, 50],
            responsive: true,
          }}
          onChange={(pagination) => {
            navigate(
              `/substation?page=${pagination.current}&limit=${pagination.pageSize}`
            );
          }}
        />
      </Spin>
    </div>
  );
};

export default SiteLocationsList;
