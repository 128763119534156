import React, { useEffect, useState } from 'react';
import { Form,  } from 'react-bootstrap';
import { ImBin2 } from 'react-icons/im';
import { api, endpoint } from '../../api';

const MultipleLocation = ({ ind, location, component, siteLocations, multipleLocation, setMultipleLocation }) => {
    const [deviceList, setDeviceList] = useState([])
    const [dParameters, setDParameters] = useState([])
    const [isDeviceLoading, setIsDeviceLoading] = useState(false)
    const [dParameterLoading, setDParameterLoading] = useState(false)


    // get device
    const getDevice = async (site) => {
        setIsDeviceLoading(true)
        try {
            setDeviceList([])
            const { results } = await api.get(endpoint.get_devices, {
                params: {
                    site,
                    limit: 1000,
                    page: 1,
                },
            });


            if (results) {
                setDeviceList(results);
                setIsDeviceLoading(false)
            }
        } catch (error) {
            setIsDeviceLoading(false)
        }
    };


    // get device parameter
    const getDeviceParameters = async (deviceId) => {
        setDParameterLoading(true);
        try {
            setDParameters([]);
            const { results } = await api.get(endpoint.get_device_parameter, {
                params: {
                    deviceId,
                    limit: 1000,
                    page: 1,
                    sortBy: "name",
                    orderBy: "ASC",
                    type: "formula",
                },
            });
            setDParameters(results);
        } catch (error) { }

        setDParameterLoading(false);
    };


    useEffect(() => {
        if (location.location) {
            getDevice(location.location)
        } else {
            setDeviceList([])
        }
    }, [location.location])

    useEffect(() => {
        if (location.device) {
            getDeviceParameters(location.device)
        } else {
            setDParameters([])
        }
    }, [location.device])


    // change handler
    const locationChangeHandler = (e) => {
        const find = multipleLocation.map((data, index) => {
            if (ind === index) {
                data = {
                    ...data,
                    [e.target.name]: e.target.value
                }
                if (e.target.name === 'location') {
                    data = {
                        ...data,
                        device: '',
                        yAxis: ''
                    }
                }
                if (e.target.name === 'device') {
                    data = {
                        ...data,
                        yAxis: ''
                    }
                }
            }

            return data
        })
        setMultipleLocation(find)
    }


    return (
        <div>
            {/* locations */}
            <Form.Group className="mb-2">
                <Form.Label className="fw-bold d-flex align-items-center justify-content-between">
                    <div>
                        Location {multipleLocation.length > 1 ? ind + 1 : ''}
                    </div>
                    <div
                        className={`ms-auto mt-1 text-danger fs-5 ${multipleLocation.length <= 1 ? 'd-none' : ''}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            const restItems = multipleLocation.filter((item, index) => index !== ind)
                            setMultipleLocation(restItems)
                        }}
                    > <ImBin2 />
                    </div>
                </Form.Label>
                <Form.Select
                    required
                    className="text-secondary"
                    name="location"
                    value={location?.location}
                    // onChange={e => setItemData({ ...itemData, location: [e.target.value] })}
                    onChange={locationChangeHandler}
                >
                    <option value="">Select Location</option>
                    {siteLocations &&
                        siteLocations.map((item) => (
                            <option key={item._id} value={item._id}>
                                {item.name}
                            </option>
                        ))}
                </Form.Select>
            </Form.Group>

            {/* device */}
            <Form.Group className="mb-2">
                <Form.Label className="fw-bold">Select Device {multipleLocation.length > 1 ? ind + 1 : ''}</Form.Label>
                <Form.Select
                    required
                    className="text-secondary"
                    name="device"
                    value={location.device}
                    onChange={locationChangeHandler}
                >
                    <option value="">{isDeviceLoading ? 'Loading...' : "Select Device"}</option>
                    {deviceList.length > 0 &&
                        deviceList?.map((item) => (
                            <option key={item._id} value={item._id}>
                                {item.name}
                            </option>
                        ))}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-2">
                <Form.Label className="fw-bold">Select Parameter {multipleLocation.length > 1 ? ind + 1 : ''}</Form.Label>
                <Form.Select
                    required
                    className="text-secondary"
                    name="yAxis"
                    value={location.yAxis}
                    onChange={locationChangeHandler}
                >
                    <option value="" disabled>{dParameterLoading ? 'Loading...' : 'Select Parameter'}</option>
                    {
                        dParameters.map((parameter) => (
                            <option key={parameter._id} value={parameter.name}>{parameter.name}</option>
                        ))
                    }
                </Form.Select>
            </Form.Group>

        </div>
    );
};

export default MultipleLocation;