/**
 * @param {{[key:string]: string}} values
 * @param {{ [key:string]: { type: string, required: boolean, minLength: number, match: string} }} schema
 * @returns
 */
export const validateFields = (values, schema) => {
  let errors = {};
  for (var key of Object.keys(values)) {
    const value = values[key];
    const field = schema[key];
    // console.log({field, value})
    if (field) {
      if (field.type === "array") {
        if (Array.isArray(value) && value.length < field.minLength) {
          errors[key] = field.message || `Select at least ${field.minLength} item${field.minLength > 1 ? "s" : ""}`;
        }
      } else if (field.type !== typeof value) {
        errors = { ...errors };
        errors[key] = field.message || "Invalid type";
      } else if (field.required && !value) {
        errors = { ...errors };

        errors[key] = field.message || `This is a mandatory field!`;
      } else if (field.match && value !== values[field.match]) {
        errors[key] = field.message || `Field did not match`;
      }
    }
  }

  if (Object.keys(errors).length === 0) errors = null
  return { values, errors };
};
