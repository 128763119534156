import { notification, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api, endpoint } from "../../api";
import appConfig from "../../appConfig";
import AlarmGraph from "./AlarmGraph";
import { RangeInputs } from "./RangeInputs";

const CreateAlarm = () => {
  const navigation = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [siteLocations, setSiteLocations] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState([]);
  const [devices, setDevices] = useState([]);
  const [panelList, setPanelList] = useState([]);
  const [lineupList, setLineuplist] = useState([]);
  const [compartmentList, setCompartmentList] = useState([]);
  const [CreateAlarmData, setCreateAlarmData] = useState({
    name: "",
    type: "email",
    site: "",
    device: "",
    parameter: "",
    option: "",
    interval: "",
    panelType: "",
    compartmentType: "",
    lineupType: "",
    criticalMin: "",
    criticalMax: "",
    cautionMin: "",
    cautionMax: "",
    highMin: "",
    highMax: "",
  });

  const getPanel = async () => {
    try {
      const response = await axios.get(`/panel-type`, {
        withCredentials: true,
      });
      if (response) {
        setPanelList(response.data);
      }
    } catch (error) {}
  };
  const getLineup = async () => {
    try {
      const response = await axios.get(`/lineup-type`, {
        withCredentials: true,
      });
      if (response) {
        setLineuplist(response.data);
      }
    } catch (error) {}
  };
  const getCompartment = async () => {
    try {
      const response = await axios.get(`/compartment-type`, {
        withCredentials: true,
      });
      if (response) {
        setCompartmentList(response.data);
      }
    } catch (error) {}
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setSiteLocations(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.title = `${appConfig.name} - Create New Alarm`;
    getCompartment();
    getPanel();
    getSiteLocations();
    getLineup();
    // eslint-disable-next-line
  }, []);

  const {
    name,
    type,
    site,
    device,
    parameter,
    option,
    interval,
    panelType,
    compartmentType,
    lineupType,
    criticalMin,
    criticalMax,
    cautionMin,
    cautionMax,
    highMin,
    highMax,
  } = CreateAlarmData;

  const onInputChange = (e) => {
    let name = e.target.name;
    let obj = { ...CreateAlarmData, [name]: e.target.value };
    if (
      name === "site" ||
      name === "lineupType" ||
      name === "compartmentType" ||
      name === "panelType"
    ) {
      obj.device = "";
      obj.parameter = "";
      setDevices([]);
      setDeviceParameters([]);
    }
    setCreateAlarmData(obj);
  };

  const getDevices = async () => {
    const response = await axios.get(`/device`, {
      withCredentials: true,
      params: { site, panelType, lineupType, compartmentType },
    });
    if (response) {
      setDevices(response.data.results);
    }
  };

  useEffect(() => {
    if (site && panelType && lineupType && compartmentType) {
      getDevices();
    }
  }, [site, panelType, lineupType, compartmentType]);

  const getDeviceParameters = async (deviceId) => {
    try {
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}
  };

  useEffect(() => {
    if (device) {
      getDeviceParameters(device);
    }
  }, [device]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // return console.log({CreateAlarmData})
    try {
      await api.post(endpoint.notification, {
        ...CreateAlarmData,
        interval: Number(CreateAlarmData.interval) || 0,
        criticalMin: Number(criticalMin) || 0,
        criticalMax: Number(criticalMax) || 0,
        cautionMin: Number(cautionMin) || 0,
        cautionMax: Number(cautionMax) || 0,
        highMin: Number(highMin) || 0,
        highMax: Number(highMax) || 0,
      });
      navigation("/alarm-summary");
      notification.info({
        message: "Notification has been created.",
        placement: "bottomLeft",
      });
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="alarm-page">
      <div className="card p-3 m-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">MANAGE NOTIFICATION</label>
            <span className="d-block py-2">Create New Alarm</span>
          </div>
        </div>
        <hr className="mt-0" />
        <Spin spinning={isLoading}>
          <form onSubmit={submitHandler}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Alarm Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={onInputChange}
                    className="form-control"
                    id="name"
                    placeholder="Enter Alarm Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="type" className="form-label">
                    Alarm Type
                  </label>
                  <select
                    name="type"
                    id="type"
                    value={type}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option value="" disabled>
                      Select alarm type
                    </option>
                    <option value="email">Email Notification Only</option>
                    {/* <option value="message">
                          System Warning Message Only
                        </option> */}
                    {/* <option value="both">
                          Email Notification + System Warning Message
                        </option> */}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="site" className="form-label">
                    Substation
                  </label>
                  <select
                    name="site"
                    id="site"
                    value={site}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option> Select substation</option>
                    {siteLocations?.length &&
                      siteLocations.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="lineupType" className="form-label">
                    Switchgear
                  </label>
                  <select
                    name="lineupType"
                    id="lineupType"
                    value={lineupType}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option>Select Switchgear</option>
                    {lineupList?.length &&
                      lineupList.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="panelType" className="form-label">
                    Panel Type
                  </label>
                  <select
                    name="panelType"
                    id="panelType"
                    value={panelType}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option>Select Panel Type</option>
                    {panelList?.length &&
                      panelList.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="compartmentType" className="form-label">
                    Compartment Type
                  </label>
                  <select
                    name="compartmentType"
                    id="compartmentType"
                    value={compartmentType}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option> Select Compartment Type</option>
                    {compartmentList?.length &&
                      compartmentList.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="device" className="form-label">
                    Sensor
                  </label>
                  <select
                    name="device"
                    id="device"
                    value={device}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option>Select sensor</option>
                    {devices?.length &&
                      devices.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="parameter" className="form-label">
                    Sensor Parameter
                  </label>
                  <select
                    name="parameter"
                    id="parameter"
                    value={parameter}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option>Select sensor parameter</option>
                    {deviceParameters?.length &&
                      deviceParameters.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="option" className="form-label">
                    Alarm Notification
                  </label>
                  <select
                    name="option"
                    id="option"
                    className="form-select"
                    defaultValue={option}
                    onChange={onInputChange}
                    value={option}
                  >
                    <option> Select alarm notification option</option>
                    <option value="min">Min</option>
                    <option value="max">Max</option>
                    <option value="range">Range</option>
                  </select>
                </div>
                <div className="row mb-3">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="interval" className="form-label">
                      Set Trigger Interval
                    </label>
                    <select
                      name="interval"
                      id="interval"
                      className="form-select"
                      value={interval}
                      onChange={onInputChange}
                    >
                      <option>Select alarm trigger interval</option>
                      <option value="1">1 min</option>
                      <option value="15">15 min</option>
                      <option value="30">30 min</option>
                      <option value="60">1 hour</option>
                      <option value="720">12 hours</option>
                      <option value="1440">24 hours</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <RangeInputs
                    option={option}
                    state={CreateAlarmData}
                    onInputChange={onInputChange}
                    warningType="critical"
                  />
                  <RangeInputs
                    option={option}
                    state={CreateAlarmData}
                    onInputChange={onInputChange}
                    warningType="caution"
                  />
                  <RangeInputs
                    option={option}
                    state={CreateAlarmData}
                    onInputChange={onInputChange}
                    warningType="high"
                  />
                </div>
              </div>
            </div>

            <AlarmGraph
              data={CreateAlarmData}
              deviceParameters={deviceParameters}
            />

            <div className="mb-3 d-flex">
              <div className="ms-auto">
                <button className="btn btn-success me-2" type="submit">
                  Create
                </button>
                <Link to="/alarm-summary" className="btn btn-secondary">
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </Spin>
      </div>
    </div>
  );
};

export default CreateAlarm;