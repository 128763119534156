import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import NivoBar from "./NivoBar";
import NivoLine from "./NivoLine";
import NivoPie from "./NivoPie";
import NivoRadial from "./NivoRadial";
import moment from "moment";

/* 

      function calculatePercentages(data) {
        for (let i = 0; i < data.length; i++) {
          const percentage = (data[i].value / totalDataNumber) * 100;
          console.log(data[i].value, percentage);
          data[i].y = Number(percentage.toFixed(2));
        }
        return data;
      }

      const pieDataPoints = calculatePercentages(pieData);
*/

const NivoChartHome = ({
  data = [],
  from: _from,
  to: _to,
  isLoading = false,
  interval: _interval = 1,
  critical,
  warning,
  type = "line",
  xAxis,
  colorSchema,
}) => {
  const [chartData, setChartData] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (type === "bar") {
      setKeys(data.map((d) => d._id));
      const convertedData = data.reduce((acc, curr) => {
        curr.chart.forEach((entry) => {
          const date = moment(entry?.x).format("hh:mm:ss DD/MM/YYYY");

          let value;

          if (typeof entry?.y === "number") {
            value = Number(entry?.y?.toFixed(2));
          } else if (typeof entry.y === "object") {
            value = [];
            entry.y.map((y) => value.push(Number(y).toFixed(2)));
          }

          // console.log(entry, value);
          if (!acc[date]) {
            acc[date] = {};
          }
          acc[date][curr._id] = value;
        });

        return acc;
      }, {});

      const formattedData = Object.keys(convertedData).map((date) => ({
        date,
        ...convertedData[date],
      }));

      setChartData(formattedData);
    } else if (type === "line") {
      const value = data?.map((item) => {
        let innerData = item.chart || item.data;
        console.log(innerData);

        return {
          id: item._id,
          data: innerData,
        };
      });

      setChartData(value);
    } else if (type === "pie") {
      const pieData = data.reduce((prev, curr) => {
        let sum = 0;
        curr.chart.forEach((item) => {
          if (typeof item.y === "number") {
            sum += item.y;
          } else if (typeof item.y === "object") {
            item.y.map((y) => (sum += y));
          }
        });
        return [
          ...prev,
          {
            id: curr._id,
            label: curr._id,
            value: Number(sum.toFixed(2)),
          },
        ];
      }, []);

      setChartData(pieData);

      // console.log({ pieData });
    } else if (type === "dial") {
      const value = data?.map((item) => ({
        id: item._id,
        data: item.chart,
      }));

      setChartData(value);
    }

    // console.log({ a });
  }, [type, data]);

  console.log("NivoChartHome ===> ", chartData);
  return (
    <>
      {type === "line" && chartData.length > 0 && (
        <NivoLine data={chartData} xAxis={xAxis} colorSchema={colorSchema} />
      )}
      {type === "bar" && chartData.length > 0 && (
        <NivoBar
          data={chartData}
          keys={keys}
          xAxis={xAxis}
          colorSchema={colorSchema}
        />
      )}
      {type === "pie" && chartData.length > 0 && (
        <NivoPie data={chartData} colorSchema={colorSchema} />
      )}
      {type === "dial" && chartData.length > 0 && (
        <NivoRadial data={chartData} />
      )}
    </>
  );
};

export default NivoChartHome;
