import { notification, Spin } from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { api, endpoint } from "../../api";

export const CreateTariffCategory = ({ getElectricityTariffList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };
  const onInputChange = (e) => {
    setName(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { message } = await api.post(endpoint.create_electricity_tariff, {
        name,
        description,
      });
      notification.success({ message, placement: "bottomLeft" });
      setName("");
      setDescription("");
      getElectricityTariffList();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    e.target.reset();
    setIsLoading(false);
  };

  return (
    <div className="col-md-4">
      <h4 className="mb-3">Add Tariff Category</h4>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Tariff Category
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onInputChange}
              className="form-control"
              id="name"
              placeholder="Enter a tariff category"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <ReactQuill
              theme="snow"
              className="mb-5"
              id="description"
              name="description"
              value={description}
              onChange={handleDescriptionChange}
              style={{ height: "200px" }}
            />
          </div>
          <div className="float-end mt-5">
            <button type="submit" className="btn btn-success me-2">
              Create
            </button>
          </div>
        </form>
      </Spin>
    </div>
  );
};
