import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function Profile() {
    const userDetails = useSelector(state => state.user?.userDetails)
    useEffect(() => {
        document.title = "TNB Switchgear 2.0 - Profile"
    }, []);
    return (
        <div className="az-content-body">
            <div className="row row-sm mg-b-15 mg-sm-b-20">
                <div className="col-lg-12">
                    <div className="card card-dashboard-six">
                        <div className="card-header">
                            <div>
                                <label className="az-content-label">Profile</label>
                                <span className="d-block py-2">Your profile Information</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row mb-2">
                                    <div className="col-4">Full Name</div>
                                    <div className="col-8">: {userDetails?.name}</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-4">Email</div>
                                    <div className="col-8">: {userDetails?.email}</div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-4">Phone Number</div>
                                    <div className="col-8">: +6{userDetails?.phone}</div>
                                </div>
                                {/* <div className="row mb-2">
                                    <div className="col-4">Fax Number</div>
                                    <div className="col-8">: +6{userDetails?.fax}</div>
                                </div> */}
                                <div className="row mb-2">
                                    <div className="col-4">Profile Photo</div>
                                    <div className="col-8">
                                        {userDetails?.avatar &&
                                            <img src={userDetails?.avatar} alt="logo" className='rounded' style={{ height: "150px" }} />
                                        }
                                    </div>
                                </div>
                                <div className="float-end">
                                    <Link to="/edit-profile" className='btn btn-az-primary me-1'>Edit</Link>
                                    <Link to="/" className='btn btn-secondary'>Back</Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
