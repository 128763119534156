import React from 'react';

const Footer = () => {
    return (
        <div className='footer'>
            <div className="container-fluid bg-light justify-content-center">
                <div className="row">
                    <div className="col">
                        <p className='py-2 mt-5 text-center'>&copy; {new Date().getFullYear()} TNB Switchgear 2.0 All Rights Reserved - Version 2.1.0 </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
