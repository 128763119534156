import { Modal, notification, Select } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import appConfig from "../../appConfig";

const TypesEdit = (props) => {
  const { deviceTypeDataToEdit, getTypesList, setModalShow, apiEndPoint } =
    props;
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [buildingTypeData, setBuildingTypeData] = useState({
    name: "",
    code: "",
    locationType: "",
  });
  const { name, code } = buildingTypeData;
  const onInputChange = (e) => {
    setBuildingTypeData({
      ...buildingTypeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `/${apiEndPoint}/` + deviceTypeDataToEdit._id,
        buildingTypeData,
        { withCredentials: true }
      );
      if (response) {
        setSuccessMessage("Edited Successfully");
        getTypesList();
        setTimeout(() => {
          setSuccessMessage();
          setModalShow(false);
        }, 2000);
      }
    } catch (error) {
      notification.warning({
        placement: "bottomLeft",
        message: error?.response?.data?.msg || "Something went wrong.",
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setBuildingTypeData({
      name: deviceTypeDataToEdit?.name,
      code: deviceTypeDataToEdit?.code,
      locationType: deviceTypeDataToEdit?.locationType,
    });
  }, [deviceTypeDataToEdit]);

  return (
    <Modal open={props.show} footer={null} onCancel={props.onHide}>
      <h6 className="text-start">Edit {props.title} Type</h6>
      <div className="d-flex justify-content-center">
        {isLoading && <Spinner animation="border" variant="dark" />}
      </div>
      {SuccessMessage && (
        <div className="alert alert-success" role="alert">
          {SuccessMessage}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={onInputChange}
            className="form-control"
            id="name"
            placeholder="Enter a name"
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Code
          </label>
          <input
            type="text"
            name="code"
            value={code}
            onChange={onInputChange}
            className="form-control"
            id="code"
            placeholder="Enter code"
            required
          />
        </div>
        {props?.title === "Panel" && (
          <div className="mb-3">
            <label htmlFor="locationType" className="form-label">
              Location Type
            </label>
            <Select
              suffixIcon={
                <img
                  src={`${appConfig.baseRoute}/img/arrow.svg`}
                  style={{ width: "12px", marginRight: "2px" }}
                />
              }
              style={{ width: "100%" }}
              size="large"
              name="locationType"
              value={buildingTypeData?.locationType || ""}
              onChange={(value) =>
                setBuildingTypeData({
                  ...buildingTypeData,
                  locationType: value,
                })
              }
            >
              <option value="" disabled selected>
                Select Location Type
              </option>
              <option value="left">Left</option>
              <option value="right">Right</option>
            </Select>
          </div>
        )}
        <div className="text-right">
          <button type="submit" className="btn btn-success me-2">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default TypesEdit;
