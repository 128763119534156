import { notification } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const EditDTModel = (props) => {
  const { deviceTypeDataToEdit, getDeviceTypes, setModalShow } = props;
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [buildingTypeData, setBuildingTypeData] = useState({
    name: "",
    code: "",
  });
  const { name, code } = buildingTypeData;
  const onInputChange = (e) => {
    setBuildingTypeData({
      ...buildingTypeData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.put(
        `/device-type/` + deviceTypeDataToEdit._id,
        buildingTypeData,
        { withCredentials: true }
      );
      if (response) {
        setSuccessMessage("Sensor Type Edited Successfully");
        getDeviceTypes();
        setTimeout(() => {
          setSuccessMessage();
          setModalShow(false);
        }, 2000);
      }
    } catch (error) {
      notification.warning({
        placement: "bottomLeft",
        message: error?.response?.data?.msg || "Something went wrong.",
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setBuildingTypeData({
      name: deviceTypeDataToEdit?.name,
      code: deviceTypeDataToEdit?.code,
    });
  }, [deviceTypeDataToEdit]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h6 className="text-start">Edit Sensor Type</h6>
        <div className="d-flex justify-content-center">
          {isLoading && <Spinner animation="border" variant="dark" />}
        </div>
        {SuccessMessage && (
          <div className="alert alert-success" role="alert">
            {SuccessMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onInputChange}
              className="form-control"
              id="name"
              placeholder="Enter a name"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="code" className="form-label">
              Code
            </label>
            <input
              type="text"
              name="code"
              value={code}
              onChange={onInputChange}
              className="form-control"
              id="code"
              placeholder="Enter code"
              required
            />
          </div>
          <div className="float-end">
            <button type="submit" className="btn btn-success me-2">
              Save
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditDTModel;
