import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    // isLogged: false,
    // userDetails: {},
    // userSites: {},
    currentSite: {},
    currentSiteSelectedTiles: [],
    customSites: [],
    customSiteSelectedTiles: [],
    filters: {
      xAxis: "",
      minuteAgo: "",
      startDate: "",
      endDate: "",
    },
    zoomCount: 1,
  },
  reducers: {
    setDashboardList: (state, action) => {
      // console.log(action.payload)
      state.isLogged = true;
      // [...action.payload]
    },
    setCurrentDashboard: (state, action) => {
      // state.currentSite = action.payload;
      const data = action.payload;
      // state.currentSite = data
      const idx = state.customSiteSelectedTiles.findIndex(
        (site) => site._id === data?._id
      );
      if (idx >= 0) {
        const customSelectedItems =
          state.customSiteSelectedTiles[idx]?.selectedTiles || [];
        const activeSiteIdx = state.customSites.findIndex(
          (site) => site?._id === data?._id
        );
        const activeCustomSite = state.customSites[activeSiteIdx];
        const items = [];
        data?.items.forEach((element) => {
          if(customSelectedItems.includes(element?._id)){
            const findActiveItem = activeCustomSite?.items?.find(
              (it) => it?._id === element?._id
            );
            if (findActiveItem) {
              items.push(findActiveItem);
            } else {
              items.push(element);
            }
          } else {
            items.push(element);
          }
        });
        activeCustomSite.items = items;
        state.customSites[activeSiteIdx] = activeCustomSite;
        state.currentSite = activeCustomSite;
        state.currentSiteSelectedTiles = customSelectedItems;
      } else {
        state.currentSite = data;
        state.currentSiteSelectedTiles = [];
      }
    },
    setZoomCount: (state, action) => {
      state.zoomCount = action.payload;
    },
    setDashboardFilter: (state, action) => {
      state.filters = action.payload;
    },
    updatecustomSites: (state, action) => {
      const { selectedTiles, multipleLocation, _id } = action.payload;
      const { currentSite, customSiteSelectedTiles } = state;

      console.log(multipleLocation);
      if (selectedTiles.length < 1) {
        const siteIdx = customSiteSelectedTiles.findIndex(
          (site) => site._id === _id
        );
        const activeSiteIdx = state.customSites.findIndex(
          (site) => site?._id === _id
        );
        state.customSites.splice(activeSiteIdx, 1)
        state.customSiteSelectedTiles.splice(siteIdx, 1);
      } else {
        const siteIdx = customSiteSelectedTiles.findIndex(
          (site) => site._id === _id
        );
        // new data
        const items = [];
        if (siteIdx < 0) {
          currentSite?.items.forEach((element) => {
            if (selectedTiles.includes(element?._id)) {
              items.push({ ...element, locations: multipleLocation });
            } else {
              items.push(element);
            }
          });
          // console.log({...currentSite, items})
          state.customSites.push({ ...currentSite, items });
          state.customSiteSelectedTiles.push({ selectedTiles, _id });
        } else {
          const activeSiteIdx = state.customSites.findIndex(
            (site) => site?._id === _id
          );
          currentSite?.items.forEach((element) => {
            if (selectedTiles.includes(element?._id)) {
              items.push({ ...element, locations: multipleLocation });
            } else {
              items.push(element);
            }
          });
          state.customSites[activeSiteIdx].items = items;
          state.customSiteSelectedTiles[siteIdx].selectedTiles = selectedTiles;
        }
      }
    },
    removeCustomSelector: (state, action) => {
      const id = action.payload;
      const siteIdx = state.customSiteSelectedTiles.findIndex(
        (site) => site._id === id
      );
      if (siteIdx >= 0) {
        state.customSites.splice(siteIdx, 1);
        state.customSiteSelectedTiles.splice(siteIdx, 1);
      }
      state.currentSiteSelectedTiles = [];
    },
  },
});

export const {
  setDashboardList,
  setCurrentDashboard,
  setDashboardFilter,
  removeCustomSelector,
  updatecustomSites,
  setZoomCount,
} = dashboardSlice.actions;

export default dashboardSlice;
