import { ResponsiveLineCanvas } from "@nivo/line"; //nivo/bump is better than line
import moment from "moment";
import 'moment-timezone';
import { useEffect, useState } from "react";

const NivoLine = ({ data = [], xAxis, colorSchema, fromPanel }) => {
  const [formatedData, setFormatedData] = useState([]);
  // const NivoLine = () => {

  useEffect(() => {
    setFormatedData(getData(data));
  }, [data]);

  console.log({ type: "line", data, formatedData })

  if (!formatedData.length) {
    return <p> No data found </p>;
  }
  return (
    <ResponsiveLineCanvas
      data={formatedData}
      margin={{ top: 50, right: fromPanel ? 55 : 320, bottom: 85, left: 60 }}
      // margin={{ top: 50, right: 160, bottom: 50, left: 60 }}
      yScale={{ type: "linear", stacked: false, min: "auto", max: "auto" }}
      xScale={{
        type: "time",
        format: "%Y-%m-%d %H:%M:%S",
        precision: "minute",
      }}
      xFormat={(data) => {
        return new Date(data).toString() === "Invalid Date"
          ? data
          : moment(data).format("HH:mm:ss DD/MM/YYYY");
      }}
      curve="monotoneX"
      axisTop={null}
      axisLeft={{
        legend: "time scale",
        legendOffset: -12,
        orient: "bottom",
        tickValues: 5,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 30,
        legend: "",
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisBottom={{
        format:
          xAxis === "year"
            ? "%Y"
            : xAxis === "month"
            ? "%Y-%m"
            : "%Y-%m-%d %H:%M" || "%Y-%m-%d %H:%M",
        // tickValues: "every 10 minutes",
        legend: "time scale",
        legendOffset: -12,
        orient: "bottom",
        tickValues: 15,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        legend: "",
        legendOffset: 36,
        legendPosition: "middle",
        tickStyle: {
          fill: "blue", // Change text color
          fontWeight: "bold", // Change font weight
        },
      }}
      colors={{ scheme: colorSchema || "category10" }}
      enableGridX={true}
      lineWidth={1}
      pointSize={4}
      pointColor={{ theme: "background" }}
      pointBorderWidth={1}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      crosshairType="bottom-left"
      legends={
        !fromPanel
          ? [
              {
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 140,
                translateY: 0,
                itemsSpacing: 2,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 12,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : []
      }
    />
  );
};

export default NivoLine;

function getData(data) {
  let outer_buffer = [];
  for (let i = 0; i < data.length; i++) {
    let packet = {};
    let inner_buffer = [];
    for (let j = 0; j < data[i]["data"].length; j++) {
      let buffer = {
        x: moment(data[i]["data"][j]["x"]).tz("Asia/Kuala_Lumpur").format("YYYY-MM-DD HH:mm:ss"),
        y: data[i]["data"][j]["y"],
      };
      inner_buffer.push(buffer);
    }
    packet["data"] = inner_buffer;
    packet["id"] = data[i].id;
    outer_buffer.push(packet);
  }
  return outer_buffer;
}
