import React, { memo } from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import ChartFetch from "../HomeCharts/ChartFetch";
import LayoutCounter from "../LayoutCharts/LayoutCounter";
import LayoutTextBox from "../LayoutCharts/LayoutTextBox";
import Layout3DTank from "../LayoutCharts/Layout3DTank";
import LayoutSplitCounter from "../LayoutCharts/LayoutSplitCounter";
import DialGuegeLayout from "../LayoutCharts/DialGuegeLayout";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const DashboardLayoutHome = memo(
  ({ allData, layouts, selectedTiles, handleSelect }) => {

    return (
      <div className="p-3 px-5">
        <ResponsiveReactGridLayout
          className="layout client_view"
          id="layout"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={80}
          layouts={layouts}
          draggableCancel=".clickbtn"
          isDraggable={false}
          isResizable={false}
          compactType="vertical"
          breakpoints={{ lg: 100, md: 99, sm: 76, xs: 48, xxs: 0 }}
        >
          {allData?.map((item, index) => (
            <div
              className="my-4 border"
              style={{
                background: selectedTiles.includes(item?._id)
                  ? "#fafafa"
                  : "#ffffff",
                boxShadow: selectedTiles.includes(item?._id)
                  ? "1.5px 1.5px 7px rgb(0 0 0 / 0.2)"
                  : "none",
              }}
              key={item._id}
              // data-grid={{ w: 3, h: 3, x: 0, y: 0 }}
              onClick={() => handleSelect(item?._id)}
            >
              <div className="h-100 d-flex align-items-center">
                {item.component === "bar" ||
                item.component === "line" ||
                item.component === "pie" ? (
                  <div className="p-2 h-100  w-100">
                    <ChartFetch
                      data={item}
                      type={item.component}
                      mainDashboard={true}
                    />
                  </div>
                ) : item.component === "dial" ? (
                  <DialGuegeLayout data={item} mainDashboard={true} key={layouts}/>
                ) : item.component === "splitCounter" ? (
                  <LayoutSplitCounter data={item} mainDashboard={true} />
                ) : item.component === "textBox" ? (
                  <LayoutTextBox data={item} />
                ) : item.component === "tank" ? (
                  <Layout3DTank data={item} mainDashboard={true} />
                ) : (
                  item.component === "counter" && (
                    <LayoutCounter data={item} mainDashboard={true} />
                  )
                )}
              </div>
            </div>
          ))}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
);

export default DashboardLayoutHome;
