import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import { FiEye, FiCheck } from "react-icons/fi";
import DataTable from "react-data-table-component";
import { api, endpoint } from "../../api";
import { DatePicker, Spin, notification } from "antd";
import { useSelector } from "react-redux";
import Search from "antd/es/input/Search";
import ReactToPdf from "react-to-pdf";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import appConfig from "../../appConfig";

const { RangePicker } = DatePicker;

const AlarmHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const userDetails = useSelector((state) => state?.user?.userDetails);
  const pdfRef = React.createRef();
  const options = {
    orientation: "landscape",
    unit: "in",
    format: [11.69, 8.27],
  };
  const [notifications, setNotifications] = useState([]);
  const [siteLocations, setSiteLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    site: "",
    from: searchParams.get("startDate") ? searchParams.get("startDate") : "",
    to: searchParams.get("endDate") ? searchParams.get("endDate") : "",
    [searchParams.get("alarm-status")]: true,
  });
  const { site, from, to } = filter;

  console.log(dayjs(searchParams.get("startDate")));
  const onInputChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const onSearch = (e) => {
    const text = e.target.value;
    setSearch(text);
  };

  const getNotificationHistory = async () => {
    setIsLoading(true);
    try {
      let { results, count } = await api.get(endpoint.notification_history, {
        params: {
          limit,
          page,
          sortBy: "date",
          orderBy: "DESC",
          search,
          ...filter,
        },
      });
      setNotifications(results);
      setTotal(count || 0);
    } catch (error) {}
    setIsLoading(false);
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setSiteLocations(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSiteLocations();
  }, []);

  useEffect(() => {
    document.title = `${appConfig.name} - Alarm Trigger History`;
    getNotificationHistory();
  }, [page, limit, filter, search]);

  const updateAcknowledgedBy = async (id) => {
    setIsLoading(true);
    try {
      await api.put(endpoint.notification_history, {
        acknowledgedBy: userDetails._id,
        id,
      });

      notification.success({
        message: "Alarm marked as Acknowledged",
      });
      await getNotificationHistory();
    } catch (error) {}
    setIsLoading(false);
  };
  const columns = [
    {
      name: "No.",
      cell: (row, index) => (page - 1) * limit + index + 1,
      width: "60px",
      center: true,
    },
    {
      name: "Alarm Name",
      cell: (row) => <div>{row?.notification?.name}</div>,
    },
    {
      name: "Substation",
      cell: (row) => <>{row?.notification?.site?.name}</>,
      selector: (row) => row?.site,
    },
    {
      name: "Sensor Name",
      cell: (row) => row?.notification?.device?.name,
      width: "150px",
    },
    {
      name: "Parameter",
      cell: (row) => row?.notification?.parameter?.name,
      center: true,
    },
    // {
    //     name: "Trigger Status",
    //     // cell: (row) => row?.parameter?.name,
    // },
    {
      name: "Trigger Value",
      cell: (row) => row?.value,
      center: true,
    },
    {
      name: "Acknowledged By",
      selector: (row) => (
        <div>
          <div className="mb-1">
            {row?.acknowledgedBy?.name ? row?.acknowledgedBy?.name : ""}
          </div>
          <div>
            {row?.acknowledgedBy
              ? dayjs(row?.updatedAt).format("hh:mm:ss DD/MM/YYYY")
              : ""}
          </div>
        </div>
      ),
      grow: 2.2,
    },
    {
      name: "Timestamp",
      selector: (row) => dayjs(row?.createdAt).format("DD/MM/YYYY"),
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          {row?.acknowledgedBy ? (
            ""
          ) : (
            <Button
              className="mx-2"
              variant="warning"
              onClick={() => updateAcknowledgedBy(row?._id)}
            >
              <FiCheck />
            </Button>
          )}
          <Link
            to={`/alarm-view/` + row?.notification?._id}
            title="View Alarm"
            className="btn btn-success me-1"
          >
            <FiEye />
          </Link>
        </div>
      ),
      width: "150px",
      center: "yes",
    },
  ];

  const cvsData =
    notifications.length > 0
      ? notifications.map((data, ind) => ({
          No: ind + 1,
          "Alarm Name": data?.notification?.name,
          "Site Location": data?.notification?.site?.name,
          "Device Name": data?.notification?.device?.name,
          // 'Trigger Status': '',
          "Trigger value": data?.value,
          Timestamp: dayjs(data?.device?.updatedAt).format("DD/MM/YYYY"),
          "Time Range": `${from} - ${to}`,
        }))
      : [];
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current.valueOf() > new Date();
  };

  return (
    <div className="settings" style={{ overflow: "hidden" }}>
      <div className="card p-3 m-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">MANAGE NOTIFICATION</label>
            <span className="d-block py-2">Alarm Trigger History</span>
          </div>
          <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/create-alarm">
              Create New Alarm
            </Link>
          </div>
        </div>
        <hr className="mt-0" />

        {/* filter */}
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="site" className="form-label">
              Select Substation
            </label>
            <select
              name="site"
              id="site"
              value={site}
              className="form-select"
              onChange={onInputChange}
            >
              <option value="" disabled>
                Select Substation
              </option>
              {siteLocations &&
                siteLocations.length > 0 &&
                siteLocations.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-5">
            <label htmlFor="site" className="form-label">
              Select Date | Time
            </label>
            <RangePicker
              disabledDate={disabledDate}
              presets={{ label: "date" }}
              value={[
                filter?.from ? dayjs(filter?.from) : "",
                filter?.to ? dayjs(filter?.to) : "",
              ]}
              showTime={{ format: "HH:mm" }}
              placeholder={[
                "Select Start Date | Time",
                "Select End Date | Time",
              ]}
              format="YYYY-MM-DD HH:mm"
              onChange={(e, dateString) => {
                console.log(dateString);
                setFilter({
                  ...filter,
                  from: dateString[0],
                  to: dateString[1],
                });
              }}
              size="large"
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="site" className="form-label">
              Search
            </label>
            <Search
              size="large"
              onChange={onSearch}
              placeholder="Search Alarms"
              value={search}
            />
          </div>
        </div>
        <hr />
        <Spin spinning={isLoading}>
          <div className="d-flex">
            <CSVLink
              data={cvsData}
              filename={`AlarmHistory-${new Date().getTime()}`}
              className="ms-auto btn btn-primary me-2"
              target="_blank"
            >
              Download CSV
            </CSVLink>

            {pdfRef && (
              <ReactToPdf
                targetRef={pdfRef}
                filename="Alarm history.pdf"
                options={options}
                y={0.5}
                x={0.5}
                scale={0.9}
              >
                {({ toPdf }) => (
                  <button
                    className="btn btn-success me-2"
                    onClick={() => {
                      // toPdf()
                    }}
                  >
                    {" "}
                    Download PDF{" "}
                  </button>
                )}
              </ReactToPdf>
            )}
          </div>

          <div>
            <DataTable
              noDataComponent={isLoading ? "Loading" : "No record found."}
              columns={columns}
              data={notifications}
              pagination
              paginationServer
              paginationTotalRows={total}
              paginationPerPage={limit}
              onChangePage={(data) => {
                setPage(data);
              }}
              onChangeRowsPerPage={(data) => {
                setLimit(data);
                setPage(1);
              }}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>

          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              zIndex: "-9999",
              opacity: 0,
            }}
          >
            <div ref={pdfRef} style={{ width: "11.69in" }}>
              <DataTable
                noDataComponent={isLoading ? "Loading" : "No record found."}
                columns={columns}
                data={notifications}
                pagination
                paginationServer
                paginationTotalRows={total}
                paginationPerPage={limit}
                onChangePage={(data) => {
                  setPage(data);
                }}
                onChangeRowsPerPage={(data) => {
                  setLimit(data);
                  setPage(1);
                }}
                paginationRowsPerPageOptions={[10, 20, 50]}
              />
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default AlarmHistory;
