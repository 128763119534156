import React, { useEffect, useRef, useState } from "react";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import NivoChartHome from "../../components/Charts/NivoChartHome";
import { useSelector } from "react-redux";
import SplineChart from "../../components/Charts/SplineChart";

const ChartFetch = ({ data, type, mainDashboard }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { chartTitle, locations, xAxis, operator, colorSchema } = data;
  const { filters, zoomCount } = useSelector((state) => state.dashboard);
  const { minuteAgo, startDate, endDate, key } = filters;
  const [graphData, setGraphData] = useState([]);

  // get location data
  const getChartData = async () => {
    setIsLoading(true);
    let params = {
      locations: JSON.stringify(locations),
      xAxis: xAxis,
      operator,
    };
    if (mainDashboard) {
      params = {
        ...params,
        xAxis: filters?.xAxis || xAxis,
        startDate:
          startDate &&
          startDate
            ?.subtract(zoomCount - 1, "day")
            .startOf("day")
            .toDate(),
        endDate: endDate && endDate?.toDate(),
        minuteAgo: minuteAgo,
      };
    }
    const data = await api.get(endpoint.get_chart_data, {
      params,
    });
    if (data?.success) {
      setGraphData(data?.results);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getChartData();
    const interval = setInterval(() => {
      getChartData();
    }, 30000);
    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.locations, minuteAgo, startDate, endDate, zoomCount, key]);
  // console.log(graphData);
  return (
    <Spin spinning={isLoading} className="h-100">
      <h2
        style={{
          textAlign: "center",
          fontSize: "16px",
          marginBottom: type === "line" ? "20px" : "-20px",
        }}
      >
        {chartTitle}
      </h2>
      {graphData.length > 0 ? (
        type === "line" ? (
          <SplineChart
            // title="Parameter Comparison"
            data={graphData}
            // from={graphFrom}
            // to={graphTo}
            // isLoading={isLoadingGraph}
          />
        ) : (
          <NivoChartHome
            data={graphData}
            type={type}
            xAxis={xAxis}
            colorSchema={colorSchema}
          />
        )
      ) : (
        <h2
          style={{
            textAlign: "center",
            fontSize: "15px",
            color: "red",
            marginTop: "50px",
          }}
        >
          No data found!
        </h2>
      )}
    </Spin>
  );
};

export default ChartFetch;
