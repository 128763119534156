import { Spin, Table } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { ThemeContext } from "../../App";

const ReportTable = ({ data, title, page, limit, parameters, setPage, setLimit, total, isLoading = false }) => {
    const generatedTableColums = useMemo(() => {
        let columps = [
            {
                title: "No.",
                width: "60px",
                render: (_, __, index) => (page - 1) * limit + index + 1,
            },
            {
                title: "Time",
                render: (row) => moment(row._id).format("DD/MM/YYYY hh:mm:ss a"),
                width: "200px",
                center: true,
            },
        ];
        if (parameters.length > 0) {
            columps = [
                ...columps,
                ...parameters.map(({ label }) => {
                    return {
                        title: label,
                        render: (row) => (row?.[label] ? Number(row?.[label]) : "--"),
                        center: true,
                    };
                }),
            ];
        }
        return columps;
    }, [parameters, page, limit]);

    return (
        <div className="p-3">
            <Spin spinning={isLoading}>
                <Table
                    columns={generatedTableColums}
                    dataSource={data}
                    pagination={{
                        onChange: (_p, size) => {
                            setPage(_p);
                            setLimit(size);
                        },
                    }}
                />
            </Spin>
        </div>
    );
};

export default ReportTable;
