export const RangeInputs = ({ option, warningType, onInputChange, state }) => {
  return (
    <>
      {option && (
        <label
          className="form-label text-capitalize"
          style={{ marginBottom: "0px" }}
        >
          Set {warningType} Value
        </label>
      )}
      {option === "min" && (
        <div className="col-md-6 mb-3">
          <label htmlFor="value" className="form-label">
            Set Value
          </label>
          <input
            type="number"
            name={warningType + "Min"}
            value={state[warningType + "Min"]}
            className="form-control"
            placeholder="Enter minimum value to trigger alarm"
            onChange={onInputChange}
            required
          />
        </div>
      )}
      {option === "max" && (
        <div className="col-md-6 mb-3">
          <label htmlFor="value" className="form-label">
            Set Value
          </label>
          <input
            type="number"
            name={warningType + "Max"}
            value={state[warningType + "Max"]}
            className="form-control"
            placeholder="Enter maximum value to trigger alarm"
            onChange={onInputChange}
            required
          />
        </div>
      )}
      {option === "range" && (
        <>
          <div className="col-md-6 mb-3">
            <label htmlFor="value" className="form-label">
              Start Value
            </label>
            <input
              type="number"
              name={warningType + "Min"}
              value={state[warningType + "Min"]}
              className="form-control"
              placeholder="Enter minimum value to trigger alarm"
              onChange={onInputChange}
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="value" className="form-label">
              End Value
            </label>
            <input
              type="number"
              name={warningType + "Max"}
              value={state[warningType + "Max"]}
              className="form-control"
              placeholder="Enter maximum value to trigger alarm"
              onChange={onInputChange}
              required
            />
          </div>
        </>
      )}
    </>
  );
};
