import React from 'react';
import DashboardForm from './DashboardForm';

const CreateDashboard = () => {
  return (
    <div>
      <DashboardForm mode='create' />
    </div>
  );
};

export default CreateDashboard;