import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsCheck2All } from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import { CustomButton } from '../../components/Shared';
import { bulkUploadStatus } from '../../redux/slices/device.slice';
import { getCSVUploadStatus, updateCSVUploadStatus, uploadBulkDeviceData } from '../../services';

const DeviceCSVUpload = () => {
  // const { status, message } = useSelector((state) => state.device.bulkUpload);

  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(bulkUploadStatus.init);

  const onChange = (e) => {
    const currentFile = e.target.files[0];
    if (currentFile.type === 'text/csv') {
      setFile(currentFile);
    } else {
      alert('Please upload csv file only!');
    }
  };

  const handleUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    uploadBulkDeviceData(formData)
      .then((res) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    getCSVUploadStatus().then((res) => {
      if (res.data.success) {
        setStatus(res.data.status);
      }
    });

    const interval = setInterval(() => {
      getCSVUploadStatus().then((res) => {
        if (res.data.success) {
          setStatus(res.data.status);
        }
      });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="card p-3 m-3">
      <div
        className="d-flex align-items-center flex-column justify-content-center"
        style={{
          borderStyle: 'dashed',
          borderColor: '#ddd',
          marginTop: 20,
          borderRadius: 5,
          height: 200,
        }}
      >
        {[bulkUploadStatus.pending, bulkUploadStatus.success, bulkUploadStatus.failed].includes(status) ? (
          <>
            {status === bulkUploadStatus.failed && (
              <div className="d-flex flex-column align-items-center justify-content-center gap-3 text-muted">
                <div className="d-flex align-items-center justify-content-center gap-3 text-success">
                  <AiOutlineCloseCircle size={30} color="red" /> <span style={{ fontSize: 30 }}>Unsuccessful</span>
                </div>
                <p style={{ color: '#aaa', fontWeight: 200, width: '60%', textAlign: 'center' }}>
                  filed uploading csv
                  <span
                    className="text-warning"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      updateCSVUploadStatus({ status: 'init' });
                    }}
                  >
                    Upload again
                  </span>
                </p>
              </div>
            )}
            {status === bulkUploadStatus.success && (
              <div className="d-flex flex-column align-items-center justify-content-center gap-3 text-muted">
                <div className="d-flex align-items-center justify-content-center gap-3 text-success">
                  <BsCheck2All size={30} /> <span style={{ fontSize: 30 }}>Upload in progress</span>
                </div>
                <p style={{ color: '#aaa', fontWeight: 200, width: '60%', textAlign: 'center' }}>
                  Successfully uploaded all device and it's data.
                  <span
                    className="text-warning"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      updateCSVUploadStatus({ status: 'init' });
                    }}
                  >
                    Upload more
                  </span>
                </p>
              </div>
            )}
            {status === bulkUploadStatus.pending && (
              <div className="d-flex flex-column align-items-center justify-content-center gap-3 text-muted">
                <div className="d-flex align-items-center justify-content-center gap-3 ">
                  <Spinner /> <span style={{ fontSize: 30 }}>Upload in progress</span>
                </div>
                <p style={{ color: '#aaa', fontWeight: 200, width: '60%', textAlign: 'center' }}>
                  Please wait until file is being uploaded. You can also browse other page during upload without
                  refreshing{' '}
                  <span
                    className="text-warning"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      updateCSVUploadStatus({ status: 'init' });
                    }}
                  >
                    cancel upload
                  </span>
                </p>
              </div>
            )}
          </>
        ) : (
          <label
            className="d-flex align-items-center flex-column justify-content-center"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <h4 className="text-muted d-flex align-items-center gap-2 ">
              <FiUploadCloud /> Choose File
            </h4>
            {file ? (
              <p style={{ color: '#232323', fontWeight: 200 }}>
                {file.name} ({parseFloat(file.size / (1024 * 1024)).toFixed(2)} MB)
              </p>
            ) : (
              <p style={{ color: '#aaa', fontWeight: 200 }}>No file selected</p>
            )}
            <input type="file" className="d-none" onChange={onChange} />
          </label>
        )}
      </div>

      <div className="mt-3 ml-auto">
        <CustomButton
          className="btn-danger"
          icon={<FiUploadCloud />}
          loading={loading}
          onClick={handleUpload}
          disabled={status === bulkUploadStatus.pending}
        >
          Upload
        </CustomButton>
      </div>
    </div>
  );
};

export default DeviceCSVUpload;
