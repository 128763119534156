import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api, endpoint } from "../../api";
import moment from "moment";

const LayoutSplitCounter = ({ data, mainDashboard }) => {
  const { filters, zoomCount } = useSelector((state) => state.dashboard);
  const { minuteAgo, startDate, endDate, key, xAxis } = filters;
  const { chartTitle, locations, unit, titleFontSize, valueFontSize } = data;
  const [value, setValue] = useState({});

  const getDial = async () => {
    let params = {
      device: locations[0].device,
      startDate: ""
    };
    if (mainDashboard) {
      params = {
        ...params,
        startDate:
          startDate && moment(startDate)?.subtract(zoomCount, "day").toDate(),
        endDate: endDate && moment(endDate).toDate(),
        minuteAgo: minuteAgo,
        xAxis
      };
    }

    const { result, success } = await api.get(
      endpoint.dial + "/" + locations[0].yAxis,
      {
        params
      }
    );
    if (success) {
      // console.log({ result, locations });
      setValue(result);
    }
  };

  useEffect(() => {
    getDial();
    const interval = setInterval(() => {
      getDial();
    }, 30000);
    return () => clearInterval(interval);

  }, [locations, minuteAgo, startDate, endDate, key, zoomCount]);

  return (
    <div className="p-2 w-100">
      <div className="d-flex">
        <div
          className="mr-1 border px-2 py-3 text-white"
          style={{ background: "#00B1A9", width: "50%" }}
        >
          <h2
            style={{ fontSize: titleFontSize ? `${titleFontSize}pt` : "14pt" }}
          >
            {chartTitle}
          </h2>
        </div>
        <div
          className="border px-2 py-3 text-white"
          style={{ background: "#A6A6A6", width: "50%" }}
        >
          <h3
            style={{ fontSize: valueFontSize ? `${valueFontSize}pt` : "14pt" }}
          >
            {value?.value ? Number(value.value).toFixed(2) : 0}{" "}
            <strong>{unit}</strong>{" "}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default LayoutSplitCounter;
