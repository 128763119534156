import { Spin, Tabs } from "antd";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { getBlinkingBG } from "./PanelOverview";

export const DeviceDataByPanel = ({ lineup, site }) => {
  const [selectedLineup, setSelectedLineup] = useState(lineup?.[0]?._id || "");
  const [panels, setPanels] = useState([]);
  const [isBlinking, setBlinking] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const getDeviceDataByPanel = async (lineup) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`dashboard/substation-overview-data`, {
        params: {
          lineup,
          site,
        },
      });
      let results = response.data?.results || [];
      let serializedCompartmentList = [
        "top",
        "bottom",
        "main",
        "reverse",
        "cable",
      ];
      results = results.map((panelRoot) => {
        const newCompartments = [];
        const compartmentRoot = panelRoot?.compartmentTypes || [];
        serializedCompartmentList.map((serializedCompartment) => {
          let foundCompartment = compartmentRoot.find(({ compartmentType }) => {
            return compartmentType?.name
              ?.toLowerCase()
              .includes(serializedCompartment);
          });
          if (foundCompartment && foundCompartment?.devices?.length) {
            foundCompartment.devices = getSerializedParameters(
              foundCompartment.devices
            );
            newCompartments.push(foundCompartment);
          }
        });
        panelRoot.compartmentTypes = newCompartments;
        return panelRoot;
      });
      setPanels(
        results
        // .concat(data?.results)
        // .concat(data?.results)
        // .concat(data?.results)
        // .concat(data?.results)
        // .concat(data?.results)
        // .concat(data?.results)
        // .concat(data?.results)
      );
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const firstLineup = lineup?.[0]?._id || "";
    if (firstLineup && site) {
      setSelectedLineup(firstLineup);
      getDeviceDataByPanel(firstLineup);
    } else {
      setPanels([]);
    }
  }, [lineup, site]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBlinking((prevBlinking) => !prevBlinking);
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedLineup) {
        getDeviceDataByPanel(selectedLineup);
      }
    }, 600000);
    return () => clearInterval(intervalId);
  }, [selectedLineup]);
  
  return (
    <>
      <div className="mb-0">
        <label style={{ fontWeight: "500" }}>Substation Overview</label>
      </div>
      {lineup?.length === 0 ? (
        <p className="font-weight-bold text-center">
          There is no switchgear types!
        </p>
      ) : (
        <Tabs
          style={{ color: "black" }}
          onChange={(e) => {
            setSelectedLineup(e);
            getDeviceDataByPanel(e);
          }}
          centered
          type="card"
          activeKey={selectedLineup}
          items={lineup.map(({ name, _id }) => {
            return {
              label: name,
              key: _id,
              children: (
                <Spin spinning={isLoading}>
                  <DeviceDataTable panels={panels} isBlinking={isBlinking} />
                </Spin>
              ),
            };
          })}
        />
      )}
    </>
  );
};

const DeviceDataTable = ({ panels, isBlinking }) => {
  if (!panels?.length) {
    return <p className="font-weight-bold text-center">No data to show!</p>;
  }
  return (
    <div
      className="d-flex"
      style={{ overflowX: "auto", maxWidth: "calc(100vw - 340px)" }}
    >
      {panels.map(({ panelTypeDetails, compartmentTypes }, outterIdx) => {
        return (
          <div
            key={outterIdx}
            style={{
              border: "1px solid #000",
              marginRight: "3px",
              paddingBottom: "5px",
              minWidth: "max-content",
              textAlign: "center",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #000",
                textAlign: "center",
                padding: "2px",
              }}
            >
              {panelTypeDetails?.name}
            </div>
            {compartmentTypes.map(({ compartmentType, devices }, idx) => {
              return (
                <div
                  style={{ padding: "0 5px 0 5px", minWidth: "100px" }}
                  key={idx}
                >
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                      padding: "2px",
                    }}
                  >
                    {compartmentType?.name}
                  </p>
                  <div style={{ padding: "5px", border: "1px solid blue" }}>
                    {devices?.map(({ _id, latestDeviceData, phase }) => {
                      let shouldBlink =
                        isBlinking && latestDeviceData?.shouldBlink;
                      return (
                        <p key={_id} className="mb-0">
                          {moment(Date.now()).diff(
                            latestDeviceData?.date || Date.now(),
                            "hours"
                          ) >= 3 ? (
                            <span>N/A</span>
                          ) : (
                            <>
                              {latestDeviceData?.name}{" "}
                              {getPhase(phase, latestDeviceData?.name)}:
                              <span
                                style={{
                                  padding: "0 3px",
                                  fontWeight: "bold",
                                  backgroundColor: shouldBlink
                                    ? getBlinkingBG(
                                        latestDeviceData?.alarmStatus
                                      )
                                    : "transparent",
                                }}
                              >
                                {latestDeviceData?.value}
                              </span>
                            </>
                          )}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export const getPhase = (phase, parameterName) => {
  if (parameterName !== "Temperature") return;
  if (phase?.toLowerCase() === "red")
    return (
      <span style={{ fontWeight: "bold", color: "red", marginRight: "5px" }}>
        R
      </span>
    );
  if (phase?.toLowerCase() === "yellow")
    return (
      <span
        style={{ fontWeight: "bold", color: "#DEC20B", marginRight: "5px" }}
      >
        Y
      </span>
    );
  if (phase?.toLowerCase() === "blue")
    return (
      <span style={{ fontWeight: "bold", color: "blue", marginRight: "5px" }}>
        B
      </span>
    );
};

export const getSerializedParameters = (givenDevices) => {
  const devices = [];
  let tempDevice = [
    givenDevices.find(
      (device) =>
        device?.latestDeviceData?.name === "Temperature" &&
        device?.latestDeviceData?.phase === "Red"
    ),
    givenDevices.find(
      (device) =>
        device?.latestDeviceData?.name === "Temperature" &&
        device?.latestDeviceData?.phase === "Yellow"
    ),
    givenDevices.find(
      (device) =>
        device?.latestDeviceData?.name === "Temperature" &&
        device?.latestDeviceData?.phase === "Blue"
    ),

    givenDevices.find(
      (device) => device?.latestDeviceData?.name === "Surface Discharge"
    ),
    givenDevices.find(
      (device) => device?.latestDeviceData?.name === "Partial Discharge"
    ),
  ];
  tempDevice.map((item) => {
    if (item) devices.push(item);
  });

  return devices;
};
