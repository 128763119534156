import React from "react";
import DialGuege from "../HomeCharts/DialGuege";

const DialGuegeLayout = ({ data, mainDashboard, dialChangekey }) => {
  return (
    <div className="p-2 h-100 w-100">
      <h2 style={{ textAlign: "center", fontSize: "16px" }}>{data.title}</h2>
      <DialGuege data={data} mainDashboard={mainDashboard} dialChangekey={dialChangekey}/>
    </div>
  );
};

export default DialGuegeLayout;
