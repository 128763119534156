import { createSlice } from '@reduxjs/toolkit';

export const bulkUploadStatus = {
  init: 'init',
  pending: 'pending',
  success: 'success',
  failed: 'failed',
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    bulkUpload: { status: bulkUploadStatus.init, message: '' },
  },
  reducers: {
    /**
     *
     * @param {*} state
     * @param {{ payload: { status: string; message: string}}} action
     */
    setBulkUploadStatus: (state, action) => {
      state.bulkUpload.status = action.payload.status;
      state.bulkUpload.message = action.payload.message || '';
    },
  },
});

export const { setBulkUploadStatus } = deviceSlice.actions;

export default deviceSlice;
