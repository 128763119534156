import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setUserDetails } from '../../redux/slices/user.slice';
import { api, endpoint } from "../../api";
import Swal from "sweetalert2";

export default function EditProfile() {
    //   const
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.user?.userDetails);

    const [IsLoading, setIsLoading] = useState(false);
    const [updateProfileData, setUpdateProfileData] = useState({
        name: "",
        email: "",
        phone: "",
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const { name, email, phone } = updateProfileData;

    useEffect(() => {
        if (userDetails) {
            setUpdateProfileData({
                name: userDetails.name,
                email: userDetails.email,
                phone: userDetails.phone,
                fax: userDetails.fax,
            });
        }
    }, [userDetails]);

    const handleFileUpload = (file) => {
        setImageUrl(URL.createObjectURL(file));
        let form = new FormData();
        form.append("avatar", file);
        setSelectedImage(form);
    };

    const onInputChange = (e) => {
        setUpdateProfileData({
            ...updateProfileData,
            [e.target.name]: e.target.value,
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (selectedImage) {
                await api.put(endpoint.update_user_avater, selectedImage);
            }
            const user = await api.put(
                endpoint.update_user_details,
                updateProfileData
            );
            dispatch(setUserDetails(user));
            navigate("/profile");
            Swal.fire({
                title: "Done!",
                text: "Profile Updated Successfully.",
                icon: "success",
                timer: 2000,
                button: false,
            });
        } catch (error) {
            Swal.fire({
                title: "Oops...!",
                text: "Something went wrong.",
                icon: "error",
                timer: 2000,
                button: false,
            });
        }
        setSelectedImage(null);
        setImageUrl(null);
        setIsLoading(false);
    }
    return (
        <div className="az-content-body">
            <div className="row row-sm mg-b-15 mg-sm-b-20">
                <div className="col-lg-12">
                    <div className="card card-dashboard-six">
                        <div className="card-header">
                            <div>
                                <label className="az-content-label">Profile</label>
                                <span className="d-block py-2">Update profile Information</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className='d-flex justify-content-center'>
                                    {IsLoading && <Spinner animation="border" variant="success" />}
                                </div>
                               
                                <form onSubmit={submitHandler}>
                                    <div className="mb-3">
                                        <label for="name" className="form-label">Name</label>
                                        <input type="text" className="form-control" name='name' value={name} onChange={onInputChange} id="name" placeholder='Enter your name' />
                                    </div>
                                    <div className="mb-3">
                                        <label for="email" className="form-label">Email address</label>
                                        <input type="email" className="form-control" name='email' value={email} onChange={onInputChange} id="email" placeholder='Enter your email' />
                                    </div>
                                    <div className="mb-3">
                                        <label for="phone" className="form-label">Phone Number</label>
                                        <div className='input-group'>
                                            <span className="input-group-text" id="basic-addon1">+6</span>
                                            <input type="number" name='phone' value={phone} onChange={onInputChange} className="form-control" id="phone" placeholder='Enter phone number' />
                                        </div>
                                    </div>
                                    {/* <div className="mb-3">
                                        <label for="fax" className="form-label">Fax Number</label>
                                        <div className='input-group'>
                                            <span className="input-group-text" id="basic-addon1">+6</span>
                                            <input type="number" name='fax' value={fax} onChange={onInputChange} className="form-control" id="fax" placeholder='Enter fax number' />
                                        </div>
                                    </div> */}
                                    <div className="mb-3">
                                        <label htmlFor="avatar" className="form-label d-block">Profile Photo</label>

                                        {imageUrl && selectedImage ? (
                                            <div mt={2} textAlign="center">
                                                <div>Image Preview:</div>
                                                <img src={imageUrl} alt={selectedImage.name} height="100px" />
                                            </div>
                                        )
                                            :
                                            <>
                                                <input className='form-control' accept="image/*" type="file" id="select-image" style={{ display: 'none' }} onChange={e => handleFileUpload(e.target.files[0])} />
                                                <label for="select-image">
                                                    {userDetails?.avatar ?
                                                        <img src={userDetails?.avatar} alt="" height="100px" className='rounded-3' />
                                                        :
                                                        <img src="assets/images/avatar.png" alt="" height="100px" className='rounded-3' />
                                                    }
                                                </label>
                                            </>
                                        }
                                    </div>
                                    <div className="float-end">
                                        <button type="submit" className="btn btn-success me-1">Update</button>
                                        <Link to="/profile" className='btn btn-secondary'>Cancel</Link>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
