import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { endpoint } from '../../api';
import DashboardForm from './DashboardForm';

const EditDashboard = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const getDashboard = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await axios.get(endpoint.dashboard + '/' + id, { withCredentials: true });
      if (response.status === 200) {
        setData(response.data.result);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getDashboard();
  }, [id]);

  return (
    <div>
      <DashboardForm mode="edit" data={data} />
    </div>
  );
};

export default EditDashboard;
