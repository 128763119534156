import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DashboardOne from "./DashboardOne";
import MainDashboard from "./MainDashboard";
import { FullScreen } from "react-full-screen";
import PanelOverview from "./PanelOverview";
import SubstationOverview from "./SubstationOverview";

const Dashboard = ({ handle }) => {
  const { activeDashboard } = useSelector((state) => state.user);

  useEffect(() => {
    document.title = "TNB Switchgear 2.0";
  }, []);

  return (
    <div>
      <FullScreen handle={handle}>
        {(() => {
          if (!activeDashboard) {
            return <MainDashboard />;
          } else if (activeDashboard === "panelOverview") {
            return <PanelOverview />;
          } else if (activeDashboard === "substationOverview") {
            return <SubstationOverview />;
          } else if (activeDashboard) {
            return <DashboardOne id={activeDashboard} />;
          } else {
            return (
              <div className="mt-4 text-center">
                {activeDashboard === null ? (
                  <h1>Loading dashboard...</h1>
                ) : (
                  <h1>No dashboard selected</h1>
                )}
              </div>
            );
          }
        })()}
      </FullScreen>
    </div>
  );
};

export default Dashboard;
