import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";

const NivoBar = ({ data, keys, xAxis, colorSchema }) => {
  // console.log({ data });
  console.log("NivoBar => ", { data });
  return (
    <ResponsiveBar
      data={data}
      // keys={data.map((d) => moment(d.date).format("hh:mm:ss DD/MM/YYYY"))}
      keys={keys}
      indexBy="date"
      margin={{ top: 50, right: 200, bottom: 85, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: colorSchema || "category10" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 30,
        legend: "",
        legendPosition: "middle",
        legendOffset: 32,
        // format: xAxis === "year" ? "%Y" : xAxis === 'month' ? "%Y-%m" : "%Y-%m-%d %H:%M:%S" || "%Y-%m-%d %H:%M:%S",
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "food",
        // legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      barAriaLabel={(e) =>
        e.id + ": " + e.formattedValue + " in country: " + e.indexValue
      }
      valueFormat={(value, index) => (
        <tspan fontWeight="bold" fontSize={12} fill="#fff">
          {value}
        </tspan>
      )}
    />
  );
};
export default NivoBar;
