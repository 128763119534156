import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import DataTable from "react-data-table-component";
import moment from "moment";

const twelveHoursAgo = new Date(Date.now() - 12 * 60 * 60 * 1000);

const DeviceDataHistoryModal = ({ show, onHide, modalId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  // get sensors
  const getSensorList = async () => {
    try {
      setIsLoading(true);

      let { results, count } = await api.get(endpoint.get_devices_data, {
        params: {
          deviceId: modalId,
          limit,
          page,
          orderBy: "DESC",
        },
      });
      setHistory(results);
      setTotal(count);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (modalId) {
      getSensorList();
    }
  }, [modalId]);

  const columns = [
    {
      name: "No.",
      selector: (row, ind) => ind + 1,
      //   width: "60px",
    },
    {
      name: "Time Stamp",
      cell: (row) => (
        <p className="m-0">
          {moment(row?._id).format("hh:mm:ss DD/MM/YYYY") || "--"}
        </p>
      ),
    },
    {
      name: "Active Status",
      cell: (row) => (
        <div>
          {twelveHoursAgo <= new Date(row?.updatedAt) ? (
            <span className="badge text-bg-success">Online</span>
          ) : (
            <span className="badge text-bg-danger">Offline</span>
          )}
        </div>
      ),
      center: true,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columns}
            data={history}
            noDataComponent={isLoading ? "Loading" : "No record found."}
            pagination
            paginationTotalRows={total}
            paginationPerPage={limit}
            paginationRowsPerPageOptions={[10, 20, 50]}
          />
          {/* <DataTable
            columns={columns}
            data={history}
            pagination
            paginationServer
            noDataComponent={isLoading ? "Loading" : "No record found."}
            className="mt-3"
            paginationTotalRows={total}
            paginationPerPage={limit}
            onChangePage={(data) => {
              setPage(data);
            }}
            onChangeRowsPerPage={(data) => {
              setLimit(data);
              setPage(1);
            }}
            paginationRowsPerPageOptions={[10, 20, 50]}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Spin>
  );
};

export default DeviceDataHistoryModal;

// function MyVerticallyCenteredModal(props)

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);
