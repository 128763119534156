import React, { useEffect, useRef, useState } from "react";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import GaugeComponent from "react-gauge-component";

const DialGuege = ({ data, mainDashboard, dialChangekey }) => {
  const { filters, zoomCount } = useSelector((state) => state.dashboard);
  const { minuteAgo, startDate, endDate, key, xAxis } = filters;
  const { chartTitle, locations, min, max } = data;
  const [dial, setDial] = useState({});
  const [ticks, setTicks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unit, setUnit] = useState(data.unit);

  const getDial = async () => {
    setIsLoading(true);
    try {
      let params = {
        device: locations[0].device,
        startDate: ""
      };
      if (mainDashboard) {
        params = {
          ...params,
          startDate:
            startDate && moment(startDate)?.subtract(zoomCount, "day").toDate(),
          endDate: endDate && moment(endDate).toDate(),
          minuteAgo: minuteAgo,
          xAxis,
        };
      }

      const { result, success } = await api.get(
        endpoint.dial + "/" + locations[0].yAxis,
        {
          params,
        }
      );
      if (success) {
        setDial(result);
      }
    } catch (error) { }
    setIsLoading(false);
  };

  // console.log("dial", dial);

  useEffect(() => {
    getDial();
    setUnit(data.unit);
    const tickRange = [{ value: min }];
    let i = parseInt(min / 100 + 1);
    while (i * 200 <= max) {
      tickRange.push({ value: i * 200 });
      i++;
    }
    setTicks(tickRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data.locations, data.unit, dial.value]);

  useEffect(() => {
    getDial();
  }, [minuteAgo, startDate, endDate, key, zoomCount]);

  let newValue = Number(dial?.value) || 0;
  let newMax = Math.max(newValue, max);
  let newMin = Math.min(newValue, min);
  let nbSubArcs = (newMax - newMin) / 2;

  console.log({ max, min, value: dial?.value });
  console.log({ newMax, newMin, newValue });


  return (
    <Spin spinning={isLoading}>
      <h2 style={{ textAlign: "center", fontSize: "16px" }}>{chartTitle}</h2>
      {ticks?.length > 0 && (
        <GaugeComponent
          className="text-center gauge_001"
          key={dialChangekey}
          arc={{
            nbSubArcs: newMin + nbSubArcs,
            colorArray: ["#000080", "#EA4228"],
            width: 0.25,
            padding: 0.02,
          }}
          labels={{
            valueLabel: {
              style: { fill: "#494c57", fontSize: "14px" },
              formatTextValue: (value) => value + ` ${unit}`,
            },
            tickLabels: {
              type: "outer",
              ticks,
              valueConfig: {
                //   formatTextValue: (value)=>value+ unit,
              },
              defaultTickValueConfig: {
                style: { fill: "#494c57", fontWeight: "600" },
              },
              defaultTickLineConfig: {
                style: { fill: "#494c57" },
              },
            },
          }}
          value={newValue}
          maxValue={newMax}
          minValue={newMin}
        />
      )}
    </Spin>
  );
};

export default DialGuege;
