import { Input } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import { BiCopy, BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { api, endpoint } from "../../api";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDashboard } from "../../redux/slices/user.slice";
import { DataTable } from "../../components";

const DashboardList = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { userDetails } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(Number(searchParams.get("limit")) || 10);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setLimit(Number(searchParams.get("limit")) || 10);
    setPage(Number(searchParams.get("page")) || 1);
  }, [searchParams]);

  const getDashboardList = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await api.get(endpoint.get_dashboard_list, {
        withCredentials: true,
        params: {
          limit,
          page,
        },
      });
      setData(response.data);
      setTotal(response.count);
      setIsLoading(false);
    }
  };

  const getActiveDashboard = async () => {
    setIsLoading(true);
    try {
      const { result, success } = await api.get(endpoint.active_dashboard);
      if (success) {
        dispatch(setActiveDashboard(result));
      } else {
        dispatch(setActiveDashboard(""));
      }
    } catch (error) {
      dispatch(setActiveDashboard(""));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDashboardList();
  }, [page, limit]);

  const columns = [
    {
      title: "No.",
      // width: "60px",
      render: (_, __, index) => <div>{(page - 1) * limit + index + 1}</div>,
    },
    {
      title: "Dashboard Name",
      render: (row) => row.name,
      maxWidth:"100px"
    },
    {
      title: "Date Created",
      render: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      align: "center",
    },
    {
      title: "Default",
      align: "center",
      render: (row) => (
        <Input
          type="checkbox"
          checked={Boolean(row?.isSuperAdminDefault)}
          onChange={() =>
            makeDefaultHandler(
              userDetails._id,
              row._id,
              Boolean(row?.isSuperAdminDefault)
            )
          }
        />
      ),
    },
    {
      title: "Action",
      width: "220px",
      align: "center",
      render: (row) => (
        <div>
          <Link to={`/dashboard-list/${row._id}`}>
            <Button className="btn btn-success  me-1">
              <AiOutlineEye className="text-white" />
            </Button>
          </Link>
          {/* EditDashboard */}
          <Link to={`/edit-dashboard/${row._id}`}>
            <Button className="btn btn-info me-1">
              <BiEdit className="text-white" />
            </Button>
          </Link>
          <Button
            className="btn btn-primary me-1"
            title="Copy dashboard"
            onClick={() => copyHandler(row._id)}
          >
            <BiCopy />
          </Button>
          <Button
            className="me-2 btn-danger"
            onClick={() => deleteHandler(row._id)}
          >
            <MdDeleteOutline className="text-white" />
          </Button>
        </div>
      ),
    },
  ];

  const copyHandler = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "This action will duplicate the existing dashboard",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(`/edit-dashboard/${id}?cp=true`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // handler
  const deleteHandler = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you wish to delete this dashboard?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.delete(
            `${endpoint.remove_dashboard}/${id}`,
            { withCredentials: true }
          );
          if (response.status === 200) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            getDashboardList();
            getActiveDashboard();
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const makeDefaultHandler = async (userId, dashboardId, isActive) => {
    Swal.fire({
      title: "Are you sure?",
      text: isActive
        ? "This will remove this dashboard as default dashboard for super admin."
        : "Make this dashboard as default dashboard for super admin",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#5A5A5A",
      confirmButtonText: "Confirm",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          const body = { userId, dashboardId, isActive: !isActive };
          const { success } = await api.put(endpoint.active_dashboard, body);
          if (success) {
            getDashboardList();
            getActiveDashboard();
            Swal.fire({
              title: "Success!",
              text: "Dashboard Updated Successfully!",
              icon: "success",
              timer: 1500,
            });
          } else {
            Swal.fire({
              title: "Deleted!",
              text: "User has already default dashboard.",
              icon: "error",
              timer: 1500,
            });
          }
        }
      })
      .catch(() =>
        Swal.fire({
          title: "",
          text: "User has already default dashboard.",
          icon: "error",
          timer: 1500,
        })
      );
  };

  return (
    <div className="px-3 mt-3">
      <div className="border bg-white p-3">
        {/* <h5>Dashboard List</h5> */}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">Manage Dashboard</label>
            <span className="d-block py-2">Dashboard List</span>
          </div>
          <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/create-dashboard">
              Create Dashboard
            </Link>
          </div>
        </div>
        <hr className="mt-0 mb-5" />

        <DataTable
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: limit,
            current: page,
            total,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSizeOptions: [10, 20, 50],
            responsive: true,
          }}
          onChange={(pagination) =>
            navigate(
              `/dashboard-list?page=${pagination.current}&limit=${pagination.pageSize}`
            )
          }
        />
      </div>
    </div>
  );
};

export default DashboardList;
