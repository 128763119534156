import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import EditDTModel from "../../components/Modals/EditDTModel";
import { FiTrash, FiEdit } from "react-icons/fi";
import TypesEdit from "../../components/Modals/TypesEdit";

const TypesTable = ({ data, getTypesList, title, apiEndPoint }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [deviceTypeDataToEdit, setDeviceTypeDataToEdit] = useState();
  const columns = [
    {
      name: "No.",
      cell: (row) => row.idx,
      // width: "60px",
      from: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <p
          style={{
            maxWidth: "10vw",
            marginBottom: "0px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row?.name}
        </p>
      ),
      from: true,
    },
    {
      name: "Code",
      // selector: (row) => row.name,
      selector: (row) => (
        <p
          style={{
            maxWidth: "10vw",
            marginBottom: "0px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row?.code}
        </p>
      ),
      // from: title==='Compartment'
      from: true,
    },
    {
      name: "Location",
      // selector: (row) => row.name,
      selector: (row) => (
        <p
          style={{
            marginBottom: "0px",
            textTransform: "capitalize",
          }}
        >
          {row?.locationType}
        </p>
      ),
      from: title === "Panel",
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button className="btn btn-info me-1" onClick={() => editType(row)}>
            <FiEdit />
          </button>
          <button
            className="btn btn-danger"
            onClick={() => deleteType(row._id)}
          >
            <FiTrash />
          </button>
        </div>
      ),
      right: true,
      from: true,
    },
  ].filter((it) => it?.from);

  const editType = (data) => {
    setDeviceTypeDataToEdit(data);
    setModalShow(true);
  };
  const deleteType = async (typeId) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(`/${apiEndPoint}/` + typeId, {
        withCredentials: true,
      });
      if (response) {
        setSuccessMessage("Deleted successfully.");
        setErrorMessage("");
        getTypesList();
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setSuccessMessage("");
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 2000);
    }
  };
  return (
    <div>
      <span className="d-block py-2">{title} Type</span>
      <hr className="mt-0" />
      <div className="d-flex justify-content-center">
        {isLoading && <Spinner animation="border" variant="dark" />}
      </div>
      {SuccessMessage && (
        <div className="alert alert-success" role="alert">
          {SuccessMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <DataTable
        columns={columns}
        customStyles={{ headCells: { style: { marginRight: "27px" } } }}
        data={data.map((item, idx) => {
          item.idx = idx + 1;
          return item;
        })}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 50]}
      />
      <TypesEdit
        show={modalShow}
        title={title}
        apiEndPoint={apiEndPoint}
        onHide={() => setModalShow(false)}
        deviceTypeDataToEdit={deviceTypeDataToEdit}
        setModalShow={setModalShow}
        getTypesList={getTypesList}
      />
    </div>
  );
};

export default TypesTable;
