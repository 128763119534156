import { DatePicker, Dropdown, Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { BiRefresh, BiSearch, BiX, BiZoomOut } from "react-icons/bi";
import { FiClock } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  setDashboardFilter,
  setZoomCount,
} from "../../redux/slices/dashboard.slice";
import moment from "moment";

export const TimePeriodFilter = ({ }) => {
  const ref = useRef();
  const timeInputRef = useRef();
  const { filters, zoomCount } = useSelector((state) => state.dashboard);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [timeOptions, setTimeoptions] = useState(quickTimeOption);
  const onSerchTimeOption = (e) => {
    const search = quickTimeOption.filter((tm) =>
      tm.label.includes(e.target.value)
    );
    setTimeoptions(search);
  };
  const dispatch = useDispatch();
  const onApply = () => {
    dispatch(setDashboardFilter({ startDate, endDate }));
    setOpen(false);
  };
  const onSelectQuickTimer = (value) => {
    dispatch(
      setDashboardFilter({
        xAxis: value?.durationType,
        minuteAgo: value?.value,
      })
    );
    setStartDate("");
    setEndDate("");
    setOpen(false);
  };

  const handleClear = () => {
    dispatch(setDashboardFilter({}));
    dispatch(setZoomCount(1));
    setEndDate("");
    setStartDate("");
  };

  const handleRefresh = () => {
    dispatch(setDashboardFilter({ ...filters, key: new Date() }));
  };

  const handleZoomOut = () => {
    dispatch(setZoomCount(zoomCount + 1));
  };

  const currentSelectTime = () => {
    if (filters?.startDate) {
      const startDate = moment.isMoment(filters?.startDate)
        ? filters?.startDate
          ?.subtract(zoomCount - 1, "day")
          .startOf("day")
          .format("YYYY/MM/DD : HH:mm")
        : moment(filters?.startDate)
          ?.subtract(zoomCount - 1, "day")
          .startOf("day")
          .format("YYYY/MM/DD : HH:mm");
      const endDate = moment.isMoment(filters?.endDate)
        ? filters?.endDate?.endOf("day").format("YYYY/MM/DD : HH:mm")
        : moment(filters?.endDate)?.endOf("day").format("YYYY/MM/DD : HH:mm");
      return `${startDate} - ${endDate}`;
    } else if (filters?.minuteAgo) {
      const startDate = moment()
        .subtract(filters?.minuteAgo + (zoomCount - 1), filters?.xAxis)
        .startOf(filters?.xAxis)
        .format("YYYY/MM/DD : HH:mm");
      const endDate = moment()
        .subtract(1, filters?.xAxis)
        .endOf(filters?.xAxis)
        .format("YYYY/MM/DD : HH:mm");
      return `${startDate} - ${endDate}`;
    } else {
      return "Select Time";
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timeInputRef.current &&
        !timeInputRef.current.contains(event.target)
      ) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  return (
    <div className="d-flex align-items-center">
      {(filters?.startDate || filters?.minuteAgo) && (
        <div
          className="mr-2"
          style={{ background: "red", fontSize: "19px", color: "#fff", padding: "0px 5px", borderRadius: "5px", cursor: "pointer" }}
          onClick={handleClear}
        >
          <BiX style={{ marginBottom: "4px" }} />
        </div>
      )}
      <div ref={ref} className="d-flex">
        <Dropdown
          overlayClassName="time-dropdown"
          open={open}
          getPopupContainer={(triggerNode) => triggerNode}
          menu={{
            items: [
              {
                key: "0",
                label: (
                  <div
                    style={{
                      width: "500px",
                    }}
                  >
                    <div
                      className="d-flex justify-content-end mb-3"
                      style={{ fontSize: "18px" }}
                    >
                      <Modal.Header
                        className="text-right"
                        closeButton
                        onClick={() => setOpen(false)}
                      />
                    </div>
                    <Row>
                      <Col xs={12} md={7}>
                        <h6 className="mb-3">Absolute Time Range</h6>
                        <div className="mb-2 time-input">
                          <label className="fw-bold">From</label>
                          <div ref={timeInputRef}>
                            <DatePicker
                              onChange={(value) => setStartDate(value)}
                              value={startDate ? startDate : ""}
                              disabledDate={(current) =>
                                current && current.valueOf() > new Date()
                              }
                              size="large"
                              className="w-100"
                              getPopupContainer={(triggerNode) => triggerNode}
                            />
                          </div>
                        </div>
                        <div className="mb-4 time-input">
                          <label className="fw-bold">To</label>
                          <div ref={timeInputRef}>
                            <DatePicker
                              disabled={!startDate}
                              value={endDate ? endDate : ""}
                              onChange={(value) => setEndDate(value)}
                              disabledDate={(current) =>
                                current &&
                                (current.valueOf() < startDate ||
                                  current.valueOf() > new Date())
                              }
                              getPopupContainer={(triggerNode) => triggerNode}
                              size="large"
                              className="w-100"
                            />
                          </div>
                        </div>
                        <button
                          disabled={!startDate || !endDate}
                          onClick={onApply}
                          className="btn btn-success"
                        >
                          Apply
                        </button>
                      </Col>
                      <Col
                        xs={12}
                        md={5}
                        style={{ borderLeft: "1px solid lightgray" }}
                      >
                        <div className="time-input">
                          <Input
                            size="middle"
                            placeholder="Search quick ranges"
                            prefix={<BiSearch />}
                            onChange={onSerchTimeOption}
                            className="w-100"
                          />
                        </div>
                        <div className="my-3 quick-time-option">
                          {timeOptions.map((time) => (
                            <p
                              style={{ color: "#2c2c2c", fontWeight: "500" }}
                              onClick={() => onSelectQuickTimer(time)}
                              key={time?.label}
                            >
                              {time?.label}
                            </p>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                ),
              },
            ],
          }}
          trigger={["click"]}
        >
          <div
            className="d-flex align-items-center"
            style={{
              background: "lightgray",
              padding: "3px 10px",
              border: "1px solid lightgray",
              fontSize: "14px",
              color: "#000",
            }}
            onClick={() => setOpen(!open)}
          >
            <FiClock className="mr-2" fontSize="14px" />
            <span className="mr-2">{currentSelectTime()}</span>
          </div>
        </Dropdown>
        <button
          style={{
            background: "lightgray",
            // padding: "3px 10px",
            border: "none",
            color: "#000",
          }}
          onClick={handleZoomOut}
        >
          <BiZoomOut fontSize="18px" />
        </button>
      </div>

      <button
        className="ml-3"
        style={{
          background: "lightgray",
          padding: "2px 10px",
          border: "1px solid lightgray",
          color: "#2c2c2c",
        }}
        onClick={handleRefresh}
      >
        <BiRefresh fontSize="20px" />
      </button>
    </div>
  );
};

const quickTimeOption = [
  { label: "Last 5 minutes", value: 5, durationType: "minute" },
  { label: "Last 15 minutes", value: 15, durationType: "minute" },
  { label: "Last 30 minutes", value: 30, durationType: "minute" },
  { label: "Last 1 hours", value: 1, durationType: "hour" },
  { label: "Last 3 hours", value: 3, durationType: "hour" },
  { label: "Last 6 hours", value: 6, durationType: "hour" },
  { label: "Last 12 hours", value: 12, durationType: "hour" },
  { label: "Last 24 hours", value: 24, durationType: "hour" },
  { label: "Last 2 days", value: 2, durationType: "day" },
  { label: "Last 3 days", value: 3, durationType: "day" },
  { label: "Last 7 days", value: 7, durationType: "day" },
  { label: "Last 30 days", value: 30, durationType: "day" },
  { label: "Last 3 months", value: 3, durationType: "month" },
  { label: "Last 6 months", value: 6, durationType: "month" },
  { label: "Last 12 months", value: 12, durationType: "month" },
];
