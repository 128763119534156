import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import { api, endpoint } from "../../api";
import moment from "moment";

const LayoutCounter = ({ data, mainDashboard }) => {
  const { filters, zoomCount } = useSelector((state) => state.dashboard);
  const { minuteAgo, startDate, endDate, key, xAxis } = filters;
  const { chartTitle, locations, unit, titleFontSize, valueFontSize } = data;
  const [value, setValue] = useState({});

  const getDial = async () => {
    let params = {
      device: locations[0].device,
      startDate: ""
    };
    if (mainDashboard) {
      params = {
        ...params,
        startDate:
          startDate && moment(startDate)?.subtract(zoomCount, "day").toDate(),
        endDate: endDate && moment(endDate).toDate(),
        minuteAgo: minuteAgo,
        xAxis
      };
    }

    const { result, success } = await api.get(
      endpoint.dial + "/" + locations[0].yAxis,
      {
        params
      }
    );
    if (success) {
      // console.log({ result, locations });
      setValue(result);
    }
  };

  useEffect(() => {
    getDial();
    const interval = setInterval(() => {
      getDial();
    }, 30000);
    return () => clearInterval(interval);
  }, [locations, minuteAgo, startDate, endDate, key, zoomCount]);

  return (
    <div className="p-2 w-100">
      <div className="border text-center bg-success text-white px-2 py-3">
        <h3 style={{ fontSize: titleFontSize ? `${titleFontSize}pt` : "14pt" }}>
          {chartTitle}
        </h3>
        <h3 style={{ fontSize: valueFontSize ? `${valueFontSize}pt` : "14pt" }}>
          {value?.value ? Number(value.value).toFixed(2) : 0}{" "}
          <strong>{unit}</strong>{" "}
        </h3>
      </div>
    </div>
  );
};

export default LayoutCounter;
