import { Spin } from "antd";
// import chroma from "chroma-js";
import React, { useEffect, useRef } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import CanvasJS from "../../assets/canvasjs.min";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SplineChart = ({
  title,
  subtitle,
  data = [],
  isLoading = false,
  yAxis,
  xAxis,
  stripLines,
  showAlarmedDatapoints = false,
}) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  // useEffect(() => {
  //   const chartContainer = chartContainerRef.current;

  //   const handleResize = () => {
  //     if (chartRef.current) {
  //       chartRef.current.render();
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);

  //   // Cleanup on component unmount
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [chartContainerRef]);

  let isDark = false;
  const getDotSpacification = (t) => {
    if (showAlarmedDatapoints && t.z) {
      return {
        markerSize: t.z === "normal" ? 0 : 7,
        markerColor: t.z === "normal" ? "transparent" : "red",
        markerType: t.z === "normal" ? "none" : "circle",
      };
    }
    return {
      markerSize: 0,
      markerColor: "transparent",
      markerType: "none",
    };
  };

  return (
    <Spin spinning={isLoading}>
      <div
        ref={chartContainerRef}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <CanvasJSChart
          options={{
            zoomEnabled: true,
            theme: isDark ? "dark1" : "light1",
            animationEnabled: false,
            title: {
              text: title,
            },
            subtitles: [
              {
                text: subtitle,
              },
            ],
            axisY: {
              includeZero: false,
              ...yAxis,
            },
            legend: {
              cursor: "pointer",
              itemclick: function (e) {
                if (
                  typeof e.dataSeries.visible === "undefined" ||
                  e.dataSeries.visible
                ) {
                  e.dataSeries.visible = false;
                } else {
                  e.dataSeries.visible = true;
                }
                e.chart.render();
              },
            },

            toolTip: {
              shared: true,
            },
            colorSet: "greenShades",
            data: [
              ...data.map((d) => ({
                type: "spline",
                name: d.name || d._id,
                xValueFormatString: "DD MMM YYYY, hh:mm TT",
                showInLegend: true,
                dataPoints: (d.data || d.chart).map((t, idx) => {
                  return {
                    x: new Date(t.x),
                    y: t.y,
                    ...getDotSpacification(t),
                  };
                }),
              })),
            ],
            axisY: {
              includeZero: false,
              stripLines: stripLines || [],
            },
          }}
          onRef={(chart) => (chartRef.current = chart)}
        />
      </div>
    </Spin>
  );
};

export default SplineChart;