import React from "react";
import { Col } from "react-bootstrap";
import { numberFormatter } from "../../utils";

export const DashboardCount = ({ count, title, bg, color }) => {
  return (
    <div
      className="w-100 py-4"
      style={{ background: bg, color: color || "#ffffff" }}
    >
      <div
        style={{
          fontSize: "32px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {numberFormatter(count, 0)}
      </div>
      <div
        style={{
          fontSize: "14px",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        {title}
      </div>
    </div>
  );
};
