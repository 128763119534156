import { Spin } from "antd";
import React, { useEffect, useRef } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import CanvasJS from "../../assets/canvasjs.min";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SplineChartSubstation = ({
  title,
  subtitle,
  data = [],
  isLoading = false,
  yAxis,
  xAxis,
  baseload = 0,
}) => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);

  // useEffect(() => {
  //   const chartContainer = chartContainerRef.current;

  //   const updateChartSize = () => {
  //     if (chartRef.current) {
  //       const containerWidth = chartContainer.offsetWidth;
  //       const containerHeight = chartContainer.offsetHeight;

  //       // Update chart size dynamically
  //       chartRef.current.options.width = containerWidth;
  //       chartRef.current.options.height = containerHeight;
  //       chartRef.current.render();
  //     }
  //   };

  //   // Set initial chart size
  //   updateChartSize();

  //   // const resizeObserver = new ResizeObserver(updateChartSize);

  //   // // Start observing the chart container
  //   // resizeObserver.observe(chartContainer);

  //   // // Cleanup on component unmount
  //   // return () => {
  //   //   resizeObserver.disconnect();
  //   // };
  // }, [chartContainerRef]);

  let isDark = false;
  // CanvasJS.addColorSet("greenShades", [
  //   "#3f9ffb",
  //   "#3ffbe8",
  //   "#f36a08",
  //   "#3ffb42",
  //   "#fb3f6b",
  // ]);
console.log(data)
  return (
    <Spin spinning={isLoading}>
      <div
        ref={chartContainerRef}
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <CanvasJSChart
          options={{
            zoomEnabled: true,
            theme: isDark ? "dark1" : "light1",
            animationEnabled: false,
            title: {
              text: title,
            },
            subtitles: [
              {
                text: subtitle,
              },
            ],
            axisY: {
              includeZero: false,
              ...yAxis,
            },
            legend: {
              cursor: "pointer",
              itemclick: function (e) {
                if (
                  typeof e.dataSeries.visible === "undefined" ||
                  e.dataSeries.visible
                ) {
                  e.dataSeries.visible = false;
                } else {
                  e.dataSeries.visible = true;
                }
                e.chart.render();
              },
            },

            toolTip: {
              shared: true,
            },
            colorSet: "greenShades",
            data: [
              ...data.map((d) => ({
                type: "splineArea",
                name: d.name || d._id,
                xValueFormatString: "MMM YYYY, hh:mm TT",
                showInLegend: true,
                dataPoints: (d.data || d.chart).map((t) => ({
                  x: new Date(t.x),
                  y: t.y,
                })),
              })),
            ],
          }}
          onRef={(chart) => (chartRef.current = chart)}
        />
      </div>
    </Spin>
  );
};

export default SplineChartSubstation;
