import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import image from '../../image/device1.png';

const DeviceInformation = () => {
  return (
    <div className="az-content-body">
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card  p-3 mb-3">
            <div className="row mt-4">
              <div className="col-md-6">
                <img src={image} alt="device" />
              </div>
              <div className="col-md-6">
                <div>
                  <h5> Device Name (xxxx) </h5>
                  <p>
                    Kuchai Entrepreneurs Park, 58200 Kuala Lampur <br />
                    Federal Territory of Kuala Lampur
                  </p>
                </div>
                <div>
                  <h5>Device Description</h5>
                  <div className="my-2">
                    <p>Device discription will show here. <br /> Description of Asset based on Data to subset</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12">
        <div className="card card-dashboard-six">
          <div className="card-header">
            <div>
              <label className="az-content-label">Sensor List</label>
            </div>
          </div>
          <Table striped className="text-center">
            <thead>
              <tr>
                <th className="fw-bold text-black">No</th>
                <th className="fw-bold text-black">Code</th>
                <th className="fw-bold text-black">Description</th>
                <th className="fw-bold text-black">Location</th>
                <th className="fw-bold text-black">State</th>
                <th className="fw-bold text-black">Status</th>
                <th className="fw-bold text-black">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>PPU1234</td>
                <td>33/2KV</td>
                <td>Kuhai-lama</td>
                <td>Kuala Lampur</td>
                <td>Active</td>
                <Link><Button variant="success" size="sm">
                Details
                </Button></Link>
                <Link><Button variant="primary" size="sm">
                View Graph
                </Button></Link>
              </tr>
              <tr>
                <td>2</td>
                <td>PPU1234</td>
                <td>33/2KV</td>
                <td>Kuhai-lama</td>
                <td>Kuala Lampur</td>
                <td>Active</td>
                <Link><Button variant="success" size="sm">
                Details
                </Button></Link>
                <Link><Button variant="primary" size="sm">
                View Graph
                </Button></Link>
              </tr>
              <tr>
                <td>3</td>
                <td>PPU1234</td>
                <td>33/2KV</td>
                <td>Kuhai-lama</td>
                <td>Kuala Lampur</td>
                <td>Active</td>
                <Link><Button variant="success" size="sm">
                Details
                </Button></Link>
                <Link><Button variant="primary" size="sm">
                View Graph
                </Button></Link>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DeviceInformation;
