import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TiHomeOutline,
  TiDeviceTablet,
  TiChartLine,
  TiCogOutline,
  TiUser,
  TiPower,
  TiBell,
} from "react-icons/ti";
import { BiBuildingHouse } from "react-icons/bi";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveDashboard,
  setIsLogged,
  setUserDetails,
} from "../../redux/slices/user.slice";
import axios from "axios";
import { userRole } from "../../constant";
import { getFormatedUserType } from "../../utils/userTypeFomater";
import appConfig from "../../appConfig";

const NavBar = ({ handle }) => {
  const userDetails = useSelector((state) => state.user?.userDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSubNv, setShowSubNv] = useState("dashboard");

  const logOut = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout.",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`/user/logout`, {
            withCredentials: true,
          })
          .then((res) => {
            dispatch(setActiveDashboard(""));
            dispatch(setIsLogged(false));
            dispatch(setUserDetails({}));
            window.localStorage.clear();
            navigate("/");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  return (
    <div className="az-sidebar">
      <div className="az-sidebar-header my-3">
        <Link to="/" className="az-logo">
          <img
            src={`${appConfig.baseRoute}/img/tnb-logo.png`}
            alt=""
            style={{ height: "80px" }}
          />
        </Link>
      </div>
      <div className="az-sidebar-loggedin">
        <div className="az-img-user online">
          <img src={userDetails?.avatar} alt="" />
        </div>
        <div className="media-body">
          <h6>{userDetails?.name || "John Doe"}</h6>
          {getFormatedUserType(userDetails?.role)}

          {/* <span className="text-capitalize">
            {userDetails?.role === "superAdmin"
              ? "Super Admin"
              : userDetails?.role}
          </span> */}
        </div>
      </div>
      <div className="az-sidebar-body">
        <ul className="nav">
          <li className="nav-label">Main Menu</li>
          <li
            className={`${
              showSubNv === "dashboard" ? "nav-item active" : "nav-item"
            }`}
          >
            <Link to="/" className="nav-link">
              <i>
                <TiHomeOutline />
              </i>
              Home
            </Link>
          </li>

          {(() => {
            switch (userDetails?.role) {
              case userRole.superAdmin:
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "users"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "users"
                            ? setShowSubNv()
                            : setShowSubNv("users")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Manage Users
                      </span>
                      <nav className="nav-sub">
                        <Link to="/users" className="nav-sub-link">
                          All Users
                        </Link>
                        <Link
                          to="/users?role=installer"
                          className="nav-sub-link"
                        >
                          Manage Installer
                        </Link>
                        <Link to="/users?role=admin" className="nav-sub-link">
                          Manage Admins
                        </Link>
                        <Link to="/users?role=user" className="nav-sub-link">
                          Manage Site User
                        </Link>
                        <Link to="/users?role=public" className="nav-sub-link">
                          Manage Site Public
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "siteLocation"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "siteLocation"
                            ? setShowSubNv()
                            : setShowSubNv("siteLocation")
                        }
                      >
                        <i>
                          <BiBuildingHouse />
                        </i>
                        Substations
                      </span>
                      <nav className="nav-sub">
                        <Link to="/substation" className="nav-sub-link">
                          All Substations
                        </Link>
                        <Link to="/substation/create" className="nav-sub-link">
                          Add New Substation
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <TiDeviceTablet />
                        </i>
                        Sensors
                      </span>
                      <nav className="nav-sub">
                        <Link to="/sensor" className="nav-sub-link">
                          All Sensors
                        </Link>
                        <Link to="/device-types" className="nav-sub-link">
                          Manage Sensor Types
                        </Link>
                        <Link to="/switchgear-types" className="nav-sub-link">
                          Manage Switchgear Types
                        </Link>
                        <Link to="/panel-types" className="nav-sub-link">
                          Manage Panel Types
                        </Link>
                        <Link to="/compartment-types" className="nav-sub-link">
                          Manage Compartment Types
                        </Link>
                        <Link to="/add-sensor" className="nav-sub-link">
                          Add New Sensor
                        </Link>
                        {/* <Link to="/add-virtual-device" className="nav-sub-link">
                          Add Virtual Device
                        </Link> */}
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Sensor Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Sensor Comparison
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                        <Link to="/alarm-history" className="nav-sub-link">
                          Alarm Trigger History
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                        {/* <Link to="/electricity-tariff" className="nav-sub-link">
                          Electricity Tariff
                        </Link> */}
                        <Link to="/parameters" className="nav-sub-link">
                          Manage System Parameters
                        </Link>
                        <Link to="/formulas" className="nav-sub-link">
                          Manage Formulas
                        </Link>
                        {/* <Link
                          to="/building-background-types"
                          className="nav-sub-link"
                        >
                          Building Background Types
                        </Link> */}
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "dashboards"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "dashboards"
                            ? setShowSubNv()
                            : setShowSubNv("dashboards")
                        }
                      >
                        <i>
                          <MdOutlineDashboardCustomize />
                        </i>
                        Dashboard
                      </span>
                      <nav className="nav-sub">
                        <Link to="/create-dashboard" className="nav-sub-link">
                          Create New Dashboard
                        </Link>
                        <Link to="/dashboard-list" className="nav-sub-link">
                          Dashboard List
                        </Link>
                        <Link to="/assign-dashboard" className="nav-sub-link">
                          Assign Dashboard
                        </Link>
                      </nav>
                    </li>

                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case userRole.installer:
                return (
                  <>
                    {/* <li
                      className={`${
                        showSubNv === "siteLocation"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "siteLocation"
                            ? setShowSubNv()
                            : setShowSubNv("siteLocation")
                        }
                      >
                        <i>
                          <BiBuildingHouse />
                        </i>
                        Substations
                      </span>
                      <nav className="nav-sub">
                        <Link to="/substation" className="nav-sub-link">
                          All Substations
                        </Link>
                      </nav>
                    </li> */}
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <TiDeviceTablet />
                        </i>
                        Sensor
                      </span>
                      <nav className="nav-sub">
                        <Link to="/sensor" className="nav-sub-link">
                          All Sensor
                        </Link>
                        <Link to="/sensor-types" className="nav-sub-link">
                          Manage Sensor Types
                        </Link>
                        <Link to="/add-sensor" className="nav-sub-link">
                          Add New Sensor
                        </Link>
                        {/* <Link to="/add-virtual-device" className="nav-sub-link">
                          Add Virtual Device
                        </Link> */}
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Sensor Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Sensor Comparison
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                        <Link to="/alarm-history" className="nav-sub-link">
                          Alarm Trigger History
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                        {/* <Link to="/parameters" className="nav-sub-link">
                          Manage System Parameters
                        </Link>
                        <Link to="/formulas" className="nav-sub-link">
                          Manage Formulas
                        </Link> */}
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case userRole.admin:
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "users"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "users"
                            ? setShowSubNv()
                            : setShowSubNv("users")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Manage Users
                      </span>
                      <nav className="nav-sub">
                        <Link to="/users" className="nav-sub-link">
                          All Users
                        </Link>
                        <Link
                          to="/users?role=installer"
                          className="nav-sub-link"
                        >
                          Manage Installer
                        </Link>
                        <Link to="/users?role=user" className="nav-sub-link">
                          Manage Site User
                        </Link>
                        <Link to="/users?role=public" className="nav-sub-link">
                          Manage Site Public
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "siteLocation"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "siteLocation"
                            ? setShowSubNv()
                            : setShowSubNv("siteLocation")
                        }
                      >
                        <i>
                          <BiBuildingHouse />
                        </i>
                        Substations
                      </span>
                      <nav className="nav-sub">
                        <Link to="/substation" className="nav-sub-link">
                          All Substations
                        </Link>
                        <Link to="/substation/create" className="nav-sub-link">
                          Add New Substation
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <TiDeviceTablet />
                        </i>
                        Sensors
                      </span>
                      <nav className="nav-sub">
                        <Link to="/sensor" className="nav-sub-link">
                          All Sensors
                        </Link>
                        <Link to="/sensor-types" className="nav-sub-link">
                          Manage Sensor Types
                        </Link>
                        <Link to="/add-sensor" className="nav-sub-link">
                          Add New Sensor
                        </Link>
                        {/* <Link to="/add-virtual-device" className="nav-sub-link">
                          Add Virtual Device
                        </Link> */}
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Sensor Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Sensor Comparison
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                        <Link to="/alarm-history" className="nav-sub-link">
                          Alarm Trigger History
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case userRole.user:
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "device"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "device"
                            ? setShowSubNv()
                            : setShowSubNv("device")
                        }
                      >
                        <i>
                          <TiDeviceTablet />
                        </i>
                        Sensors
                      </span>
                      <nav className="nav-sub">
                        <Link to="/sensor" className="nav-sub-link">
                          All Sensors
                        </Link>
                        <Link to="/add-sensor" className="nav-sub-link">
                          Add New Sensor
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Sensor Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Sensor Comparison
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "notification"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "notification"
                            ? setShowSubNv()
                            : setShowSubNv("notification")
                        }
                      >
                        <i>
                          <TiBell />
                        </i>
                        Notification
                      </span>
                      <nav className="nav-sub">
                        <Link to="/alarm-summary" className="nav-sub-link">
                          System Alarm Summary
                        </Link>
                        <Link to="/create-alarm" className="nav-sub-link">
                          Create New Alarm
                        </Link>
                        <Link to="/recipient-list" className="nav-sub-link">
                          Notification Recipient List
                        </Link>
                        <Link to="/alarm-history" className="nav-sub-link">
                          Alarm Trigger History
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        to="/profile"
                        className="nav-link"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );
              case userRole.public:
                return (
                  <>
                    <li
                      className={`${
                        showSubNv === "comparison"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "comparison"
                            ? setShowSubNv()
                            : setShowSubNv("comparison")
                        }
                      >
                        <i>
                          <TiChartLine />
                        </i>
                        <Link to="/parameter-comparison" className="nav-link">
                          Analysis &amp; Reporting
                        </Link>
                      </span>
                      <nav className="nav-sub">
                        <Link
                          to="/parameter-comparison"
                          className="nav-sub-link"
                        >
                          Sensor Parameter Comparison
                        </Link>
                        <Link to="/device-comparison" className="nav-sub-link">
                          Multiple Sensor Comparison
                        </Link>
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "settings"
                          ? "nav-item active show"
                          : "nav-item"
                      }`}
                    >
                      <span
                        className="nav-link with-sub"
                        onClick={() =>
                          showSubNv === "settings"
                            ? setShowSubNv()
                            : setShowSubNv("settings")
                        }
                      >
                        <i>
                          <TiCogOutline />
                        </i>
                        Settings
                      </span>
                      <nav className="nav-sub">
                        <Link to="/settings" className="nav-sub-link">
                          Change Password
                        </Link>
                        {/* <Link to="/electricity-tariff" className="nav-sub-link">
                          Electricity Tariff
                        </Link> */}
                      </nav>
                    </li>
                    <li
                      className={`${
                        showSubNv === "profile" ? "nav-item active" : "nav-item"
                      }`}
                    >
                      <Link
                        className="nav-link"
                        to="/profile"
                        onClick={() =>
                          showSubNv === "profile"
                            ? setShowSubNv()
                            : setShowSubNv("profile")
                        }
                      >
                        <i>
                          <TiUser />
                        </i>
                        Profile
                      </Link>
                    </li>
                  </>
                );

              default:
                return "";
            }
          })()}
          <li className="nav-item">
            <button
              className="nav-link border-0 bg-transparent"
              onClick={() => logOut()}
            >
              <i>
                <TiPower />
              </i>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
