import React, { memo, useState } from "react";
import RGL, { WidthProvider, Responsive } from "react-grid-layout";
import LayoutCounter from "../LayoutCharts/LayoutCounter";

import AddItemModal from "../LiveDashboard/AddItemModal";
import RedialChartLayout from "../LayoutCharts/RedialChartLayout";
import ChartViewDemo from "../LayoutCharts/ChartViewDemo";
import LayoutSplitCounter from "../LayoutCharts/LayoutSplitCounter";
import LayoutTextBox from "../LayoutCharts/LayoutTextBox";
import Layout3DTank from "../LayoutCharts/Layout3DTank";
import { useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import ChartFetch from "../HomeCharts/ChartFetch";
import { BiCopy } from "react-icons/bi";
import { Popconfirm } from "antd";
import DialGuegeLayout from "../LayoutCharts/DialGuegeLayout";
import { useEffect } from "react";
import { debounce } from "lodash";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
// const ResponsiveReactGridLayout = WidthProvider(RGL);

const DashboardLayout = memo(
  ({ allData, setAllData, setLayouts, layouts, mode }) => {
    const { userDetails } = useSelector((state) => state.user);
    const [editId, setEditId] = useState("");
    const [dialChangekey, setDialChangeKey] = useState(new Date());
    const [show, setShow] = useState(false);

    const onLayoutChange = (layout, data) => {
      setLayouts(data);
      setDialChangeKey(new Date());
    };
    console.log("layouts", layouts);

    const handlerRemove = (id) => {
      const newData = allData.filter((data) => data._id !== id);
      setAllData(newData);
    };

    const handlerUpdate = (id) => {
      setEditId(id);
      setShow(true);
    };

    const defaultLayout = {
      w: 3, // default width
      h: 3, // default height
      x: 0, // default x position
      y: 0, // default y position
      moved: false,
      static: false,
    };

    return (
      <div>
        <ResponsiveReactGridLayout
          className={`layout ${mode === "view" ? "bg-white client_view" : ""}`}
          id="layout1"
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
          rowHeight={80}
          layouts={layouts}
          onLayoutChange={onLayoutChange}
          // onResize={() => setDialChangeKey(new Date())}
          draggableCancel=".clickbtn"
          isDraggable={mode === "view" ? false : true}
          isResizable={mode === "view" ? false : true}
          verticalCompact={false}
          breakpoints={{ lg: 100, md: 99, sm: 76, xs: 48, xxs: 0 }}
        >
          {allData?.map((item, idx) => (
            <div
              className="border bg-white"
              key={item._id}
              // data-grid={{ w: 3, h: 3, x: 0, y: 0 }}
              data-grid={layouts?.lg?.[idx] || defaultLayout}
            >
              <div className="position-relative h-100 d-flex align-items-center">
                {userDetails.role === "superAdmin" && mode !== "view" ? (
                  <div
                    className="d-flex justify-content-end align-items-center position-absolute"
                    style={{ top: 0, right: 5, zIndex: 10 }}
                  >
                    <div className="clickbtn">
                      <Popconfirm
                        title="Copy Tile?"
                        onCancel={() => {
                          setAllData([
                            ...allData,
                            {
                              ...item,
                              _id: new Date().getTime(),
                              chartTitle: item.chartTitle + " (copy)",
                            },
                          ]);
                        }}
                        okText="No"
                        cancelText="Yes"
                        cancelButtonProps={{ danger: true, type: "primary" }}
                        okButtonProps={{
                          className: "btn-warning popconfirm_warning_btn",
                        }}
                      >
                        <BiCopy className="text-secondary me-2" />
                      </Popconfirm>
                    </div>
                    <div
                      className="clickbtn"
                      onClick={() => handlerUpdate(item._id)}
                    >
                      <FiEdit className="text-secondary me-1" />
                    </div>
                    <div className="clickbtn">
                      <Popconfirm
                        title="Are you sure?"
                        onCancel={() => handlerRemove(item._id)}
                        okText="No"
                        cancelText="Yes"
                        cancelButtonProps={{ danger: true, type: "primary" }}
                        okButtonProps={{
                          className: "btn-secondary popconfirm_cancel_btn",
                        }}
                      >
                        <MdDeleteForever className="fs-5 text-secondary" />
                      </Popconfirm>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {item.component === "bar" ||
                item.component === "line" ||
                item.component === "pie" ? (
                  <div className="p-2 h-100 w-100">
                    <ChartFetch data={item} type={item.component} />
                  </div>
                ) : item.component === "dial" ? (
                  <DialGuegeLayout data={item} dialChangekey={dialChangekey} />
                ) : // <RedialChartLayout data={item}/>
                item.component === "splitCounter" ? (
                  <LayoutSplitCounter data={item} />
                ) : item.component === "textBox" ? (
                  <LayoutTextBox data={item} />
                ) : item.component === "tank" ? (
                  <Layout3DTank data={item} />
                ) : (
                  item.component === "counter" && <LayoutCounter data={item} />
                )}
              </div>
            </div>
          ))}
        </ResponsiveReactGridLayout>
        {show && (
          <AddItemModal
            show={show}
            setShow={setShow}
            allData={allData}
            setAllData={setAllData}
            mode="edit"
            id={editId}
          />
        )}
      </div>
    );
  }
);

export default DashboardLayout;
