import { Spin } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { api } from "../../api";
import appConfig from "../../appConfig";
import { getPhase, getSerializedParameters } from "./DeviceDataByPanel";

export const PanelImageView = ({ site, lineupType, panelType }) => {
  const [compartmentGroup, setCompartmentGroup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getImageData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/dashboard/panel-overview-image-data", {
        params: {
          panelType,
          lineupType,
          site,
        },
      });
      let results = response?.results || [];
      results = results.map((item) => {
        item.devices = getSerializedParameters(item?.devices || []);
        return item;
      });
      setCompartmentGroup(response?.results);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    if (panelType && lineupType && site) getImageData();
    const intervalId = setInterval(() => {
      if (panelType && lineupType && site) getImageData();
    }, 600000);
    return () => clearInterval(intervalId);
  }, [panelType, lineupType, site]);

  return (
    <div className="col-md-8 offset-2 mb-5">
      <Spin spinning={isLoading}>
        <div style={{ position: "relative", width: "80%", margin: "auto" }}>
          <img src={`${appConfig.baseRoute}/img/panel.png`} width={"100%"} />
          {compartments.map(({ fullName, name, ...rest }) => {
            let compartment = compartmentGroup.find((item) =>
              item.compartmentTypeInfo?.name?.toLowerCase()?.includes(name)
            );
            // console.log(compartment);
            return (
              <div
                key={name}
                style={{
                  background: "#fff",
                  padding: "10px",
                  position: "absolute",
                  ...rest,
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                    border: "2px solid #d38904",
                    padding: "5px",
                    marginBottom: "7px",
                    textAlign: "center",
                  }}
                >
                  {fullName}
                </p>
                {compartment?.devices?.map(
                  ({ _id, latestDeviceData, phase }) => (
                    <p key={_id} className="mb-0 font-weight-bold">
                      {latestDeviceData?.name}{" "}
                      {getPhase(phase, latestDeviceData?.name)}:{" "}
                      {latestDeviceData?.value}
                    </p>
                  )
                )}
              </div>
            );
          })}
          <div></div>
        </div>
      </Spin>
    </div>
  );
};
// const getPhase = (phase) => {
//   if (phase === "red")
//     return <span style={{ color: "red", marginRight: "5px" }}>R</span>;
//   if (phase === "yellow")
//     return <span style={{ color: "yellow", marginRight: "5px" }}>Y</span>;
//   if (phase === "blue")
//     return <span style={{ color: "blue", marginRight: "5px" }}>B</span>;
// };
const compartments = [
  {
    fullName: "CB-Top Area",
    name: "top",
    top: "25%",
    left: "5%",
  },
  {
    fullName: "CB-Bottom Area",
    name: "bottom",
    bottom: "5%",
    left: "5%",
  },
  {
    fullName: "Reserve Busbar Area",
    name: "reserve",
    top: "25%",
    right: "5%",
  },
  {
    fullName: "Main Busbar Area",
    name: "main",
    bottom: "5%",
    left: "45%",
  },
  {
    fullName: "Cable Area",
    name: "cable",
    bottom: "13%",
    right: "5%",
  },
];
