import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChangePasswordModal from "./components/ChangePasswordModal";
import UserForm from "./components/UserForm";

const UserEdit = () => {
  const params = useParams();
  const userId = params.userId;

  const [user, setUser] = useState();
  const [modalShow, setModalShow] = useState(false);

  const getUser = async () => {
    axios
      .get(`/user/` + userId, {
        withCredentials: true,
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <UserForm mode="edit" user={user} onClickChangePwd={() => setModalShow(true)} />
      <ChangePasswordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        userId={user?._id}
      />
    </>
  );
};

export default UserEdit;
