export const endpoint = {
  login: "/user/login",
  signup: "/user/register",
  forget_password: "/user/forgot-password",
  all_users: "/user",

  // profile
  update_user_details: "/user/update-details",
  update_user_avater: "/user/update-avatar",
  update_user_password: "/user/me/change-password",

  // device
  get_devices: "/device",
  get_sensor_list: "/device/getSensorList/",
  get_devices_data: "/device/device-data",
  get_device_parameter: "/device-parameter/available-parameter",

  // electricity tariff
  get_electricity_tariff: "/electricity-tariff",
  create_electricity_tariff: "/electricity-tariff",
  delete_electricity_tariff: "/electricity-tariff",
  update_electricity_tariff: "/electricity-tariff",

  //system parameter
  assing_system_parameter: "/device-parameter/assign-system-parameter",
  update_system_parameter1: "/device-parameter/update-system-parameter",
  get_system_parameter_list: "/system-parameter",
  create_system_parameter: "/system-parameter",
  update_system_parameter: "/system-parameter",
  delete_system_parameter: "/system-parameter",

  // building type
  create_building_type: "/building-type",
  update_building_type: "/building-type/",
  get_building_types: "/building-type",
  delete_building_type: "/building-type/",

  // report
  parameter_comparison: "/report/parameter-comparison",
  device_comparison: "/report/device-comparison",

  // dashboard
  dashboard: "/dashboard",
  dashboard_count: "/dashboard/overall-counts",
  dashboard_alarm_count: "/dashboard/alarm-counts",
  create_dashboard: "/dashboard/create",
  update_dashboard: "/dashboard/update",
  get_dashboard_list: "/dashboard/list",
  remove_dashboard: "/dashboard/remove",
  assign_dashboard: "/dashboard/assign",
  get_assign: "/dashboard/getAssigns",

  get_consumption_by_device: "/dashboard/chart/consumption-by-device",
  get_power_consumption: "/dashboard/chart/power-consumption",
  get_energy_consumption: "/dashboard/chart/energy-consumption",
  get_building_energy_index: "/dashboard/chart/building-energy-index",
  update_dashboard_settings: "/dashboard/update-setting",
  get_dashboard_setting: "/dashboard/setting",
  get_all_dashboard_setting: "/dashboard/all-setting",
  get_dashboard_counter: "/dashboard/counter",
  get_dashboard_assinees: "/dashboard/get-assinees",
  assinee_status_update: "/dashboard/update-assinee-status",
  delete_dashboard_assinee: "/dashboard/delete-assinee",
  dashboard_list: "/dashboard/get-dashboard-list",
  assinee_dashboard: "/dashboard/assign",
  dashboard_summary: "/dashboard/summery",

  // chart by building
  get_power_consumption_building: "/dashboard/chart/total-power-by-building",
  get_energy_consumption_building:
    "/dashboard/chart/total-consumption-by-building",
  get_energy_source_percentage: "/dashboard/chart/energy-source-by-percentage",
  active_dashboard: "/dashboard/activeDashboard",

  // site url
  site_url: "/document",

  // notification
  notification: "/notification",
  notification_history: "/notification/history",
  notification_v1: "/notification",

  // get chart data
  get_chart_data: "/dashboard/multi-dashboard/chart-data",

  // dashboard dial
  dial: "/dashboard/dial",
};
