import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Settings() {
  const [ErrorMessageNotMatch, setErrorMessageNotMatch] = useState();
  const [isActive, setIsActive] = useState(false);
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const toggleCurrentPassword = () => {
    setCurrentPasswordShown(!currentPasswordShown);
  };
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const toggleNewPasswordShown = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const toggleRepeatPasswordShown = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };
  const [userData, setUserData] = useState({
    currentPassword: "",
    password: "",
    repeatPassword: "",
  });
  const { currentPassword, password, repeatPassword } = userData;
  const onInputChange = (e) => {
    if (e.target.name === "repeatPassword") {
      setUserData({ ...userData, [e.target.name]: e.target.value });
      if (password === e.target.value) {
        setErrorMessageNotMatch();
        setIsActive(true);
      } else {
        setErrorMessageNotMatch("Both Passwords Are Not Matching");
      }
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };
  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Settings";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to update password?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/user/me/change-password`, userData, { withCredentials: true })
          .then((res) => {
            setUserData({
              currentPassword: "",
              password: "",
              repeatPassword: "",
            });
            Swal.fire({
              title: "Done!",
              text: "Password Updated Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          })
          .catch(function (error) {
            if (error) {
              Swal.fire({
                title: "Cancelled!",
                text: "Current password not matched",
                icon: "error",
                timer: 2000,
                button: false,
              });
            }
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };
  return (
    <div className="az-content-body">
      <div className="row row-sm mg-b-15 mg-sm-b-20 mt-3">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header">
              <div>
                <h6 className="fw-bold mt-0">
                  Settings</h6>
                <span className="d-block py-2">Change Password</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label for="currentPassword" className="form-label">
                      Current Password
                    </label>
                    <div className="input-group">
                      <input
                        type={currentPasswordShown ? "text" : "password"}
                        name="currentPassword"
                        value={currentPassword}
                        minlength="6"
                        onChange={onInputChange}
                        className="form-control"
                        id="currentPassword"
                        placeholder="Enter your current password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {currentPasswordShown ? (
                          <BsEyeSlash onClick={() => toggleCurrentPassword()} />
                        ) : (
                          <BsEye onClick={() => toggleCurrentPassword()} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="password" className="form-label">
                      New Password
                    </label>
                    <div className="input-group">
                      <input
                        type={newPasswordShown ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={onInputChange}
                        minlength="6"
                        className="form-control"
                        id="password"
                        placeholder="Enter a new password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {newPasswordShown ? (
                          <BsEyeSlash
                            onClick={() => toggleNewPasswordShown()}
                          />
                        ) : (
                          <BsEye onClick={() => toggleNewPasswordShown()} />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="repeatPassword" className="form-label">
                      Retype New Password
                    </label>
                    <div className="input-group">
                      <input
                        type={repeatPasswordShown ? "text" : "password"}
                        name="repeatPassword"
                        value={repeatPassword}
                        minlength="6"
                        onChange={onInputChange}
                        className="form-control"
                        id="repeatPassword"
                        placeholder="Retype the new password"
                        required
                      />
                      <span className="input-group-text" id="basic-addon1">
                        {repeatPasswordShown ? (
                          <BsEyeSlash
                            onClick={() => toggleRepeatPasswordShown()}
                          />
                        ) : (
                          <BsEye onClick={() => toggleRepeatPasswordShown()} />
                        )}
                      </span>
                    </div>
                    {ErrorMessageNotMatch && (
                      <div className="alert alert-danger" role="alert">
                        {ErrorMessageNotMatch}
                      </div>
                    )}
                  </div>
                  <div className="float-end">
                    {isActive ? (
                      <button type="submit" className="btn btn-success me-2">
                        Update
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-success me-2"
                        disabled
                      >
                        Update
                      </button>
                    )}
                    <Link to="/" className="btn btn-secondary">
                      Cancel
                    </Link>
                  </div>
                </form>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
