import React, { memo, useEffect, useState } from "react";
import { api, endpoint } from "../../api";
import SplineChart from "../../components/Charts/SplineChart";
import { DatePicker } from "antd";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import NivoChartHome from "../../components/Charts/NivoChartHome";
import NivoLine from "../../components/Charts/NivoLine";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const AlarmGraph = memo(({ data, deviceParameters }) => {
  const [stripLines, setStripLines] = useState([]);
  const {
    device,
    parameter,
    option = "min",
    interval = "1",
    cautionMax,
    cautionMin,
    criticalMax,
    criticalMin,
    highMax,
    highMin,
  } = data;
  console.log(data);
  useEffect(() => {
    let arr = [];

    // if (status === "critical") color = "red";
    // else if (status === "caution") color = "#DEC20B";
    // else if (status === "high") color = "orange";
    // Start
    if (option === "min" || option === "range") {
      if (criticalMin) {
        arr.push({
          value: criticalMin,
          color: "red",
          labelFontColor: "red",
          label: "Critical Start",
          thickness: 2,
          labelMaxWidth: 150,
        });
      }
      if (highMin) {
        arr.push({
          value: highMin,
          color: "orange",
          labelFontColor: "orange",
          label: "High Start",
          thickness: 2,
          labelMaxWidth: 150,
        });
      }
      if (cautionMin) {
        arr.push({
          value: cautionMin,
          color: "#DEC20B",
          labelFontColor: "#DEC20B",
          label: "Caution Start",
          thickness: 2,
          labelMaxWidth: 150,
        });
      }
    }
    //   End
    if (option === "max" || option === "range") {
      if (criticalMax) {
        arr.push({
          value: criticalMax,
          color: "red",
          labelFontColor: "red",
          label: "Critical End",
          thickness: 2,
          labelMaxWidth: 150,
        });
      }
      if (highMax) {
        arr.push({
          value: highMax,
          color: "orange",
          labelFontColor: "orange",
          label: "High End",
          thickness: 2,
          labelMaxWidth: 150,
        });
      }
      if (cautionMax) {
        arr.push({
          value: cautionMax,
          color: "#DEC20B",
          labelFontColor: "#DEC20B",
          label: "Caution End",
          thickness: 2,
          labelMaxWidth: 150,
        });
      }
    }
    setStripLines(arr);
  }, [data]);

  const [parameterName, setParameterName] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log({ parameter, deviceParameters });
    if (parameter) {
      const selected = deviceParameters?.find((p) => p._id === parameter); //problem with edit alarm
      console.log({ selected });
      setParameterName(selected?.name);
    }
  }, [parameter, device]);

  useEffect(() => {
    const body = {
      device,
      //   interval: interval || 1,
        operator: "real",
      parameters: [parameterName],
      from: from || moment().startOf("day").format("YYYY-MM-DD hh:mm"),
      to: to || moment().endOf("day").format("YYYY-MM-DD hh:mm"),
    };
    if (parameterName) {
      setIsLoading(true);
      api
        .post(endpoint.parameter_comparison, body, {
          params: { limit: 10, page: 1, type: "graph" },
        })
        .then((res) => {
          // const data = res.map((d) => ({ id: d._id, data: d.data }));
          // setGraphData(data);
          setGraphData(res);
          setIsLoading(false);
        })
        .catch((err) => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterName, option, interval, to, from]);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  return (
    <div className="mt-4">
      <p className="form-label">
        Live preview
        <span
          className="text-danger"
          style={{ fontFamily: "monospace", marginLeft: 3 }}
        >
          *
        </span>
      </p>
      <Row className="align-items-center">
        <Col md={6}>
          <RangePicker
            disabledDate={disabledDate}
            presets={{ label: "date" }}
            className="w-100"
            format="YYYY-MM-DD"
            onChange={(e, dateString) => {
              setFrom(dateString[0]);
              setTo(dateString[1]);
            }}
            st
          />
        </Col>
        <Col md={6}>
          <div className="text-danger">
            Default time range is last 24 hours from current view
          </div>
        </Col>
      </Row>
      <div
        className={`my-5 ${graphData?.length && "alarm-graph"}`}
        style={{ height: "400px" }}
      >
        {/* <NivoChartHome data={graphData} xAxis="day" /> */}

        {/* <NivoLine
                    title="Parameter Comparison"
                    data={graphData}
                    from={from}
                    to={to}
                    isLoading={isLoading}
                /> */}
        <SplineChart
          data={graphData}
          isLoading={isLoading}
          stripLines={stripLines}
        />
      </div>
    </div>
  );
});

export default AlarmGraph;
