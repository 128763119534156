import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RecipientListTable from "./RecipientListTable";
import { endpoint } from "../../api";
import { Spin } from "antd";

const RecipientList = () => {
  const userDetails = useSelector((state) => state?.user?.userDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [siteLocations, setSiteLocations] = useState([]);
  const [assignedAlarm, setAssignedAlarm] = useState([]);
  const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationsEx, setNotificationsEx] = useState([]);
  const [assignAlerm, setAssignAlarm] = useState({});
  const [AssignAlarmEx, setAssignAlarmEx] = useState({
    isActive: true,
  });

  const onInputChangeEx = (e) => {
    setAssignAlarmEx({ ...AssignAlarmEx, [e.target.name]: e.target.value });
  };
  const onInputChange = (e) => {
    setAssignAlarm({ ...assignAlerm, [e.target.name]: e.target.value });
  };
  const handleUserChange = (e) => {
    let user = users.filter((item) => item._id === e.target.value);
    setAssignAlarm({
      ...assignAlerm,
      user: e.target.value,
      name: user[0]?.name,
      email: user[0]?.email,
    });
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/site-location/my-site`,
        {
          withCredentials: true,
        }
      );
      setSiteLocations(response.data);
    } catch (error) {}
  };
  const getNotifications = async () => {
    axios
      .get(endpoint.notification, {
        params: { site: assignAlerm.site, page: 1, limit: 99999 },
        withCredentials: true,
      })
      .then((res) => setNotifications(res.data?.results));
  };
  useEffect(() => {
    if (assignAlerm.site) {
      getNotifications();
    }
    // eslint-disable-next-line
  }, [assignAlerm.site]);

  const getNotificationsEx = async () => {
    axios
      .get(endpoint.notification, {
        params: { site: AssignAlarmEx.site, page: 1, limit: 99999 },
        withCredentials: true,
      })
      .then((res) => setNotificationsEx(res.data?.results));
  };
  useEffect(() => {
    if (AssignAlarmEx.site) {
      getNotificationsEx();
    }
    // eslint-disable-next-line
  }, [AssignAlarmEx.site]);
  // console.log({ assignAlerm });
  const getUsers = async () => {
    if (!assignAlerm?.site || !assignAlerm?.role) {
      setUsers([]);
      return;
    }
    try {
      const response = await axios.get(
        `/user/siteUser/${assignAlerm.site}/${assignAlerm?.role}`,
        {
          withCredentials: true,
        }
      );
      if (response) {
        setUsers(response.data);
      }
    } catch (error) {
      setUsers([]);
    }
  };
  useEffect(() => {
    if (assignAlerm?.role === "external") {
    } else {
      getUsers();
    }
    // eslint-disable-next-line
  }, [assignAlerm.site, assignAlerm?.role]);

  const getAssignedAlarm = async () => {
    setIsTableLoading(true);
    const response = await axios.get(`/notification/assign`, {
      withCredentials: true,
    });
    if (response) {
      setAssignedAlarm(response.data);
    }
    setIsTableLoading(false);
  };
  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Recipient List";
    getAssignedAlarm();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSiteLocations(userDetails);
    // eslint-disable-next-line
  }, [userDetails]);

  const handleSubmitEx = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await axios.post(
      `/notification/assign`,
      { ...AssignAlarmEx, ...assignAlerm },
      {
        withCredentials: true,
      }
    );
    if (response) {
      setIsLoading(false);
      setSuccessMessage("Alarm Assign Successfully");
      setTimeout(() => {
        setSuccessMessage();
        window.location.reload(false);
      }, 2000);
    }
  };

  return (
    <div className="settings">
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="card p-3 mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <label className="az-content-label">MANAGE NOTIFICATION</label>
                <span className="d-block py-2">
                  Notification Recipient List
                </span>
              </div>
              <div>
                <Link
                  to="/alarm-summary"
                  className="btn btn-secondary float-end"
                >
                  Back
                </Link>
              </div>
            </div>
            <hr className="mt-0" />

            <div className="row">
              <div className="col-md-6">
                {/* <h6>Notification Recipient List</h6> */}
                <div className="d-flex justify-content-center">
                  {isLoading && <Spinner animation="border" variant="dark" />}
                </div>
                {SuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    {SuccessMessage}{" "}
                  </div>
                )}
              </div>
              {/* <div className="col-md-6 ">
                <Link
                  to="/alarm-summary"
                  className="btn btn-secondary float-end"
                >
                  Back
                </Link>
              </div> */}
            </div>
            <div className="row mt-3">
              <div className="col-md-6 border-end">
                <h6 className="fw-bold mt-0">
                  Assign Existing System User Accounts
                </h6>
                <form onSubmit={handleSubmitEx}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="site" className="form-label">
                        Substation
                      </label>
                      <select
                        name="site"
                        id="site"
                        value={assignAlerm?.site}
                        className="form-select"
                        onChange={onInputChange}
                      >
                        <option> Select Substation</option>
                        {siteLocations &&
                          siteLocations.length > 0 &&
                          siteLocations.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="role" className="form-label">
                        User Type
                      </label>
                      <select
                        name="role"
                        id="role"
                        value={assignAlerm?.role}
                        className="form-select"
                        onChange={onInputChange}
                      >
                        <option label="Select Role"></option>
                        <option value="admin">Admin</option>
                        <option value="installer">Installer</option>
                        <option value="user">Site User</option>
                        <option value="public">Site Public</option>
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="user" className="form-label">
                        Site User
                      </label>
                      <select
                        name="user"
                        id="user"
                        className="form-select"
                        onChange={handleUserChange}
                        value={assignAlerm.user}
                      >
                        <option> Select User</option>
                        {users &&
                          users.length > 0 &&
                          users.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <label htmlFor="alarm" className="form-label">
                        Alarm Name
                      </label>
                      <select
                        name="alarm"
                        id="alarm"
                        value={assignAlerm.alarm || ""}
                        className="form-select"
                        onChange={onInputChange}
                      >
                        <option value={""}>Select Alarm</option>
                        {notifications &&
                          notifications.length > 0 &&
                          notifications.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-8">
                      <button
                        type="submit"
                        className="btn btn-success mt-4 float-end"
                        disabled={
                          !assignAlerm?.role ||
                          !assignAlerm?.user ||
                          !assignAlerm?.alarm
                        }
                      >
                        Assign
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <h6 className="fw-bold mt-0">
                  Assign Email Address (External Accounts)
                </h6>
                <form onSubmit={handleSubmitEx}>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="site" className="form-label">
                        Substation
                      </label>
                      <select
                        name="site"
                        id="site"
                        value={AssignAlarmEx?.site}
                        className="form-select"
                        onChange={onInputChangeEx}
                      >
                        <option>Select Substation</option>
                        {siteLocations &&
                          siteLocations.length > 0 &&
                          siteLocations.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="name" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter full name"
                        onChange={onInputChangeEx}
                      />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter email"
                        onChange={onInputChangeEx}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <label htmlFor="alarm" className="form-label">
                        Alarm Name
                      </label>
                      <select
                        name="alarm"
                        id="alarm"
                        value={AssignAlarmEx?.alarm || ""}
                        className="form-select"
                        onChange={onInputChangeEx}
                      >
                        <option value={""}>Select Alarm</option>
                        {notificationsEx &&
                          notificationsEx.length > 0 &&
                          notificationsEx.map((item, index) => (
                            <option value={item._id} key={index}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-8">
                      <button
                        type="submit"
                        className="btn btn-success mt-4 float-end"
                        disabled={
                          !AssignAlarmEx?.site ||
                          !AssignAlarmEx?.name ||
                          !AssignAlarmEx?.email ||
                          !AssignAlarmEx?.alarm
                        }
                      >
                        Assign
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <hr />
            <Spin spinning={isTableLoading}>
              <RecipientListTable
                data={assignedAlarm}
                getAssignedAlarm={getAssignedAlarm}
                siteLocations={siteLocations}
              />
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientList;
