import axios from "axios";
import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import { DataTable } from "../../components";

const DeviceData = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [deviceDetails, setDeviceDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [readings, setReading] = useState([]);
  const [tableColumn, setTableColmn] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const Params = useParams();
  const deviceId = Params.deviceId;
  const deviceType = searchParams.get("deviceType");

  const getDevice = async () => {
    try {
      const response = await axios.get(`/device/` + deviceId, {
        withCredentials: true,
      });
      if (response) {
        setDeviceDetails(response.data?.result);
      }
    } catch (error) {}
  };

  const generateTableHeader = (deviceParameters) => {
    if (tableColumn.length === 0) {
      setTableColmn([
        {
          title: "No.",
          width: "80px",
          render: (_, { sl }) => sl,
          align: "center",
        },
        {
          title: "Time",
          render: (_, row) => moment(row._id).format("DD/MM/YYYY hh:mm:ss a"),
          // width: "200px",
          align: "center",
        },
        ...deviceParameters.map((name) => {
          return {
            title: name,
            render: (row) =>
              row?.[name] ? Number(row?.[name]).toFixed(2) : "--",
            align: "center",
          };
        }),
      ]);
    }
  };

  // getting the table data
  const getDeviceData = async () => {
    setIsLoading(true);
    try {
      let { results, count, deviceParameters } = await api.get(
        endpoint.get_devices_data,
        {
          params: { deviceId, limit, page, sortBy: "date", orderBy: "DESC" },
        }
      );
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });

      generateTableHeader(deviceParameters);

      setReading(results);
      setTotal(count || 0);
    } catch (error) {}
    setIsLoading(false);
  };

  useEffect(() => {
    getDevice();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDeviceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page]);

  return (
    <div className="card p-3 mb-3">
      <div className="row">
        <div className="col-md-6">
          <label class="az-content-label" style={{ fontSize: "15px" }}>
            Meter Data Received
          </label>
          {/* <h3>Meter Data Received</h3> */}
          <p style={{ fontSize: "15px", fontWeight: "500" }}>
            Meter name: {deviceDetails?.name || "--"}
          </p>
        </div>
        <div className="col-md-6">
          <div className="d-flex justify-content-end">
            <button onClick={getDeviceData} className="btn btn-success me-1">
              Refresh
            </button>
            <button onClick={() => navigate(-1)} className="btn btn-secondary">
              Back
            </button>
          </div>
        </div>
      </div>
      <Spin spinning={isLoading}>
        <DataTable
          dataSource={readings}
          columns={tableColumn}
          rowKey={(record) => record.id}
          pagination={{
            defaultPageSize: limit,
            current: page,
            total,
            showSizeChanger: true,
            onShowSizeChange: (_, pageSize) => {
              setLimit(pageSize);
            },
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSizeOptions: [10, 20, 50],
            responsive: true,
          }}
          onChange={(pagination) => {
            setPage(pagination.current);
          }}
        />
      </Spin>
    </div>
  );
};

export default DeviceData;
