import { Spinner } from 'react-bootstrap';

/**
 *
 * @param {{ size: 'md'| 'sm'| 'lg'| 'xl'}} param0
 * @returns
 */
const CustomButton = ({ className = '', size = 'md', loading = false, icon, children, ...props }) => {
  return (
    <button className={`btn btn-primary btn-${size} ${className}`} {...props}>
      {loading && <Spinner size="sm" />}
      {!loading ? icon : ''} {children}
    </button>
  );
};

export default CustomButton;
