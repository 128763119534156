import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MultipleLocation from "../LiveDashboard.old/MultipleLocation";
import axios from "axios";
import { updatecustomSites } from "../../redux/slices/dashboard.slice";
import { BiPlus } from "react-icons/bi";

export const CustomSelector = ({
  selectedTiles,
  handleSelect,
  setSelectedTiles,
  getDashboard,
}) => {
  const dispatch = useDispatch();
  const { currentSiteSelectedTiles, currentSite } = useSelector(
    (state) => state.dashboard
  );
  const initLocation = [
    {
      location: "",
      device: "",
      yAxis: "",
    },
  ];
  const dashboard = useSelector((state) => state.dashboard.currentSite);
  const [multipleLocation, setMultipleLocation] = useState(initLocation);
  const [siteLocations, setSiteLocations] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setSelectedTiles(currentSiteSelectedTiles);
    setMultipleLocation(initLocation);
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocations(
          response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
        );
      }
    } catch (error) {}
  };

  const handleSubmit = () => {
    dispatch(
      updatecustomSites({
        selectedTiles,
        multipleLocation,
        _id: currentSite._id,
      })
    );
    setMultipleLocation(initLocation);
    setShow(false);
    getDashboard();
  };

  const checkForMultipleLocationAvail = () => {
    let count = 0;
    selectedTiles?.map((tileId) => {
      const component = currentSite.items?.find((tile) => tile?._id === tileId);
      if (multipleAvail.includes(component?.component)) {
        count++;
      }
    });
    if (count !== 0 && count === selectedTiles.length) {
      return true;
    } else {
      return false;
    }
  };

 
  const handleOpenModal = () => {
    setShow(true);
    getSiteLocations();
    if(selectedTiles.length){
      let items = [];
      let count=0;
      currentSiteSelectedTiles?.map((tileId) => {
        const component = currentSite.items?.find((tile) => tile?._id === tileId);
        if (multipleAvail.includes(component?.component)) {
          count++;
        }
        if(component){
          items.push(component)
        }
      });
      if(count !== 0 && count === selectedTiles.length){
        setMultipleLocation(items[0]?.locations);
      }
       else if(items.length) {
        setMultipleLocation([{...items[0]?.locations[0]}])
      }
    } else {
      // setMultipleLocation([{...currentSite?.items[0]?.locations[0]}]);
    }
  };

  const checkMultipleLocationEmpty = ()=>{
    let check = false;
    multipleLocation.forEach(element => {
      if(!element?.location){
        check = true
      }
    });
    return check;
  }

  const disabled = (selectedTiles?.length && checkMultipleLocationEmpty());

  return (
    <>
      <button
        style={{
          border: "none",
          padding: "3.8px 10px",
          fontSize: "14px",
          background: "lightgray",
          borderRadius: "2px",
        }}
        onClick={handleOpenModal}
      >
        Custom Selector
      </button>
      <Modal className="mt-5 mt-md-0" show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Row className="gy-3">
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="fw-bold">Tile List</Form.Label>
              </Form.Group>
              <div className="border p-3">
                {dashboard?.items?.map(({ chartTitle, _id }) => (
                  <p
                    style={{
                      background: selectedTiles.includes(_id)
                        ? "#cbc6c6"
                        : "#ffffff",
                      padding: "0 15px",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    key={chartTitle}
                    onClick={() => handleSelect(_id)}
                  >
                    {chartTitle}
                  </p>
                ))}
              </div>
            </Col>
            <Col xs={6}>
              {multipleLocation.map((location, ind) => {
                const props = {
                  ind,
                  location,
                  //   component,
                  siteLocations,
                  multipleLocation,
                  setMultipleLocation,
                };
                return (
                  <React.Fragment key={ind}>
                    <MultipleLocation {...props} />
                  </React.Fragment>
                );
              })}
              {checkForMultipleLocationAvail() ? (
                <Button
                  className="mb-2 py-2 rounded-circle"
                  onClick={() =>
                    setMultipleLocation([...multipleLocation, ...initLocation])
                  }
                >
                  <BiPlus />
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <div className="text-end mt-4">
            <button className="btn btn-success" disabled={disabled} onClick={handleSubmit}>
              Set
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const multipleAvail = ["bar", "line", "pie"];
