import React, { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import axios from "axios";
import Swal from "sweetalert2";
import { Modal, notification, Spin } from "antd";
import { api } from "../../api/request.js";
import { endpoint } from "../../api/endpoint.js";
import Checkbox from "antd/es/checkbox/Checkbox.js";
import { DataTable } from "../../components/index.js";

const DeviceParameters = ({
  isVirtualDevice,
  isLoading,
  data,
  device,
  getDevice,
  getDeviceParameters,
}) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [singleDeviceParameter, setSingleDeviceParameter] = useState({});
  const [parameters, setParameters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setLoading(false);
  };
  const getParameters = async () => {
    try {
      const response = await axios.get(`/system-parameter`, {
        withCredentials: true,
      });
      if (response) {
        setParameters(response.data.results);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getParameters();
  }, []);

  const columns = [
    {
      title: "No.",
      render: (_, __, index) => <div>{(page - 1) * limit + index + 1}</div>,
      // width: "60px",
    },
    {
      title: "Sensor Parameter Name",
      render: (_, row) => <div className="text-capitalize">{row?.name}</div>,
    },
    ...(isVirtualDevice
      ? [
          {
            title: "Device Name",
            render: (_, row) => (
              <div className="text-capitalize">{row?.device?.name}</div>
            ),
          },
        ]
      : []),

    {
      title: "System Parameter Name",
      render: (_, row) => (
        <div className="text-capitalize">
          {row?.systemParameter?.name || "--"}
        </div>
      ),
      //       align: "center",
    },
    // {
    //   title: "Count",
    //   render: (_, row) => row?.count,
    //   align: "center",
    // },
    {
      title: "1 Day Smoothing",
      render: (_, row) => (
        <Checkbox
          disabled={
            row?.name?.includes(" EMA ") ||
            row?.name?.includes(" Smoothing ") ||
            row?.name?.includes(" SMA ")
          }
          checked={row.oneDaySmothing}
          onChange={() =>
            onUpdateSystemParaToDevicePara(
              {
                oneDaySmothing: !row?.oneDaySmothing,
              },
              row._id
            )
          }
        />
      ),
      align: "center",
    },
    {
      title: "7 Day Smoothing",
      render: (_, row) => (
        <Checkbox
          disabled={
            row?.name?.includes(" EMA ") ||
            row?.name?.includes(" Smoothing ") ||
            row?.name?.includes(" SMA ")
          }
          checked={row.sevenDaySmothing}
          onChange={() =>
            onUpdateSystemParaToDevicePara(
              {
                sevenDaySmothing: !row?.sevenDaySmothing,
              },
              row._id
            )
          }
        />
      ),
      align: "center",
    },
    {
      title: "30 Day Smoothing",
      render: (_, row) => (
        <Checkbox
          disabled={
            row?.name?.includes(" EMA ") ||
            row?.name?.includes(" Smoothing ") ||
            row?.name?.includes(" SMA ")
          }
          checked={row.thirtyDaySmothing}
          onChange={() =>
            onUpdateSystemParaToDevicePara(
              {
                thirtyDaySmothing: !row?.thirtyDaySmothing,
              },
              row._id
            )
          }
        />
      ),
      align: "center",
    },
    {
      title: "Action",
      maxWidth: "80px",
      render: (_, row) => (
        <div>
          <button
            className="btn btn-info me-1"
            onClick={() => {
              setIsModalOpen(true);
              setSingleDeviceParameter({
                ...row,
                systemParameterId: row?.systemParameter?._id,
              });
            }}
          >
            <FiEdit />
          </button>
          {/* {device?.parameter === row?._id ? (
            <button className="btn btn-warning">
              <AiFillStar />
            </button>
          ) : (
            <button className="btn btn-warning" onClick={() => setParameter(row?._id)}>
              <BiStar />
            </button>
          )} */}
        </div>
      ),
      align: "center",
    },
  ];

  // const setParameter = async (parameter) => {
  //   let data = {
  //     parameter: parameter,
  //   };
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You want to set this parameter as default?",
  //     //icon: "warning",
  //     dangerMode: true,
  //     showCancelButton: true,
  //     confirmButtonText: "Confirm",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios
  //         .put(`/device/` + device._id, data, {
  //           withCredentials: true,
  //         })
  //         .then((res) => {
  //           getDevice();
  //           getDeviceParameters();
  //           Swal.fire({
  //             title: "Done!",
  //             text: "Set default parameter Successfully",
  //             icon: "success",
  //             timer: 2000,
  //             button: false,
  //           });
  //         });
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //     }
  //   });
  // };

  const onAssingSystemParaToDevicePara = async () => {
    try {
      const { message } = await api.post(endpoint.assing_system_parameter, {
        systemParameterId: singleDeviceParameter?.systemParameterId,
        deviceParameterId: singleDeviceParameter?._id,
      });
      notification.info({
        message,
        placement: "bottomLeft",
      });
      setIsModalOpen(false);
      getDeviceParameters();
    } catch (error) {
      console.log(error?.response);
      notification.warning({
        message: error?.response?.data?.message,
        placement: "bottomLeft",
      });
    }
  };

  const onUpdateSystemParaToDevicePara = async (payload, _id) => {
    try {
      const { message } = await api.put(
        endpoint.update_system_parameter1 + "/" + _id,
        payload
      );
      notification.info({
        message,
        placement: "bottomLeft",
      });
      getDeviceParameters();
    } catch (error) {
      console.log(error?.response);
      notification.warning({
        message: error?.response?.data?.message,
        placement: "bottomLeft",
      });
    }
  };

  return (
    <div>
      <h6 className="fw-bold mb-3">Available Sensor Parameters</h6>
      <Modal
        title={`Assign System Parameter Name - ${singleDeviceParameter?.name}`}
        open={isModalOpen}
        onOk={onAssingSystemParaToDevicePara}
        onCancel={handleCancel}
        okButtonProps={{
          className: "btn btn-success h-auto",
          loading,
        }}
        cancelButtonProps={{ className: "btn btn-secondary h-auto" }}
      >
        <select
          className="form-select"
          name="interval"
          value={singleDeviceParameter?.systemParameterId}
          onChange={(e) =>
            setSingleDeviceParameter({
              ...singleDeviceParameter,
              systemParameterId: e.target.value,
            })
          }
        >
          <option value="">Select system parameter</option>
          {parameters.map(({ _id, name }) => (
            <option value={_id} key={_id}>
              {name}
            </option>
          ))}
        </select>
      </Modal>
      <Spin spinning={isLoading}>
        <DataTable
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: limit,
            current: page,
            // total,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSizeOptions: [10, 20, 50],
            responsive: true,
          }}
          onChange={(pagination) => {
            setLimit(pagination.pageSize);
            setPage(pagination.current);
          }}
        />
      </Spin>
    </div>
  );
};

export default DeviceParameters;
