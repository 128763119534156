import { notification, Spin } from "antd";
import React, { useState } from "react";
import { api, endpoint } from "../../api";

export const CreateSystemParameter = ({ getParameters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [parameterData, setParameterData] = useState({
    name: "",
    type: "",
    value: "",
  });
  const { name, type, value } = parameterData;

  const onInputChange = (e) => {
    setParameterData({ ...parameterData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { message } = await api.post(
        endpoint.create_system_parameter,
        parameterData
      );
      notification.success({ message, placement: "bottomLeft" });
      setParameterData({ name: "", type: "", value: "" });
      getParameters();
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    e.target.reset();
    setIsLoading(false);
    return;

    // const response = await axios.post(
    //   `/parameters`,
    //   parameterData,
    //   { withCredentials: true }
    // );
    // if (response) {
    //   setIsLoading(false);
    //   setParameterData({ name: "", type: "", value: "" });
    //   getParameters();
    //   //   setSuccessMessage("Parameter Created Successfully");
    //   setTimeout(() => {
    //     setSuccessMessage();
    //   }, 2000);
    // }
  };
  return (
    <div className="col-md-4">
      <h6 className="fw-bold">
        Add New System Parameter</h6>
      <Spin spinning={isLoading}>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              System Parameter Name
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onInputChange}
              className="form-control"
              id="name"
              placeholder="Enter system parameter name"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="type" className="form-label">
              System Parameter Type
            </label>
            <select
              name="type"
              id="type"
              className="form-select"
              value={type}
              onChange={onInputChange}
            >
              <option>Select system parameter type</option>
              <option value="Measured Value">Measured Value</option>
              <option>Default Value</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="value" className="form-label">
              Unit / Value
            </label>
            <input
              type="text"
              name="value"
              value={value}
              onChange={onInputChange}
              className="form-control"
              id="value"
              placeholder="Enter Parameter Value"
              required
            />
          </div>
          <div className="float-end">
            <button type="submit" className="btn btn-success me-2">
              Create New Parameter
            </button>
          </div>
        </form>
      </Spin>
    </div>
  );
};
