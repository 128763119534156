import axios from "axios";
import moment from "moment/moment";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FiTrash, FiEye, FiEdit } from "react-icons/fi";
import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";
import { endpoint } from "../../api/endpoint.js";
import { getFormatedUserType } from "../../utils/userTypeFomater.js";

const RecipientListTable = ({ data, siteLocations, getAssignedAlarm }) => {
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [alarmId, setAlarmId] = useState("");
  const [updatedUser, setUpdatedUser] = useState({});
  const [updatedUserEx, setUpdatedUserEx] = useState({});

  const columns = [
    {
      name: "No.",
      cell: (row, index, column, id) => <div>{index + 1}</div>,
      selector: (row) => console.log(row),
      width: "60px",
      center: true,
    },
    {
      name: "User Name",
      cell: (row) => (
        <div className="text-capitalize">{row?.user?.name || row?.name}</div>
      ),
      selector: (row) => row,
      grow: 2,
      minWidth: "120px",
    },
    {
      name: "Email",
      cell: (row) => <div>{row.email}</div>,
      selector: (row) => row,
      grow: 2,
      minWidth: "220px",
    },
    {
      name: "User Type",
      cell: (row) => (
        <div className="text-capitalize text-center">
          {row.role === "external" ? "External" : getFormatedUserType(row.role)}
        </div>
      ),
      selector: (row) => row.role,
      center: true,
      minWidth: "110px",
    },
    {
      name: "Assign Site",
      cell: (row) => <div className="text-wrap">{row?.site?.name}</div>,
      selector: (row) => row?.site?.name,
      // grow: 2,
      minWidth: "110px",
      center: true,
    },
    {
      name: "Alarm",
      cell: (row) => <div className="text-wrap">{row.alarm?.name}</div>,
      grow: 2,
      center: true,
    },
    {
      name: "Date Created",
      cell: (row) => (
        <div className="text-wrap">
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </div>
      ),
      selector: (row) => row,
      minWidth: "130px",
      center: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {row.isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
      selector: (row) => row.isActive,
      minWidth: "100px",
      center: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Link
            to={`/alarm-view/${row?.alarm?._id}`}
            className="btn btn-success  me-1"
          >
            <FiEye title="View" />
          </Link>
          {
            <Link onClick={() => handleShow(row)} className="btn btn-info me-1">
              <FiEdit title="Edit" />
            </Link>
          }

          {(() => {
            switch (row.isActive) {
              case true:
                return (
                  <button
                    className="btn btn-warning  me-1"
                    onClick={() => activeDeactiveUser(row._id, row.isActive)}
                  >
                    <BsPauseFill title="Inactive" />
                  </button>
                );
              case false:
                return (
                  <button
                    className="btn btn-success  me-1"
                    onClick={() => activeDeactiveUser(row._id, row.isActive)}
                  >
                    <BsFillPlayFill title="Active" />
                  </button>
                );
              default:
                return;
            }
          })()}
          <button
            className="btn btn-danger"
            onClick={() => deleteAlarm(row?._id)}
          >
            <FiTrash title="Delete" />
          </button>
        </div>
      ),
      minWidth: "230px",
      center: true,
    },
  ];
  const activeDeactiveUser = async (alarmId, isActive) => {
    const data = {
      isActive: !isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change Alarm status?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/notification/assign/` + alarmId,
            data,
            { withCredentials: true }
          )
          .then((res) => {
            getAssignedAlarm();
            Swal.fire({
              title: "Done!",
              text: "Alarm Status Successfully Changed",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteAlarm = async (alarmId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Alarm?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/notification/assign/` + alarmId,
            { withCredentials: true }
          )
          .then((res) => {
            getAssignedAlarm();
            Swal.fire({
              title: "Done!",
              text: "Alarm Deleted Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const handleClose = () => {
    setShow(false);
    setNotifications([]);
    setAlarmId("");
    setUpdatedUser({});
    setUpdatedUserEx({});
  };

  const handleShow = (row) => {
    setShow(true);
    getNotifications(row?.site?._id);
    setUpdatedUser(row);
    setUpdatedUserEx(row);
    setAlarmId(row?.alarm?._id);
  };
  console.log("5555555555555555=> ", updatedUserEx);
  const getNotifications = async (id) => {
    axios
      .get(endpoint.notification, {
        params: { site: id, page: 1, limit: 99999 },
        withCredentials: true,
      })
      .then((res) => {
        setNotifications(res.data?.results);
        if (res.data?.results?.length <= 0) {
          setAlarmId("");
        }
      });
  };

  const updateAlarmHandler = (e) => {
    e.preventDefault();

    const update =
      updatedUser.role === "external"
        ? { ...updatedUserEx, alarm: alarmId }
        : { alarm: alarmId };

    axios
      .put(`${endpoint.notification}/assign/${updatedUser._id}`, update, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          getAssignedAlarm();
        }
      })
      .catch((err) => console.log(err));
    handleClose();
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 20, 50]}
      />

      <Modal centered show={show} onHide={handleClose}>
        <form onSubmit={updateAlarmHandler}>
          <Modal.Header closeButton>
            <Modal.Title>Update Alarm</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {updatedUser && updatedUser?.role === "external" ? (
              <div>
                <div className="form-group mb-2">
                  <label htmlFor="site" className="form-label">
                    Substation
                  </label>
                  <select
                    name="site"
                    id="site"
                    value={updatedUserEx?.site?._id}
                    className="form-select"
                    onChange={(e) => {
                      setUpdatedUserEx({
                        ...updatedUserEx,
                        site: e.target.value,
                      });
                      getNotifications(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select Substation
                    </option>
                    {siteLocations &&
                      siteLocations.length > 0 &&
                      siteLocations.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="name" className="form-label">
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Enter Full Name"
                    value={updatedUserEx.name}
                    onChange={(e) =>
                      setUpdatedUserEx({
                        ...updatedUserEx,
                        name: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Enter Email Address"
                    value={updatedUserEx.email}
                    onChange={(e) =>
                      setUpdatedUserEx({
                        ...updatedUserEx,
                        email: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="alarm" className="form-label">
                    Alarm Name
                  </label>
                  <select
                    name="alarm"
                    id="alarm"
                    className="form-select"
                    value={alarmId}
                    onChange={(e) => setAlarmId(e.target.value)}
                  >
                    <option value="">Select Alarm</option>
                    {notifications &&
                      notifications.length > 0 &&
                      notifications.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            ) : (
              <div>
                <label htmlFor="alarm" className="form-label">
                  Alarm Name
                </label>
                <select
                  name="alarm"
                  id="alarm"
                  className="form-select"
                  value={alarmId}
                  onChange={(e) => setAlarmId(e.target.value)}
                >
                  <option value="" selected disabled>
                    Select Alarm
                  </option>
                  {notifications &&
                    notifications.length > 0 &&
                    notifications.map((item, index) => (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button disabled={!alarmId} type="submit" variant="success">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default RecipientListTable;
