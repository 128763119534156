import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AnalysisReporting from './pages/AnalysisReporting/AnalysisReporting';
import DeviceComparison from './pages/AnalysisReporting/DeviceComparison';
import AssignDashboard from './pages/Dashboard/AssignDashboard';
import CreateDashboard from './pages/Dashboard/CreateDashboard';
import Dashboard from './pages/Dashboard/Dashboard';
import DashboardLayout from './pages/Dashboard/DashboardLayout';
import DashboardList from './pages/Dashboard/DashboardList';
import DashboardListView from './pages/Dashboard/DashboardListView';
import DeviceInformation from './pages/Dashboard/DeviceInformation';
import AddDevice from './pages/Devices/AddDevice';
import DeviceData from './pages/Devices/DeviceData';
import EditDevice from './pages/Devices/EditDevice';
import AlarmSummary from './pages/Notifications/AlarmSummary';
import AlarmView from './pages/Notifications/AlarmView';
import CreateAlarm from './pages/Notifications/CreateAlarm';
import EditAlarm from './pages/Notifications/EditAlarm';
import RecipientList from './pages/Notifications/RecipientList';
import EditProfile from './pages/Profile/EditProfile';
import Profile from './pages/Profile/Profile';
import DashboardSettings from './pages/Settings/DashboardSettings';
import ElectricityTariff from './pages/Settings/ElectricityTariff';
import ManageFormulas from './pages/Settings/Formula/ManageFormulas';
import Parameters from './pages/Settings/Parameters/Parameters';
import Settings from './pages/Settings/Settings';
import AddSiteLocation from './pages/SiteLocations/AddSiteLocation';
import EditSiteLocation from './pages/SiteLocations/EditSiteLocation';
import SiteDocuments from './pages/SiteLocations/SiteDocuments';
import SiteLocationView from './pages/SiteLocations/SiteLocationView';
import UserCreate from './pages/Users/UserCreate';
import UserDetails from './pages/Users/UserDetails';
import UserEdit from './pages/Users/UserEdit';
import SiteLocationsList from './pages/SiteLocations/SiteLocationsList';
import UserList from './pages/Users/UserList';
import BuildingBackgroundTypes from './pages/Settings/BuildingTypes/BuildingBackgroundTypes';
import DeviceList from './pages/Devices/DeviceList';
import DeviceDetails from './pages/Devices/DeviceDetails';
import AlarmHistory from './pages/Notifications/AlarmHistory';
import DeviceCSVUpload from './pages/Devices/DeviceCSVUpload';
import EditDashboard from './pages/Dashboard/EditDashboard';
import { CompartmentTypes, DeviceTypes, LineupTypes, PanelTypes } from './pages/Devices';

export default function MyRoutes({ handle }) {
  return (
    <Routes>
      {/* Dashboard Informations Route */}
      <Route path="/dashboard-list/:id" element={<DashboardListView />} />
      <Route path="/edit-dashboard/:id" element={<EditDashboard />} />
      <Route path="/create-dashboard" element={<CreateDashboard />} />
      <Route path="/dashboard-list" element={<DashboardList />} />
      <Route path="/assign-dashboard" element={<AssignDashboard />} />
      <Route path="/liveDashbroad" element={<DashboardLayout />} />
      <Route path="/" element={<Dashboard handle={handle}/>} />

      {/* users route */}
      <Route path="/users" element={<UserList />} />
      <Route path="/users/create" element={<UserCreate />} />
      <Route path="/users/edit/:userId" element={<UserEdit />} />
      <Route path="/users/details/:userId" element={<UserDetails />} />

      {/* Device Informations Route */}
      <Route path="/device-details" element={<DeviceInformation />} />

      <Route path="substation" element={<SiteLocationsList />} />
      <Route path="substation/create" element={<AddSiteLocation />} />
      <Route path="substation/details/:siteLocationId" element={<SiteLocationView />} />
      <Route path="substation/edit/:siteLocationId" element={<EditSiteLocation />} />
      <Route path="substation/document/:siteLocationId" element={<SiteDocuments />} />

      {/* Device related routes */}

      <Route path="sensor" element={<DeviceList />} />
      <Route path="add-sensor" element={<AddDevice />} />
      <Route path="device-csv-upload" element={<DeviceCSVUpload />} />
      <Route path="device-types" element={<DeviceTypes />} />
      <Route path="switchgear-types" element={<LineupTypes />} />
      <Route path="panel-types" element={<PanelTypes />} />
      <Route path="compartment-types" element={<CompartmentTypes />} />
      <Route path="sensor/details/:deviceId" element={<DeviceDetails />} />
      <Route path="sensor/edit/:deviceId" element={<EditDevice />} />
      <Route path="sensor/data/:deviceId" element={<DeviceData />} />

      {/* Analysis &Reporting related routes */}
      <Route path="/parameter-comparison" element={<AnalysisReporting />} />
      <Route path="/device-comparison" element={<DeviceComparison />} />

      {/* Settings related Routes */}
      <Route path="/settings" element={<Settings />} />
      <Route path="/building-background-types" element={<BuildingBackgroundTypes />} />
      <Route path="/electricity-tariff" element={<ElectricityTariff />} />
      <Route path="/dashboard-settings" element={<DashboardSettings />} />
      <Route path="/parameters" element={<Parameters />} />
      <Route path="/formulas" element={<ManageFormulas />} />

      {/* Notification related routes */}
      <Route path="/alarm-summary" element={<AlarmSummary />} />
      <Route path="/alarm-history" element={<AlarmHistory />} />
      <Route path="/create-alarm" element={<CreateAlarm />} />
      <Route path="/recipient-list" element={<RecipientList />} />
      <Route path="/alarm-view/:alarmId" element={<AlarmView />} />
      <Route path="/edit-alarm/:alarmId" element={<EditAlarm />} />

      <Route path="/profile" element={<Profile />} />
      <Route path="/edit-profile" element={<EditProfile />} />
    </Routes>
  );
}
