import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FiEye } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api, endpoint } from "../../api";
import { useSearchQuery } from "../../utils/useSearchQuery";
import MyVerticallyCenteredModal from "../../components/Modals/DeviceDataHistoryModal";
import { useSelector } from "react-redux";
import { userRole } from "../../constant";
import { AiOutlineFundView } from "react-icons/ai";

const SiteLocationView = () => {
  const role = useSearchQuery("role");
  const { userDetails } = useSelector((state) => state.user);

  const { siteLocationId } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [modalId, setModalId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [siteLocationDetails, setSiteLocationDetails] = useState([]);
  const [sensorList, setSensorList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  // get site location
  const getSiteLocation = async () => {
    const response = await axios.get(`/site-location/` + siteLocationId, {
      withCredentials: true,
    });
    if (response) {
      setSiteLocationDetails(response.data);
    }
  };

  // get sensors
  const getSensorList = async () => {
    try {
      setIsLoading(true);
      let { result, count } = await api.get(
        endpoint.get_sensor_list + siteLocationId,
        {
          params: {
            limit,
            page,
            search: search?.trim() || "",
            role: role !== "all" ? role : null,
          },
        }
      );
      setSensorList(result);
      setTotal(count);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();
    getSensorList();
  };

  useEffect(() => {
    getSiteLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getSensorList();
    // eslint-disable-next-line
  }, [page, limit]);

  const columns = [
    {
      name: "No.",
      selector: (row, ind) => ind + 1,
      width: "60px",
    },
    {
      name: "Sensor Name",
      cell: (row) => <p className="m-0">{row?.name || "--"}</p>,
    },
    // {
    //   name: "ApiKey",
    //   cell: (row) => <p className="m-0">{row?.apiKey || "--"}</p>,
    // },
    // {
    //   name: "Sensor Type",
    //   cell: (row) => <p className="m-0">{row?.deviceType?.name || "--"}</p>,
    // },
    {
      name: "ApiKey",
      cell: (row) => row?.apiKey || "--",
    },
    {
      name: "Site Name",
      cell: (row) => <p className="m-0">{row?.site?.name || "--"}</p>,
    },
    {
      name: "Status",
      cell: (row) => (
        <div>
          {row.isActive ? (
            <span className="badge text-bg-success">Online</span>
          ) : (
            <span className="badge text-bg-danger">Offline</span>
          )}
        </div>
      ),
      center: true,
      // width: '100px',
    },
    {
      name: "Action",
      width: "220px",
      center: true,
      cell: (row) => (
        <div>
          <Link
            title="View Sensor"
            to={`/sensor/details/${row._id}`}
            className="btn btn-success me-1"
          >
            <FiEye />
          </Link>
          <Link
            to={`/sensor/data/` + row._id}
            className="btn btn-warning me-1"
          >
            {/* <ImHistory /> */}
            <AiOutlineFundView title="View Sensor Data" />
          </Link>
          {/* <div
            title="View History"
            className="btn btn-info me-1"
            onClick={() => {
              setModalShow(true);
              setModalId(row._id);
            }}
          >
            <ImHistory />
          </div> */}
        </div>
      ),
    },
  ];

  return (
    <div className="az-content-body">
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card  p-3 mb-3">
            <div className="row mt-4 px-3">
              <div>
                <div className="mb-3">
                  <h6 className="fw-bold mb-4" style={{ fontSize: "19px" }}>
                    {" "}
                    Substation: {siteLocationDetails?.name}
                  </h6>
                  {/* <div> {siteLocationDetails?.name} </div> */}
                </div>
                <div className="mb-3">
                  <h6 className="fw-bold mb-0"> Building Name: </h6>
                  <div> {siteLocationDetails?.buildingName} </div>
                </div>
                <div className="mb-3">
                  <h6 className="fw-bold mb-0"> Address: </h6>
                  <div> {siteLocationDetails?.buildingAddress1} </div>
                  <div> {siteLocationDetails?.buildingAddress2} </div>
                  <div>
                    {`${siteLocationDetails?.buildingPostalCode} ${siteLocationDetails?.state}`}
                  </div>
                  <div className="mt-3">
                    Latitude: {siteLocationDetails?.latitude}
                  </div>
                  <div> Longitude: {siteLocationDetails?.longitude}</div>
                </div>
                <div className="mb-3">
                  <h6 className="fw-bold mb-0"> Remark: </h6>
                  <div> {siteLocationDetails?.remark} </div>
                </div>
                <div className="mb-3">
                  <h6 className="fw-bold mb-0"> Contact Person: </h6>
                  <div> Name: {siteLocationDetails?.contactPersonName} </div>
                  <div> Email: {siteLocationDetails?.contactPersonEmail} </div>
                  <div>
                    {" "}
                    Phone: +6{siteLocationDetails?.contactPersonPhone}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              {[userRole.superAdmin, userRole.admin].includes(
                userDetails.role
              ) && (
                <Link
                  to={`/substation/edit/${siteLocationId}`}
                  className="btn btn-success me-2"
                >
                  Edit
                </Link>
              )}
              <button
                className="btn btn-secondary"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card card-dashboard-six">
            <div className="card-header d-flex pb-2">
              <div>
                <label className="az-content-label">Sensor List</label>
              </div>
              <div className="ms-auto">
                <form onSubmit={searchHandler} className="input-group">
                  <input
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    aria-label="search"
                    className="form-control"
                  />
                  <Button variant="success" type="submit">
                    search
                  </Button>
                </form>
              </div>
            </div>

            <Spin spinning={isLoading}>
              <DataTable
                columns={columns}
                data={sensorList}
                pagination
                paginationServer
                noDataComponent={isLoading ? "Loading" : "No record found."}
                className="mt-3"
                paginationTotalRows={total}
                paginationPerPage={limit}
                onChangePage={(data) => {
                  setPage(data);
                }}
                onChangeRowsPerPage={(data) => {
                  setLimit(data);
                  setPage(1);
                }}
                paginationRowsPerPageOptions={[10, 20, 50]}
              />
            </Spin>
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        modalId={modalId}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default SiteLocationView;
