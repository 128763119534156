import React from "react";

const LayoutTextBox = ({ data }) => {
  const { chartTitle, description } = data;

  return (
    <div className="p-2">
      <h2 style={{ fontSize: "16px" }}>{chartTitle}</h2>
      <div dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  );
};

export default LayoutTextBox;
