import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api, endpoint } from "../../api";
import moment from "moment";

const Layout3DTank = ({ data, background, mainDashboard }) => {
  const { filters, zoomCount } = useSelector((state) => state.dashboard);
  const { minuteAgo, startDate, endDate, key, xAxis } = filters;
  const { chartTitle, locations, capacity, unit, baseLine } = data;
  const [value, setValue] = useState(0);

  const getDial = async () => {
    try {
      let params = {
        device: locations[0].device,
        startDate: "",
      };
      if (mainDashboard) {
        params = {
          ...params,
          startDate:
            startDate && moment(startDate)?.subtract(zoomCount, "day").toDate(),
          endDate: endDate && moment(endDate).toDate(),
          minuteAgo: minuteAgo,
          xAxis,
        };
      }

      const { result, success } = await api.get(
        endpoint.dial + "/" + locations[0].yAxis,
        {
          params,
        }
      );
      if (success) {
        setValue(result?.value || 0);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDial();
    const interval = setInterval(() => {
      getDial();
    }, 30000);
    return () => clearInterval(interval);
  }, [locations, minuteAgo, startDate, endDate, key, zoomCount]);

  let capacityOffset = capacity / 4;
  let capacitySteps = [];
  for (let i = 0; i <= capacity; i += capacityOffset) {
    capacitySteps.unshift(
      <p
        className="m-0"
        style={{ fontSize: "13px", color: "black", fontWeight: "400" }}
        key={i}
      >
        {Math.round(i)} {unit}
      </p>
    );
  }
  let fullnessHeight = (100 * Number(value)) / capacity;
  let baseLineHeight = (100 * Number(baseLine)) / capacity;

  return (
    <div className="p-2 h-100  w-100">
      <h2 style={{ textAlign: "center", fontSize: "16px" }}>{chartTitle}</h2>
      <p
        style={{
          fontSize: "14px",
          color: "black",
          fontWeight: "600",
          margin: "15px 0",
        }}
      >
        Capacity: {capacity} {unit}
      </p>
      <div className="d-flex" style={{ height: "calc(100% - 150px)" }}>
        <div
          style={{ width: "80%", marginRight: "10px" }}
          title={Number(fullnessHeight).toFixed(2) + " %"}
        >
          <div className="tank-top-round"></div>
          <div
            id="tank-container"
            style={{
              background: `linear-gradient(to bottom, ${background || "#fff"} ${
                100 - fullnessHeight
              }%, #ED7D31 0)`,
            }}
          >
            <div
              className="tank-middle-round"
              style={{ bottom: `calc(${fullnessHeight}% - 18px)` }}
            ></div>
            <div
              className="baseLine"
              style={{ bottom: `calc(${baseLineHeight}%)` }}
            ></div>
          </div>
        </div>
        <div
          className="d-flex flex-column justify-content-between"
          style={{ marginBottom: "-25px", marginTop: "10px" }}
        >
          {capacitySteps}
        </div>
      </div>
    </div>
  );
};

export default Layout3DTank;
