import { encryptTransform } from "redux-persist-transform-encrypt";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import userSlice from "./slices/user.slice";
import deviceSlice from "./slices/device.slice";
import dashboardSlice from "./slices/dashboard.slice";

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_REDUX_STORAGE_SECRET_KEY,
  onError: function (error) {
    console.log(error);
  },
});

const persistConfig = (key) => ({
  key,
  storage: storage,
  timeout: null,
  transforms: [encryptor],
});

const rootReducers = combineReducers({
  [userSlice.name]: persistReducer(persistConfig(userSlice.name), userSlice.reducer),
  [deviceSlice.name]: persistReducer(persistConfig(deviceSlice.name),deviceSlice.reducer),
  [dashboardSlice.name]: persistReducer(persistConfig(dashboardSlice.name),dashboardSlice.reducer)
});

// const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: rootReducers,
  devTools: process.env.NODE_ENV === "development",
  middleware: [thunk],
});
export const persistor = persistStore(store);
