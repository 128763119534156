import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { setIsLogged, setUserDetails } from "../../redux/slices/user.slice";
import appConfig from "../../appConfig";

export const ResetPassword = () => {
  const Params = useParams();
  const token = Params.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ErrorMessage, setErrorMessage] = useState();
  const [showPasswordBlock, setShowPasswordBlock] = useState(false);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [resetPasswordData, setResetPasswordData] = useState({
    token: token,
    password: "",
    repeatPassword: "",
  });
  const isLogged = useSelector((state) => state.user?.isLogged);
  const resetPasswordToken = {
    token: token,
  };
  const { password, repeatPassword } = resetPasswordData;
  const onInputChange = (e) => {
    setResetPasswordData({
      ...resetPasswordData,
      [e.target.name]: e.target.value,
    });
  };
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
  const toggleNewPasswordShown = () => {
    setNewPasswordShown(!newPasswordShown);
  };
  const toggleRepeatPasswordShown = () => {
    setRepeatPasswordShown(!repeatPasswordShown);
  };

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (password === repeatPassword) {
      const response = await axios
        .put(`/user/reset-password`, resetPasswordData, {
          withCredentials: true,
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            if (error.response.status === 401 || 500) {
              setErrorMessage("Invalid password reset link!");
            }
            console.log(error.response.headers);
          }
        });
      if (response.data) {
        setSuccessMessage("Password reset successfully");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } else {
      setErrorMessage("Both Passwords Are Not Matching");
    }
  };
  const checkTokenValid = async () => {
    const response = await axios
      .post(`/user/reset-password-token`, resetPasswordToken, {
        withCredentials: true,
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          if (error.response.status === 401 || 500) {
            setErrorMessage("Invalid password reset link!");
          }
          console.log(error.response.headers);
        }
      });
    if (response.data) {
      setShowPasswordBlock(true);
    }
  };
  useEffect(() => {
    checkTokenValid();
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    document.title = "TNB Switchgear 2.0";
    if (isLogged) {
      axios
        .get(`/user/logout`, {
          withCredentials: true,
        })
        .then((res) => {
          dispatch(setIsLogged(false));
          dispatch(setUserDetails({}));
          window.localStorage.clear();
        });
    }
  }, [isLogged]);

  let background = `${appConfig.baseRoute}/img/bg.jpg`;
  return (
    <div>
      <div
        className="az-content-wrapper p-0"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div>
          <div className="az-signin-wrapper">
            <div className="az-card-signin" style={{ width: "500px" }}>
              <h1 className="az-logo">
                <img
                  src={`${appConfig.baseRoute}/img/tnb-logo.png`}
                  alt="logo"
                  style={{ height: "80px" }}
                />
              </h1>
              <div className="az-signin-header">
                <h4>Reset Password?</h4>
                {ErrorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {ErrorMessage}{" "}
                  </div>
                )}
                {SuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    {SuccessMessage}{" "}
                  </div>
                )}
                <form
                  onSubmit={SubmitHandler}
                  className={`${showPasswordBlock ? "" : "d-none0"}`}
                >
                  <div className="row mb-3">
                    <label
                      htmlFor="password"
                      className="col-sm-4 col-md-4 col-form-label"
                    >
                      New Password
                    </label>
                    <div className="col-sm-6 col-md-8">
                      <div className="input-group">
                        <input
                          type={newPasswordShown ? "text" : "password"}
                          name="password"
                          value={password}
                          onChange={onInputChange}
                          minLength="6"
                          className="form-control"
                          id="password"
                          placeholder="Enter new password"
                          required
                        />
                        <span className="input-group-text" id="basic-addon1">
                          {newPasswordShown ? (
                            <BsEyeSlash
                              onClick={() => toggleNewPasswordShown()}
                            />
                          ) : (
                            <BsEye onClick={() => toggleNewPasswordShown()} />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label
                      htmlFor="repeatPassword"
                      className="col-sm-4 col-md-4 col-form-label"
                    >
                      Repeat Password
                    </label>
                    <div className="col-sm-6 col-md-8">
                      <div className="input-group">
                        <input
                          type={repeatPasswordShown ? "text" : "password"}
                          name="repeatPassword"
                          value={repeatPassword}
                          minLength="6"
                          onChange={onInputChange}
                          className="form-control"
                          placeholder="Retype Password"
                          required
                        />
                        <span className="input-group-text" id="basic-addon1">
                          {repeatPasswordShown ? (
                            <BsEyeSlash
                              onClick={() => toggleRepeatPasswordShown()}
                            />
                          ) : (
                            <BsEye
                              onClick={() => toggleRepeatPasswordShown()}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 float-end">
                    <button className="btn btn-primary px-5 me-2" type="submit">
                      Submit
                    </button>
                    <Link to="/" className="btn btn-secondary">
                      Cancel
                    </Link>
                  </div>
                </form>
              </div>
              <div className="az-signin-footer text-center mt-2">
                <p>Version 2.1.0</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
