import { notification, Select } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { api, endpoint } from "../../api";
import appConfig from "../../appConfig";

const SiteLocationForm = ({ mode, data }) => {
  const states = [
    "Kuala Lumpur",
    "Putrajaya",
    "Perlis",
    "Kedah",
    "Penang",
    "Perak",
    "Selangor",
    "Negeri Sembilan",
    "Malacca",
    "Johore",
    "Kelantan",
    "Terengganu",
    "Pahang",
    "Sarawak",
    "Sabah",
  ];

  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const [buildingTypes, setBuildingTypes] = useState([]);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [Admins, setAdmins] = useState([]);
  const [Installers, setInstallers] = useState([]);
  // const [ElectricityTariff, setElectricityTariff] = useState([]);
  const [dashboardList, setDashboardList] = useState([]);
  const [siteLocationData, setSiteLocationData] = useState({
    name: "",
    admin: [],
    installer: [],
    buildingName: "",
    buildingAddress1: "",
    buildingAddress2: "",
    buildingPostalCode: "",
    contactPersonName: "",
    contactPersonPhone: "",
    contactPersonEmail: "",
    remark: "",
    latitude: "",
    longitude: "",
    state: "",
    dashboard: "",
    code: "",
  });
  const {
    name,
    admin,
    installer,
    buildingName,
    buildingAddress1,
    buildingAddress2,
    buildingPostalCode,
    contactPersonName,
    contactPersonPhone,
    contactPersonEmail,
    remark,
    dashboard,
    latitude,
    longitude,
    state,
    code,
  } = siteLocationData;

  useEffect(() => {
    if (mode === "edit" && data) {
      setSiteLocationData({
        name: data?.name,
        admin: Array.isArray(data?.admin) ? data?.admin?.map((d) => d._id) : [],
        installer: Array.isArray(data?.installer)
          ? data?.installer?.map((d) => d._id)
          : [],
        buildingName: data?.buildingName,
        buildingAddress1: data?.buildingAddress1,
        buildingAddress2: data?.buildingAddress2,
        buildingPostalCode: data?.buildingPostalCode,
        contactPersonName: data?.contactPersonName,
        contactPersonPhone: data?.contactPersonPhone,
        contactPersonEmail: data?.contactPersonEmail,
        remark: data?.remark,
        latitude: data.latitude,
        longitude: data.longitude,
        state: data?.state,
        dashboard: data?.dashboard,
        code: data?.code,
      });
    }
  }, [mode, data]);

  const onInputChange = (e) => {
    setSiteLocationData({
      ...siteLocationData,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectChange = (values) => {
    setSiteLocationData({
      ...siteLocationData,
      ...values,
    });
  };

  const getAdmins = async () => {
    const response = await axios.get(`/user/role/admin`, {
      withCredentials: true,
    });
    if (response) {
      setAdmins(response.data);
    }
  };

  const getDashboardList = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const response = await api.get(endpoint.get_dashboard_list, {
        params: {
          limit: 1000,
          page: 1,
        },
      });
      setDashboardList(response.data || []);
      setIsLoading(false);
    }
  };
  const getInstaller = async () => {
    const response = await axios.get(`/user/role/installer`, {
      withCredentials: true,
    });
    if (response) {
      setInstallers(response.data);
    }
  };

  useEffect(() => {
    document.title =
      mode === "edit"
        ? "TNB Switchgear 2.0 - Edit Site Location"
        : "TNB Switchgear 2.0 - Add New Site Location";
    getInstaller();
    getAdmins();
    getDashboardList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userDetails.role === "admin") {
      setSiteLocationData({ ...siteLocationData, admin: userDetails?._id });
    }
    // eslint-disable-next-line
  }, [userDetails]);

  const handleCreate = async () => {
    setIsLoading(true);
    siteLocationData.latitude = Number(siteLocationData.latitude);
    siteLocationData.longitude = Number(siteLocationData.longitude);
    try {
      const response = await axios.post(`/site-location`, siteLocationData, {
        withCredentials: true,
      });
      const data = response.data;
      if (data) {
        setSuccessMessage("Site Location Created Successfully");
        setTimeout(() => {
          setSuccessMessage();
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      notification.warning({
        placement: "bottomLeft",
        message: error?.response?.data?.msg || "Something went wrong.",
      });
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      siteLocationData.latitude = Number(siteLocationData.latitude);
      siteLocationData.longitude = Number(siteLocationData.longitude);
      const response = await axios.put(
        `/site-location/` + data?._id,
        siteLocationData,
        {
          withCredentials: true,
        }
      );
      if (response.data) {
        setIsLoading(false);
        setSuccessMessage("Site Location Edited Successfully");
        setTimeout(() => {
          setSuccessMessage();
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      notification.warning({
        placement: "bottomLeft",
        message: error?.response?.data?.msg || "Something went wrong.",
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (mode === "edit") {
      handleUpdate();
    } else {
      handleCreate();
    }
  };
  return (
    <div className="card p-3 m-3">
      <div>
        <label className="az-content-label">MANAGE SUBSTATIONS</label>
        <span className="d-block py-2">
          {mode === "edit" ? "Edit" : "Add New"} Substation
        </span>
      </div>
      <hr className="mt-0 mb-4" />

      <div className="d-flex justify-content-center">
        {isLoading && <Spinner animation="border" variant="dark" />}
      </div>
      {SuccessMessage && (
        <div className="alert alert-success" role="alert">
          {SuccessMessage}
        </div>
      )}
      <form onSubmit={submitHandler}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="name" className="form-label">
              Substation Name
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onInputChange}
              className="form-control"
              id="name"
              placeholder="Enter full name"
              required
            />
          </div>
          {userDetails.role === "admin" ? (
            ""
          ) : (
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">
                Admin
              </label>
              <Select
                suffixIcon={
                  <img
                    src={`${appConfig.baseRoute}/img/arrow.svg`}
                    style={{ width: "12px", marginRight: "2px" }}
                  />
                }
                showArrow={true}
                showSearch={true}
                style={{ width: "100%" }}
                size="large"
                mode="multiple"
                id="admin"
                name="admin"
                value={admin}
                onChange={(value) => onSelectChange({ admin: value })}
                placeholder="Select Single / Multiple Admin"
                options={[
                  ...Admins.map((item) => ({
                    label: item.name,
                    value: item._id,
                  })),
                ]}
                required
              />
            </div>
          )}
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="installer" className="form-label">
              Assign Installer
              {/* <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span> */}
            </label>
            <Select
              suffixIcon={
                <img
                  src={`${appConfig.baseRoute}/img/arrow.svg`}
                  style={{ width: "12px", marginRight: "2px" }}
                />
              }
              showArrow={true}
              showSearch={true}
              style={{ width: "100%" }}
              size="large"
              mode="multiple"
              id="installer"
              name="installer"
              value={installer}
              onChange={(value) => onSelectChange({ installer: value })}
              placeholder="Select Single / Multiple Installer"
              aria-label="Select admins"
              options={[
                ...Installers.map((item) => ({
                  label: item.name,
                  value: item._id,
                })),
              ]}
              required
            />
          </div>
        </div>
        <h5 className="mt-4">Building Information</h5>
        <div className="row mb-3 gy-3">
          <div className="col-md-6">
            <label htmlFor="bname" className="form-label">
              Building Name
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              name="buildingName"
              value={buildingName}
              onChange={onInputChange}
              className="form-control"
              id="bname"
              placeholder="Enter building name"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="buildingAddress1" className="form-label">
              Building Address Line 1
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              name="buildingAddress1"
              value={buildingAddress1}
              onChange={onInputChange}
              className="form-control"
              id="buildingAddress1"
              placeholder="Enter building address line 1"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="buildingAddress2" className="form-label">
              Building Address Line 2
            </label>
            <input
              type="text"
              name="buildingAddress2"
              value={buildingAddress2}
              onChange={onInputChange}
              className="form-control"
              id="buildingAddress2"
              placeholder="Enter building address line 2"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="buildingPostalCode" className="form-label">
              Building Postal Code
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="number"
              name="buildingPostalCode"
              value={buildingPostalCode}
              onChange={onInputChange}
              className="form-control"
              id="buildingPostalCode"
              placeholder="Enter building postal code"
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              State
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <select
              className="form-select"
              id="state"
              name="state"
              value={state}
              onChange={onInputChange}
              aria-label="Select a state"
              required
            >
              <option value="" disabled>
                Select State
              </option>
              {states.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6">
            <label htmlFor="dashboard" className="form-label">
              Select Dashboard
            </label>
            <select
              showArrow={true}
              showSearch={true}
              className="form-select"
              id="dashboard"
              name="dashboard"
              value={dashboard}
              onChange={onInputChange}
            >
              <option value="">Select a dashboard</option>
              <option value="substationOverview">Substation Overview</option>
              <option value="panelOverview">Panel Overview</option>
              {dashboardList?.length > 0 &&
                dashboardList?.map((item) => (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-md-6">
            <label htmlFor="latitude" className="form-label">
              Building Latitude
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="number"
              name="latitude"
              value={latitude}
              onChange={onInputChange}
              className="form-control"
              id="latitude"
              placeholder="Enter building Latitude"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="longitude" className="form-label">
              Building Longitude
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="number"
              name="longitude"
              value={longitude}
              onChange={onInputChange}
              className="form-control"
              id="longitude"
              placeholder="Enter building Longitude"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="remark" className="form-label">
              Remark
            </label>
            <input
              type="text"
              name="remark"
              value={remark}
              onChange={onInputChange}
              className="form-control"
              id="remark"
              placeholder="Enter remark"
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="code" className="form-label">
              Code
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              name="code"
              value={code}
              onChange={onInputChange}
              className="form-control"
              id="code"
              placeholder="Substation code"
              required
            />
          </div>
        </div>
        <h5 className="mt-4">Contact Person Information</h5>
        <div className="row mb-3 gy-3">
          <div className="col-md-6">
            <label htmlFor="contactPersonName" className="form-label">
              Contact Person Name
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              name="contactPersonName"
              value={contactPersonName}
              onChange={onInputChange}
              className="form-control"
              id="contactPersonName"
              placeholder="Enter contact person name"
              required
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="contactPersonPhone" className="form-label">
              Contact Person Phone
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                +6
              </span>
              <input
                type="number"
                name="contactPersonPhone"
                value={contactPersonPhone}
                onChange={onInputChange}
                className="form-control"
                id="contactPersonPhone"
                placeholder="Enter contact person phone"
                required
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="contactPersonEmail" className="form-label">
              Contact Person Email
              <span
                className="text-danger"
                style={{ fontFamily: "monospace", marginLeft: 3 }}
              >
                *
              </span>
            </label>
            <div className="input-group">
              <input
                type="email"
                name="contactPersonEmail"
                value={contactPersonEmail}
                onChange={onInputChange}
                className="form-control"
                id="contactPersonEmail"
                placeholder="Enter contact person Email"
                required
              />
            </div>
          </div>
        </div>

        <div className="float-end">
          <button type="submit" className="btn btn-success me-2">
            {mode === "edit" ? "Update" : "Create Substation"}
          </button>
          <button onClick={() => navigate(-1)} className="btn btn-secondary">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default SiteLocationForm;
