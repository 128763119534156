import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FiUserCheck, FiUserX, FiTrash, FiEdit, FiEye } from "react-icons/fi";
import { api, endpoint } from "../../api";
import { Spin } from "antd";
import { userRole } from "../../constant";
import { useSearchQuery } from "../../utils/useSearchQuery";
import { getFormatedUserType } from "../../utils/userTypeFomater";
import { DataTable } from "../../components";

const UserList = () => {
  const role = useSearchQuery("role");
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(Number(searchParams.get("limit")) || 10);
  const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [siteLocation, setSiteLocation] = useState([]);
  const [searchByLocation, setSearchByLocation] = useState("");
  const navigate = useNavigate();

  const getUsers = async () => {
    setIsLoading(true);
    try {
      let { results, count } = await api.get(endpoint.all_users, {
        params: {
          limit,
          page,
          sortBy: "createdAt",
          orderBy: "DESC",
          // role: role !== "all" ? role : null,
          site: searchByLocation,
          searchText: searchTerm,
        },
      });
      results = results.map((item, idx) => {
        item.sl = (page - 1) * limit + idx + 1;
        return item;
      });
      setUsers(results);
      setTotal(count || 0);
    } catch (error) {}

    setIsLoading(false);
  };

  const onConfirmActiveDeactiveUser = async (row) => {
    const data = {
      isActive: !row.isActive,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You want to change user status?",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(`user/` + row._id, data, {
            withCredentials: true,
          })
          .then((res) => {
            getUsers();
            Swal.fire({
              title: "Done!",
              text: "User Status Successfully Changed.",
              icon: "success",
              timer: 2000,
              button: false,
            });
          })
          .catch((err) => {});
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteUser = async (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user?",
      //icon: "warning",
      dangerMode: true,
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`user/` + userId, {
            withCredentials: true,
          })
          .then((res) => {
            getUsers();
            Swal.fire({
              title: "Done!",
              text: "User Deleted Successfully",
              icon: "success",
              timer: 2000,
              button: false,
            });
          })
          .catch((err) =>
            Swal.fire({
              title: "",
              text: "User Deleted Failed",
              icon: "error",
              timer: 2000,
              button: false,
            })
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const getSiteLocation = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        setSiteLocation(response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    document.title = "TNB Switchgear 2.0 - Users";
    getUsers();
    getSiteLocation();
    // eslint-disable-next-line
  }, [page, limit, role, searchTerm]);

  const handleLocation = (e) => {
    setSearchByLocation(e.target.value);
  };

  const columns = [
    {
      title: "No.",
      render: (_, { sl }) => sl,
      width: "60px",
    },
    {
      title: "User Name",
      render: (row) => (
        <div className="d-flex my-2 align-items-center">
          <div>
            <img
              src={row.avatar}
              width={40}
              height={40}
              className="me-2 rounded-circle"
              style={{ objectFit: "cover" }}
              alt={`${row.name}`}
            />
          </div>
          <p className="m-0">{row?.name || "--"}</p>
        </div>
      ),
      // maxWidth: "200px",
    },
    {
      title: "Email",
      render: (row) => row.email,
      // maxWidth: "200px",
    },
    {
      title: "User Type",
      render: (row) => getFormatedUserType(row.role),
      selector: (row) => row.role,
      // width: "100px",
      align: "center",
    },
    {
      title: "Date Created",
      render: (_, row) => moment(row.createdAt).format("DD/MM/YYYY"),
      align: "center",
    },
    {
      title: "Last Login",
      render: (row) => (
        <div>
          {row?.lastLogin ? (
            <div>
              {moment(row.lastLogin).format("DD/MM/YYYY")}
              <br />
              {moment(row.lastLogin).format("hh:mm:ss A")}
            </div>
          ) : (
            "--"
          )}
        </div>
      ),
      align: "center",
    },
    {
      title: "Status",
      render: (row) => (
        <div>
          {row.isActive ? (
            <span className="badge text-bg-success">Active</span>
          ) : (
            <span className="badge text-bg-danger">Deactivated</span>
          )}
        </div>
      ),
      align: "center",
      // width: '100px',
    },
    {
      title: "Action",
      width: "220px",
      align: "center",
      render: (row) => (
        <div>
          <Link
            to={`/users/details/${row._id}?role=${role || "all"}`}
            className="btn btn-success me-1"
          >
            <FiEye title="View Profile" />
          </Link>
          <Link
            to={`/users/edit/${row._id}?role=${role || "all"}`}
            className="btn btn-info me-1"
          >
            <FiEdit title="Edit Profile" />
          </Link>
          {row.isActive ? (
            <button
              className="btn btn-warning  me-1"
              onClick={() => onConfirmActiveDeactiveUser(row)}
            >
              <FiUserX title="Inactive Account" />
            </button>
          ) : (
            <button
              className="btn btn-success  me-1"
              onClick={() => onConfirmActiveDeactiveUser(row)}
            >
              <FiUserCheck title="Active Account" />
            </button>
          )}
          <button
            className="btn btn-danger"
            onClick={() => deleteUser(row._id)}
          >
            <FiTrash title="Delete" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setSearchTerm(searchParams.get("s") || "");
    setPage(Number(searchParams.get("page")) || 1);
    setLimit(Number(searchParams.get("limit")) || 10);
  }, [searchParams]);

  return (
    <div className="card p-3 m-3">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <label className="az-content-label">Manage Users</label>
          <span className="d-block py-2">{getHeader(role).title}</span>
        </div>
        <div className="col-md-4 d-flex justify-content-end p-0">
          {role && (
            <Link
              className="btn btn-az-primary btn-md text-capitalize"
              to={`/users/create?role=${role || "public"}`}
            >
              {getHeader(role).btnText || "Add Public User"}
            </Link>
          )}
        </div>
      </div>

      <hr className="mt-0" />
      <div className="row my-3">
        <div className="col-md-3">
          <select className="form-select" onChange={handleLocation}>
            <option label="All Substations"></option>
            {siteLocation &&
              siteLocation.length > 0 &&
              siteLocation.map((item, index) => (
                <option value={item._id} key={index}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="col-md-2">
          <Button onClick={getUsers} variant="success">
            Search
          </Button>
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-3">
          <Form className="d-flex">
            <Form.Control
              value={searchTerm}
              onChange={(e) => {
                navigate(
                  `/users?role=${role || "all"}&page=${1}&limit=${limit}&s=${
                    e.target.value
                  }`
                );
              }}
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
          </Form>
        </div>
      </div>

      <Spin spinning={isLoading}>
        <DataTable
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={users}
          pagination={{
            defaultPageSize: limit,
            current: page,
            total,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
            pageSizeOptions: [10, 20, 50],
            responsive: true,
          }}
          onChange={(pagination) => {
            navigate(
              `/users?role=${role || "all"}&page=${pagination.current}&limit=${
                pagination.pageSize
              }&s=${searchTerm}`
            );
          }}
        />
      </Spin>
    </div>
  );
};

export default UserList;

const getHeader = (role) => {
  switch (role) {
    case userRole.installer:
      return {
        title: "All Installers",
        btnText: "Add Installer",
        btnLink: "/users/add-public-user",
      };
    case userRole.admin:
      return {
        title: "All Admins",
        btnText: "Add Admin",
        btnLink: "/users/add-public-user",
      };
    case userRole.user:
      return {
        title: "All Site Users",
        btnText: "Add Site User",
        btnLink: "/users/add-public-user",
      };
    case userRole.public:
      return {
        title: "All Public Users",
        btnText: "Add Public User",
        btnLink: "/users/add-public-user",
      };
    default:
      return {
        title: "All Users",
        btnLink: "/users/add-public-user",
      };
  }
};
