import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { FiTrash } from "react-icons/fi";
import Select from "react-select";
import { api, endpoint } from "../../api";
import { Select as AntdSelect } from "antd";

export const DeviceAndParameter = ({
  deviceNumber,
  device,
  parameters = [],
  onRemoveDevice,
  onChangeDevice,
  sites,
}) => {
  const [deviceFilter, setDeviceFilter] = useState({
    site: "",
    panelType: "",
    lineupType: "",
    compartmentType: "",
  });
  const [deviceParameters, setDeviceParameters] = useState();
  const [deviceList, setListDevices] = useState([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);
  const [isLoadingParameters, setIsLoadingParameters] = useState(false);
  const [panelList, setPanelList] = useState([]);
  const [lineupList, setLineuplist] = useState([]);
  const [compartmentList, setCompartmentList] = useState([]);
  const [isFilterItemsLoading, setFilterItemLoading] = useState(false);

  const { lineupType, panelType, compartmentType, site } = deviceFilter;

  const getFilterOptionsBySite = async (site) => {
    setFilterItemLoading(true);
    try {
      // selected items
      setDeviceFilter({
        ...deviceFilter,
        site,
        lineupType: "",
        panelType: "",
        compartmentType: "",
      });
      // list
      setDeviceParameters([]);
      setListDevices([]);
      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site,
          },
        }
      );
      setLineuplist(response?.data?.lineupType || []);
      setPanelList(response?.data?.panelType || []);
      setCompartmentList(response?.data?.compartmentType || []);
      resetDeviceAndParameter();
      // setSensorTypes(response?.data?.deviceType || []);
    } catch (error) {
      setLineuplist([]);
      setPanelList([]);
      setCompartmentList([]);
      // setSensorTypes([]);
      console.log(error);
    }
    setFilterItemLoading(false);
  };

  const getFilterOptionsByLineup = async (lineupType) => {
    setFilterItemLoading(true);
    // selected items
    setDeviceFilter({
      ...deviceFilter,
      lineupType,
      panelType: "",
      compartmentType: "",
    });
    // list
    setDeviceParameters([]);
    setListDevices([]);

    try {
      const response = await axios.get(
        `/dashboard/substation-overview-filter`,
        {
          params: {
            site: deviceFilter?.site,
            lineupType,
          },
        }
      );

      setPanelList(response?.data?.panelType || []);
      setCompartmentList(response?.data?.compartmentType || []);
      resetDeviceAndParameter();
    } catch (error) {
      setPanelList([]);
      setCompartmentList([]);
    }
    setFilterItemLoading(false);
  };

  const getDevices = async () => {
    setDeviceParameters([]);
    onChangeDevice({ idx: deviceNumber - 1, value: "", name: "device" });
    onChangeDevice({ idx: deviceNumber - 1, value: [], name: "parameters" });

    setIsLoadingDevices(true);
    try {
      const response = await axios.get(`/device`, {
        withCredentials: true,
        params: { site, lineupType, panelType, compartmentType },
      });
      if (response) {
        setListDevices(response.data?.results);
      }
    } catch (error) {}
    setIsLoadingDevices(false);
  };

  const getDeviceParameters = async (deviceId) => {
    setIsLoadingParameters(true);
    try {
      setDeviceParameters([]);
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
    } catch (error) {}

    setIsLoadingParameters(false);
  };

  const onChangeDev = (deviceId) => {
    // const deviceId = e.target.value;
    getDeviceParameters(deviceId);
    onChangeDevice({ idx: deviceNumber - 1, value: deviceId, name: "device" });
    onChangeDevice({ idx: deviceNumber - 1, value: [], name: "parameters" });
  };
  const onChangeParm = (value, name) => {
    onChangeDevice({
      idx: deviceNumber - 1,
      value: value.map((v) => v.label),
      name: "parameters",
    });
  };
  const resetDeviceAndParameter = () => {
    onChangeDevice({ idx: deviceNumber - 1, value: "", name: "device" });
    onChangeDevice({ idx: deviceNumber - 1, value: [], name: "parameters" });
  };
  const options = useMemo(() => {
    if (deviceParameters) {
      return deviceParameters.map((item) => {
        return { value: item.name, label: item.name };
      });
    } else {
      return [];
    }
  }, [deviceParameters]);

  const parameterValues = useMemo(() => {
    return parameters.map((item) => {
      return { value: item, label: item };
    });
  }, [parameters]);

  useEffect(() => {
    if (site && panelType && lineupType && compartmentType) {
      getDevices();
    }
  }, [site, panelType, lineupType, compartmentType]);

  return (
    <div className="row">
      <div className="col-md-9">
        <div className="row">
          <div className="col-md-4 mt-2">
            <select
              className="form-select"
              name="siteLocations"
              onChange={(e) => {
                getFilterOptionsBySite(e.target.value);
              }}
              aria-label="Select Substation"
              defaultValue={""}
            >
              <option disabled label="Select Substation" value={""}></option>
              {sites?.length &&
                sites.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-4 mt-2">
            <select
              name="lineupType"
              id="lineupType"
              value={lineupType}
              className="form-select"
              onChange={(e) => {
                getFilterOptionsByLineup(e.target.value);
              }}
            >
              <option>Select Switchgear</option>
              {lineupList?.length &&
                lineupList.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-4 mt-2">
            <AntdSelect
              className="w-100"
              value={panelType || null}
              onChange={(value) => {
                setDeviceFilter({
                  ...deviceFilter,
                  panelType: value,
                  compartmentType: "",
                });
                setDeviceParameters([]);
                setListDevices([]);
                resetDeviceAndParameter();
              }}
              placeholder="Select Panel Type"
              size="large"
              showArrow
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.children
                  ? option?.children.toLowerCase()
                  : ""
                ).includes(input?.toLowerCase())
              }
            >
              {panelList?.length > 0 &&
                panelList.map((item) => (
                  <AntdSelect.Option key={item._id}>
                    {item.name}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
            {/* <select
              name="panelType"
              id="panelType"
              value={panelType}
              className="form-select"
              onChange={(e) => {
                setDeviceFilter({
                  ...deviceFilter,
                  panelType: e.target.value,
                  compartmentType: "",
                });
                setDeviceParameters([]);
                setListDevices([]);
                resetDeviceAndParameter();
              }}
            >
              <option>Select Panel Type</option>
              {panelList?.length &&
                panelList.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select> */}
          </div>
          <div className="col-md-4 mt-2">
            <AntdSelect
              className="w-100"
              value={compartmentType || null}
              onChange={(value) => {
                setDeviceFilter({
                  ...deviceFilter,
                  compartmentType: value,
                });
                setDeviceParameters([]);
                setListDevices([]);
                resetDeviceAndParameter();
              }}
              placeholder="Select Compartment Type"
              size="large"
              showArrow
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.children
                  ? option?.children.toLowerCase()
                  : ""
                ).includes(input?.toLowerCase())
              }
            >
              {compartmentList?.length > 0 &&
                compartmentList.map((item) => (
                  <AntdSelect.Option key={item._id}>
                    {item.name}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
            {/* <select
              name="compartmentType"
              id="compartmentType"
              value={compartmentType}
              className="form-select"
              onChange={(e) => {
                setDeviceFilter({
                  ...deviceFilter,
                  compartmentType: e.target.value,
                });
                setDeviceParameters([]);
                setListDevices([]);
                resetDeviceAndParameter();
              }}
            >
              <option> Select Compartment Type</option>
              {compartmentList?.length &&
                compartmentList.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                ))}
            </select> */}
          </div>
          <div className="col-md-4 mt-2">
            <AntdSelect
              loading={isLoadingDevices}
              className="w-100"
              value={device || null}
              onChange={onChangeDev}
              placeholder={`Select Sensor ${deviceNumber}`}
              size="large"
              showArrow
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.children
                  ? option?.children.toLowerCase()
                  : ""
                ).includes(input?.toLowerCase())
              }
            >
              {deviceList?.length > 0 &&
                deviceList.map((item) => (
                  <AntdSelect.Option key={item._id}>
                    {item.name}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
            {/* <select
              className="form-select mb-2"
              name="device"
              value={device || ""}
              onChange={onChangeDev}
            >
              {isLoadingDevices ? (
                <option value="">Loading devices...</option>
              ) : (
                <>
                  <option value={""}>Select Sensor {deviceNumber}</option>
                  {deviceList?.length > 0 &&
                    deviceList.map((device) => {
                      return (
                        <option value={device._id} key={device?._id}>
                          {device.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select> */}
          </div>
          <div className="col-md-4 mt-2">
            <Select
              isMulti
              suffixIcon={
                <img
                  src="/img/arrow.svg"
                  style={{ width: "12px", marginRight: "2px" }}
                />
              }
              // defaultInputValue={parameters.map((p) => ({ label: p, value: p }))}
              onChange={(value) => onChangeParm(value, "parameters")}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              isSearchable={true}
              placeholder="Select Parameters"
              value={parameterValues}
              isLoading={isLoadingParameters}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3 mt-2">
        <button
          className="btn btn-danger"
          onClick={(e) => {
            e.preventDefault();
            onRemoveDevice(deviceNumber);
          }}
        >
          <FiTrash title="Delete Device" />
        </button>
      </div>
    </div>
  );
};
