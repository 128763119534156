import { notification, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";
import { api, endpoint } from "../../api";

export default function EditETariffModal(props) {
  const { ETariffToEdit, getElectricityTariff, setModalShow } = props;
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [electricityTariffData, setElectricityTariffData] = useState({
    name: "",
    description: "",
  });
  const { name, description } = electricityTariffData;
  const handleChange = (value, bodyContent) => {
    setElectricityTariffData({
      ...electricityTariffData,
      [bodyContent]: value,
    });
  };
  const onInputChange = (e) => {
    setElectricityTariffData({
      ...electricityTariffData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { message } = await api.put(
        `${endpoint.update_electricity_tariff}/${ETariffToEdit._id}`,
        electricityTariffData
      );
      notification.success({ message, placement: "bottomLeft" });
      getElectricityTariff();
      setModalShow(false);
    } catch (error) {
      notification.warning({
        message: "Something went wrong.",
        placement: "bottomLeft",
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setElectricityTariffData({
      name: ETariffToEdit?.name,
      description: ETariffToEdit?.description,
    });
  }, [ETariffToEdit]);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="text-center">Update Electricity Tariff</h4>
        <Spin spinning={isLoading}>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Tariff Category
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={onInputChange}
                className="form-control"
                id="name"
                placeholder="Enter a tariff category"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <ReactQuill
                theme="snow"
                className="mb-5"
                id="description"
                name="description"
                value={description}
                onChange={(value) => handleChange(value, "description")}
                style={{ height: "200px" }}
              />
            </div>
            <div className="float-end">
              <button type="submit" className="btn btn-success me-2">
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setModalShow(false)}
              >
                Cancel
              </button>
            </div>
          </form>
        </Spin>
      </Modal.Body>
    </Modal>
  );
}
