import { notification, Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { api, endpoint } from "../../api";

const EditParameterModal = (props) => {
    const { parameterToEdit, getParameters, setModalShow } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [parameterData, setParameterData] = useState({
        name: "",
        type: "",
        value: "",
    });
    const { name, type, value } = parameterData;

    const onInputChange = (e) => {
        setParameterData({ ...parameterData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const { message } = await api.put(
                `${endpoint.update_system_parameter}/${parameterToEdit._id}`,
                parameterData
            );
            notification.success({ message, placement: "bottomLeft" });
            getParameters();
            setModalShow(false);
        } catch (error) {
            notification.warning({
                message: "Something went wrong.",
                placement: "bottomLeft",
            });
        }
        setIsLoading(false);
        return;
    };

    useEffect(() => {
        setParameterData({
            name: parameterToEdit?.name,
            type: parameterToEdit?.type,
            value: parameterToEdit?.value,
        });
    }, [parameterToEdit]);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Spin spinning={isLoading}>
                    <h4 className="">Update System Parameter</h4>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                System Parameter Name
                            </label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                onChange={onInputChange}
                                className="form-control"
                                id="name"
                                placeholder="Enter a parameter name"
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="type" className="form-label">
                                System Parameter Type
                            </label>
                            <select
                                name="type"
                                id="type"
                                className="form-select"
                                value={type}
                                onChange={onInputChange}
                            >
                                <option>Select parameter type</option>
                                <option value="Measured Value">Measured Value</option>
                                <option value="Computation Action">Computation Action</option>
                                <option>Default Value</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="value" className="form-label">
                                Unit / Value
                            </label>
                            <input
                                type="text"
                                name="value"
                                value={value}
                                onChange={onInputChange}
                                className="form-control"
                                id="value"
                                placeholder="Enter a unite/ value"
                                required
                            />
                        </div>
                        <div className="float-end">
                            <Button variant="secondary" className="mr-2" onClick={() => setModalShow(false)}>
                                Cancel
                            </Button>
                            <button type="submit" className="btn btn-success me-2">
                                Update
                            </button>
                        </div>
                    </form>
                </Spin>
            </Modal.Body>
        </Modal>
    );
};

export default EditParameterModal;
