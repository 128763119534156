import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import axios from "axios";
import { groupByCompartment, groupByLineup, groupByPanel } from "../../utils";

export const TriggeredAlarm = () => {
  const [loading, setLoading] = useState(false);
  const [alarmHistory, setAlarmHistory] = useState([]);

  const getAlarmHisotry = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/notification/history`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setAlarmHistory(groupByLineup(data.results));
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getAlarmHisotry();
  }, []);

  return (
    <div className="col-lg-12">
      <Spin spinning={loading}>
        {Object.keys(alarmHistory).map((hist, outerIdx) => {
          const panels = groupByPanel(alarmHistory[hist]);
          const compartment = groupByCompartment(alarmHistory[hist]);
          return (
            <div key={outerIdx} className="alarm-history-table d-flex mb-4">
              <div>
                <div className="c-table-first-column">
                  <div className="header-one">Lineup Heatmap</div>
                  <div className="header-two">{hist}</div>
                  <div
                    className="header-three"
                    style={{
                      width: `${Object.keys(panels).length * 100}}px`,
                      display: Object.keys(panels).length <= 0 ? "none" : "",
                    }}
                  >
                    New Alarm (Accumulated)
                  </div>
                </div>
                <div className="c-table-second-column">
                  <div className="header-one">Compartment</div>
                  {Object.keys(panels).map((pn, idx) => {
                    return (
                      <div key={idx} className="panel-header">
                        {pn}
                      </div>
                    );
                  })}
                </div>
                {compartment?.map((com, idx) => {
                  const compartmentName = Object.keys(com)[0];
                  return (
                    <div className="c-table-row" key={idx}>
                      <div className="header-one">{Object.keys(com)[0]}</div>
                      {Object.keys(panels)?.map((pannel, innerIdx) => (
                        <div key={innerIdx} className="panel-header">
                          {com[compartmentName]
                            ? com[compartmentName]?.[pannel]
                            : ""}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Spin>
    </div>
  );
};
