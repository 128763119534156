import { Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api, endpoint } from "../../api";
import appConfig from "../../appConfig";
import AlarmGraph from "./AlarmGraph";
import { RangeInputs } from "./RangeInputs";

const EditAlarm = () => {
  const Params = useParams();
  const alarmId = Params.alarmId;
  const navigate = useNavigate();

  const [panelList, setPanelList] = useState([]);
  const [lineupList, setLineuplist] = useState([]);
  const [compartmentList, setCompartmentList] = useState([]);
  const [SuccessMessage, setSuccessMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [siteLocations, setSiteLocations] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceParameters, setDeviceParameters] = useState([]);
  const [state, setState] = useState({
    name: "",
    type: "",
    site: "",
    device: "",
    parameter: "",
    option: "",
    interval: "",
    panelType: "",
    compartmentType: "",
    lineupType: "",
    criticalMin: "",
    criticalMax: "",
    cautionMin: "",
    cautionMax: "",
    highMin: "",
    highMax: "",
  });

  let { site, panelType, lineupType, compartmentType, option } = state;

  const getPanel = async () => {
    try {
      const response = await axios.get(`/panel-type`, {
        withCredentials: true,
      });
      if (response) {
        setPanelList(response.data);
      }
    } catch (error) {}
  };
  const getCompartment = async () => {
    try {
      const response = await axios.get(`/compartment-type`, {
        withCredentials: true,
      });
      if (response) {
        setCompartmentList(response.data);
      }
    } catch (error) {}
  };

  const getSiteLocations = async () => {
    try {
      const response = await axios.get(`/site-location/my-site`, {
        withCredentials: true,
      });
      if (response) {
        let data = response.data;
        setSiteLocations(data);
      }
    } catch (error) {}
  };
  const getLineup = async () => {
    try {
      const response = await axios.get(`/lineup-type`, {
        withCredentials: true,
      });
      if (response) {
        setLineuplist(response.data);
      }
    } catch (error) {}
  };

  const onInputChange = (e) => {
    let name = e.target.name;
    let obj = { ...state, [name]: e.target.value };
    if (
      name === "site" ||
      name === "lineupType" ||
      name === "compartmentType" ||
      name === "panelType"
    ) {
      obj.device = "";
      obj.parameter = "";
      setDevices([]);
      setDeviceParameters([]);
    }
    setState(obj);
  };

  const getDevices = async () => {
    const response = await axios.get(`/device`, {
      withCredentials: true,
      params: { site, panelType, lineupType, compartmentType },
    });
    if (response) {
      setDevices(response.data.results);
    }
  };

  useEffect(() => {
    if (site && panelType && lineupType && compartmentType) {
      getDevices();
    }
  }, [site, panelType, lineupType, compartmentType]);

  const getDeviceParameters = async (deviceId) => {
    try {
      const { results } = await api.get(endpoint.get_device_parameter, {
        params: {
          deviceId,
          limit: 1000,
          page: 1,
          sortBy: "name",
          orderBy: "ASC",
          type: "formula",
        },
      });
      setDeviceParameters(results);
      console.log({ results, deviceParameters });
    } catch (error) {}
  };

  useEffect(() => {
    if (state.device) {
      getDeviceParameters(state.device);
    }
    // eslint-disable-next-line
  }, [state.device]);

  const getAlarm = async () => {
    const response = await axios.get(`${endpoint.notification}/${alarmId}`, {
      withCredentials: true,
    });
    if (response) {
      const data = response.data;
      setState({
        name: data?.name,
        type: data?.type,
        site: data?.site?._id,
        device: data?.device?._id,
        parameter: data?.parameter?._id,
        option: data?.option,
        interval: data?.interval,
        panelType: data?.panelType?._id,
        compartmentType: data?.compartmentType?._id,
        lineupType: data?.lineupType?._id,
        criticalMin: data?.criticalMin || "",
        criticalMax: data?.criticalMax || "",
        cautionMin: data?.cautionMin || "",
        cautionMax: data?.cautionMax || "",
        highMin: data?.highMin || "",
        highMax: data?.highMax || "",
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await axios
      .put(
        `${process.env.REACT_APP_API_URL}/notification/` + alarmId,
        {
          ...state,
          criticalMin: Number(state?.criticalMin) || 0,
          criticalMax: Number(state?.criticalMax) || 0,
          cautionMin: Number(state?.cautionMin) || 0,
          cautionMax: Number(state?.cautionMax) || 0,
          highMin: Number(state?.highMin) || 0,
          highMax: Number(state?.highMax) || 0,
        },
        {
          withCredentials: true,
        }
      )
      .catch(function (error) {
        if (error.response) {
          setIsLoading(false);
          console.log(error.response);
        }
      });
    const data = response.data;
    if (data) {
      setIsLoading(false);
      setSuccessMessage("Alarm Updated successfully");
      navigate(-1);
      setTimeout(() => {
        setSuccessMessage();
      }, 2000);
    }
  };

  useEffect(() => {
    document.title = `${appConfig.name} - Edit Alarm`;
    getAlarm();
    getCompartment();
    getPanel();
    getSiteLocations();
    getLineup();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="alarm-page">
      <div className="card p-3 m-3">
        {/* <h3>Edit Alarm Settings</h3> */}
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">MANAGE NOTIFICATION</label>
            <span className="d-block py-2">Edit Alarm Settings</span>
          </div>
          {/* <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/substation/create">Add Site Location</Link>
          </div> */}
        </div>
        <hr className="mt-0" />
        <div className="d-flex justify-content-center">
          {isLoading && <Spinner animation="border" variant="dark" />}
        </div>
        {SuccessMessage && (
          <div className="alert alert-success" role="alert">
            {SuccessMessage}{" "}
          </div>
        )}
        <Spin spinning={isLoading}>
          <form onSubmit={submitHandler}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Alarm Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={state.name}
                    onChange={onInputChange}
                    className="form-control"
                    id="name"
                    placeholder="Enter Alarm Name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="type" className="form-label">
                    Alarm Type
                  </label>
                  <select
                    name="type"
                    id="type"
                    value={state.type}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option disabled> Select alarm type</option>
                    {/* <option value="message">
                          System Warning Message Only
                        </option> */}
                    <option value="email">Email Notification Only</option>
                    {/* <option value="both">
                          Email Notification + System Warning Message
                        </option> */}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="site" className="form-label">
                    Site Location
                  </label>
                  <select
                    name="site"
                    id="site"
                    value={state.site}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option> Select site location</option>
                    {siteLocations?.length &&
                      siteLocations.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="lineupType" className="form-label">
                    Switchgear
                  </label>
                  <select
                    name="lineupType"
                    id="lineupType"
                    value={state.lineupType}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option>Select Switchgear</option>
                    {lineupList?.length &&
                      lineupList.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="panelType" className="form-label">
                    Panel Type
                  </label>
                  <select
                    name="panelType"
                    id="panelType"
                    value={state.panelType}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option>Select Panel Type</option>
                    {panelList?.length &&
                      panelList.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="compartmentType" className="form-label">
                    Compartment Type
                  </label>
                  <select
                    name="compartmentType"
                    id="compartmentType"
                    value={state.compartmentType}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option> Select Compartment Type</option>
                    {compartmentList?.length &&
                      compartmentList.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="device" className="form-label">
                    Sensor
                  </label>
                  <select
                    name="device"
                    id="device"
                    value={state.device}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option> Select device</option>
                    {devices?.length &&
                      devices.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="parameter" className="form-label">
                    Sensor Parameter
                  </label>
                  <select
                    name="parameter"
                    id="parameter"
                    value={state.parameter}
                    className="form-select"
                    onChange={onInputChange}
                  >
                    <option>Select sensor parameter</option>
                    {deviceParameters?.length &&
                      deviceParameters.map((item, index) => (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="option" className="form-label">
                    Alarm Notification
                  </label>
                  <select
                    name="option"
                    id="option"
                    className="form-select"
                    defaultValue={state.option}
                    onChange={onInputChange}
                    value={state.option}
                  >
                    <option> Select alarm notification option</option>
                    <option value="min">Min</option>
                    <option value="max">Max</option>
                    <option value="range">Range</option>
                  </select>
                </div>
                <div className="row mb-3">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="interval" className="form-label">
                      Set Trigger Interval
                    </label>
                    <select
                      name="interval"
                      id="interval"
                      className="form-select"
                      value={state.interval}
                      onChange={onInputChange}
                    >
                      <option>Select alarm trigger interval</option>
                      <option value="1">1 min</option>
                      <option value="15">15 min</option>
                      <option value="30">30 min</option>
                      <option value="60">1 hour</option>
                      <option value="720">12 hours</option>
                      <option value="1440">24 hours</option>
                    </select>
                  </div>
                  <div className="col-md-6"></div>
                  <RangeInputs
                    option={option}
                    state={state}
                    onInputChange={onInputChange}
                    warningType="critical"
                  />
                  <RangeInputs
                    option={option}
                    state={state}
                    onInputChange={onInputChange}
                    warningType="caution"
                  />
                  <RangeInputs
                    option={option}
                    state={state}
                    onInputChange={onInputChange}
                    warningType="high"
                  />
                </div>
              </div>
            </div>

            {deviceParameters.length ? (
              <AlarmGraph data={state} deviceParameters={deviceParameters} />
            ) : (
              ""
            )}

            <div className="mb-3 d-flex">
              <div className="ms-auto">
                <button className="btn btn-success me-2" type="submit">
                  Update
                </button>
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </Spin>
      </div>
    </div>
  );
};

export default EditAlarm;
