import { Spin } from "antd";
import React from "react";
import { Button, Table } from "react-bootstrap";
import * as L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { useDispatch } from "react-redux";
import { setActiveDashboard } from "../../redux/slices/user.slice";
import ReactDOMServer from "react-dom/server";
import { GiPositionMarker } from "react-icons/gi";
import { FaMapMarkerAlt } from 'react-icons/fa';

const MapMarker = ({ site, markerLoading, handleMarkerClick, markerData }) => {
  const dispatch = useDispatch();

  const customIcon = new L.divIcon({
    className: "custom-marker",
    html: ReactDOMServer.renderToString(
      <GiPositionMarker
        style={{ color: getMarkerColor(site?.alarmStatus), fontSize:"45px" }}
      />
    ),
  });

  const latitude = site?.latitude || 0;
  const longitude = site?.longitude || 0;
  return (
    <Marker
      position={[latitude, longitude]}
      eventHandlers={{ click: () => handleMarkerClick(site._id) }}
      pathOptions={{ color: "red" }}
      icon={customIcon}
    >
      <Popup>
        <Spin spinning={markerLoading}>
          <h6 className="fw-bold"> {site?.name} </h6>
          <Table className="table-borderless fw-bold">
            <tr className="table-primary">
              <td className="py-1">Total Sensors</td>
              <td className="py-1"> : </td>
              <td className="py-1">{markerData?.totalDevices || 0}</td>
            </tr>
            <tr className="table-success">
              <td className="py-1">Online Sensors</td>
              <td className="py-1"> : </td>
              <td className="py-1 text-success">{markerData?.activeSensors}</td>
            </tr>
            <tr className="table-danger">
              <td className="py-1">Offline Sensors</td>
              <td className="py-1"> : </td>
              <td className="py-1 text-danger">
                {markerData?.inactiveSensors}
              </td>
            </tr>
          </Table>
          <div>
            <p className="font-weight-bold mb-0">Address:</p>
            <p className="mt-0">{markerData?.siteAddress || "--"}</p>
          </div>
          <div className="d-flex justify-content-end">
            {/* <Link role='button' className="btn btn-success" to={`substation/details/${site._id}`}>View
            </Link> */}
            {
              <Button
                variant="success"
                onClick={() => dispatch(setActiveDashboard(site?.dashboard))}
                disabled={!site?.dashboard}
              >
                View
              </Button>
            }
          </div>
        </Spin>
      </Popup>
    </Marker>
  );
};

export default MapMarker;

const getMarkerColor = (alarmStatus) => {
  if (alarmStatus === "critical") return "red";
  else if (alarmStatus === "caution") return "yellow";
  else if (alarmStatus === "high") return "orange";
  else return "green";
};
