import { notification } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { BiPlusCircle } from "react-icons/bi";
import { FormulaOperation } from "./FormulaOperation";

/**
 *
 * @param {{ parameters: Object[];  formulas: Object[], onFinish: Function }}} param0
 * @returns
 */
export const FormulaForm = ({
  parameters,
  formulas,
  mode,
  data,
  onFinish = () => {},
  onCancel = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    name: "",
    unit: "",
    formula: "",
    type: "system",
    operations: [
      {
        option: "",
        value: "",
      },
      {
        option: "",
        value: "",
      },
      {
        option: "",
        value: "",
      },
      {
        option: "",
        value: "",
      },
      {
        option: "",
        value: "",
      },
    ],
  };
  const [state, setState] = useState(initialState);

  const onInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  /**
   *
   * @param {Number} index
   * @param {{ value: String; option: String}} obj
   */
  const onChangeOperation = (index, obj) => {
    const data = Array.from(state.operations);

    data[index] = { ...data[index], ...obj };

    setState({
      ...state,
      operations: data,
    });
  };

  const onCreate = async (payload) => {
    try {
      const response = await axios.post(`/formula`, payload, {
        withCredentials: true,
      });
      if (response) {
        setState(initialState);
        notification.success({
          message: "Formula Created Successfully",
          placement: "bottomLeft",
        });
        onFinish();
      }
    } catch (error) {
      notification.error({
        message: "Something went wrong. Please try again later",
        placement: "bottomLeft",
      });
    }
  };

  const onUpdate = async (id, payload) => {
    try {
      const response = await axios.put(`/formula/${id}`, payload, {
        withCredentials: true,
      });
      if (response) {
        setState(initialState);
        notification.success({
          message: "Formula Updated Successfully",
          placement: "bottomLeft",
        });
        onFinish();
      }
    } catch (error) {
      notification.error({
        message: "Something went wrong. Please try again later",
        placement: "bottomLeft",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let payload = state;
    payload.operations = payload.operations.filter((d) => d.option && d.value);

    if (mode === "edit") {
      await onUpdate(data?._id, payload);
    } else {
      await onCreate(payload);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    let formula = "";
    for (const op of state.operations) {
      if (["time", "area", "year", "month"].includes(op.value)) {
        formula += " " + op.value[0].toUpperCase() + op.value.slice(1);
      } else {
        formula += " " + op.value;
      }
    }
    setState({ ...state, formula: formula.trim() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.operations]);

  useEffect(() => {
    if (mode === "edit" && data) {
      setState({
        name: data.name,
        unit: data.unit,
        formula: data.formula,
        type: data.type,
        operations: data.operations,
      });
    }
  }, [mode, data]);

  return (
    <div className="row mt-4">
      <div className="col-md-12">
        <h4>{mode === "edit" ? "Update Formula" : "Add A New Formula"}</h4>
        <div className="d-flex justify-content-center">
          {isLoading && <Spinner animation="border" variant="dark" />}
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="name">Formula Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={state.name}
              onChange={onInputChange}
              className="form-control"
              placeholder="Enter formula name"
              required
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="unit">Formula Unit / Value</label>
            <input
              type="text"
              id="unit"
              name="unit"
              value={state.unit}
              onChange={onInputChange}
              className="form-control"
              placeholder="Enter formula Unit"
            />
          </div>
        </div>

        <div>
          <label htmlFor="name">
            Set Formula
            <Button
              style={{
                display: "inline-flex",
                padding: "2px 10px",
                alignItems: "center",
                gap: 5,
              }}
              onClick={() => {
                setState({
                  ...state,
                  operations: [...state.operations, { option: "", value: "" }],
                });
              }}
            >
              <span style={{ fontSize: 12 }}>Add More</span> <BiPlusCircle />
            </Button>
          </label>
          <div
            style={{
              display: "flex",
              flex: 1,
              overflowX: "auto",
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            <div
              className=" gap-3"
              style={{
                display: "flex",
              }}
            >
              {state.operations.map((op, index) => (
                <FormulaOperation
                  key={index}
                  closeable={index > 2}
                  onClose={() => {
                    const data = Array.from(state.operations);
                    data.splice(index, 1);
                    setState({
                      ...state,
                      operations: data,
                    });
                  }}
                  onChange={(obj) => onChangeOperation(index, obj)}
                  operation={op}
                  parameters={parameters}
                  formulas={formulas}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-md-12">
            <label htmlFor="" className="me-3">
              Formula Preview
            </label>
            {state.name && state.formula && (
              <b>
                {state.name} = {state.formula}
              </b>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12 d-flex justify-content-end">
            <button className="btn btn-success me-2" type="submit">
              {mode === "edit" ? "Update" : "Create Formula"}
            </button>
            <button
              className="btn btn-dark me-2"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
