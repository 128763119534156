import React from "react";
import { Table } from "antd";

const DataTable = (props) => {
  return (
    // <div style={{ width: "100%" }}>
      <Table {...props}
        scroll={{ x: true }}
      />
    // </div>
  );
};

export default React.memo(DataTable);
