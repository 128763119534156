import React, { useState } from 'react';

import { Modal } from 'antd';

const ConfirmModal = ({
  onConfirm,
  title = 'Confirmation!',
  description = 'Do you really want to proceed?',
  children,
  okText,
  cancelText,
  className = '',
  okButtonProps,
  cancelButtonProps,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    onClose();
  };

  const onClick = (e) => {
    e.preventDefault();
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className={className}>
      <Modal
        open={visible}
        width="300px"
        onCancel={onClose}
        title={title}
        onOk={handleConfirm}
        okText={okText}
        cancelText={cancelText}
        okButtonProps={{ ...okButtonProps, loading: loading }}
        cancelButtonProps={cancelButtonProps}
      >
        <div className="modal-sub">{description}</div>
      </Modal>
      {React.cloneElement(children, { onClick: onClick })}
    </div>
  );
};

export default ConfirmModal;
