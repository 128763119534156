import { Tag } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useParams } from "react-router-dom";

const UserDetails = () => {
  const navigate = useNavigate();
  const params = useParams("userId");
  const userId = params.userId;

  const [user, setUser] = useState();

  const getUser = async () => {
    const response = await axios.get(`/user/` + userId, {
      withCredentials: true,
    });
    if (response) {
      console.log(response.data);
      setUser(response.data);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const getHeader = (role) => {
    switch (role) {
      case "installer":
        return {
          title: "Installer Profile",
        };
      case "admin":
        return {
          title: "Admin Profile",
        };
      case "user":
        return {
          title: "Site User Profile",
        };
      case "public":
        return {
          title: "Public User Profile",
        };
      default:
        return {
          title: "User Profile",
        };
    }
  };

  return (
    <div className="card p-3 m-3">
      <label className="az-content-label mb-3">
        {getHeader(user?.role).title}
      </label>
      {/* <h3>{getHeader(user?.role).title}</h3> */}
      <div className="row mt-4 mb-5">
        <div className="col-md-8">
          <div className="row mb-2">
            <div className="col-4">Full Name</div>
            <div className="col-8">: {user?.name}</div>
          </div>
          <div className="row mb-2">
            <div className="col-4">Email Address</div>
            <div className="col-8">: {user?.email}</div>
          </div>
          <div className="row mb-2">
            <div className="col-4">Phone Number</div>
            <div className="col-8">: +6{user?.phone}</div>
          </div>

          {["installer", "admin"].includes(user?.role) && (
            <>
              {user.companyName && (
                <div className="row mb-2">
                  <div className="col-4">Company Name</div>
                  <div className="col-8">: {user?.companyName}</div>
                </div>
              )}
              {user.companyAddress && (
                <div className="row mb-2">
                  <div className="col-4">Company Address</div>
                  <div className="col-8">: {user?.companyAddress}</div>
                </div>
              )}
            </>
          )}

          {/* {["user", "public"].includes(user?.role) && (
            <>
              <div className="row mb-2">
                <div className="col-4">Assigned Site</div>
                <div className="col-8">
                  :
                  {Array.isArray(user?.site) &&
                    user.site.map((site) => (
                      <Link to={`/substation/details/` + site?._id} className="me-2">
                        {site.name}
                      </Link>
                    ))}
                </div>
              </div>
            </>
          )} */}

          <div className="row mb-2">
            <div className="col-4">Status</div>
            <div className="col-8">
              :
              {user?.isActive ? (
                <Tag color="success">Active</Tag>
              ) : (
                <Tag color="error">Deactivated</Tag>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4  text-center">
          <div className="col-12">
            {user?.avatar && (
              <img
                src={user?.avatar}
                alt="logo"
                className="rounded-circle img-responsive"
                style={{ height: "150px" }}
              />
            )}
          </div>
          <div className="col-12 mt-3">Profile Photo</div>
        </div>
      </div>

      {["user", "public", "admin", "installer"].includes(user?.role) && (
        <div className="row mb-2">
          <div className="col-12">
            <DataTable
              columns={[
                {
                  name: "No",
                  width: "100px",
                  selector: (row, index) => index + 1,
                },
                {
                  name: "Assigned Substation",
                  selector: (row) => (
                    <Link
                      to={`/substation/details/` + row?._id}
                      className="me-2"
                    >
                      {row.name}
                    </Link>
                  ),
                },
                {
                  name: "Date Assigned",
                  selector: (row) =>
                    moment(user.updatedAt).format("DD/MM/YYYY"),
                },
              ]}
              data={user?.site}
              paginationRowsPerPageOptions={[10, 20, 50]}
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-12 d-flex justify-content-end">
          <Link to={`/users/edit/` + userId} className="btn btn-success me-1">
            Edit
          </Link>

          <Button onClick={() => navigate(-1)} variant="secondary">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
