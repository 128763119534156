import { Spin } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { endpoint } from "../../api";

const AlarmView = () => {
  const navigate = useNavigate();
  const Params = useParams();
  const alarmId = Params.alarmId;
  const [alarmDetails, setAlarmDetails] = useState();
  const [isLoading, setLoading] = useState(false);

  const getNotification = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        endpoint.notification_v1 + "/" + alarmId,
        {
          withCredentials: true,
        }
      );
      if (response) {
        setAlarmDetails(response.data);
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="installer-view">
      <div className="card p-3 m-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <label className="az-content-label">MANAGE NOTIFICATION</label>
            <span className="d-block py-2">Alarm Profile</span>
          </div>
          {/* <div className="col-md-4 d-flex justify-content-end p-0">
            <Link className="btn btn-az-primary btn-md" to="/substation/create">Add Site Location</Link>
          </div> */}
        </div>
        <hr className="mt-0" />
        {/* <h3>Alarm Profile</h3> */}
        <Spin spinning={isLoading}>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="row mb-2">
                <div className="col-5">Alarm Name</div>
                <div className="col-7">: {alarmDetails?.name}</div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Alarm Type</div>
                <div className="col-7 text-capitalize">
                  : {alarmDetails?.type}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Substation</div>
                <div className="col-7">: {alarmDetails?.site?.name}</div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Sensor</div>
                <div className="col-7">: {alarmDetails?.device?.name}</div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Sensor Parameter</div>
                <div className="col-7">: {alarmDetails?.parameter?.name}</div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Alarm Notification Option</div>
                <div className="col-7 text-capitalize">
                  : {alarmDetails?.option}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-5 text-capitalize">
                  {alarmDetails?.option} value
                </div>
                <div className="col-7">
                  :{" "}
                  {alarmDetails?.option === "max"
                    ? alarmDetails?.max
                    : alarmDetails?.option === "min"
                    ? alarmDetails?.min
                    : `${alarmDetails?.min} to ${alarmDetails?.max}`}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Trigger Interval</div>
                <div className="col-7">: {alarmDetails?.interval}</div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Critical Value</div>
                <div className="col-7">: {alarmDetails?.critical}</div>
              </div>
              <div className="row mb-2">
                <div className="col-5">Cautious Value</div>
                <div className="col-7">: {alarmDetails?.caution}</div>
              </div>
              <div className="row mb-2">
                <div className="col-5">High Value</div>
                <div className="col-7">: {alarmDetails?.high}</div>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-end">
              <Link
                to={`/edit-alarm/` + alarmId}
                className="btn btn-success me-1"
              >
                Edit
              </Link>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-secondary"
              >
                Back
              </button>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default AlarmView;
